import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import style from './ContactUs.module.scss'
import MapContainer from './MapContainer/MapContainer'

const ContactUs = () => {

    const { t } = useTranslation('common')

    return (
        <Container fluid className={style.mapContainer}>
            <Row>
                <Col className='text-center'>
                    <p className={style.title}>{t('get_in_touch')}</p>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col md={8} className='text-center'>
                    <p className={style.para}>{t('map_para')}</p>
                </Col>
            </Row>
            <Row className={style.mapRow}>
                <MapContainer />
            </Row>
        </Container>
    )
}

export default ContactUs

