import React, { useState, useEffect } from "react";
import style from "./Municipal.module.scss";
import { useTranslation } from "react-i18next";
import PopUp from "./../../UIComponents/PopUp/PopUp";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";

const ServiceMunicipal = ({ municipalDetails, showMuicipls, requestNo = "0", supportTypeId = "0", inputsData = {}, isSupportEnabled = true, error }) => {
  const { t, i18n } = useTranslation("common");
  const [show, setShow] = useState(false);
  const [img, setImg] = useState();
  const { user, clientProfiles } = useSelector((state) => state.user);
  const location = useLocation();
  const [isUploading, setIsUploading] = useState(false);

  const handlePopup = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    if (show) {
      html2canvas(document.querySelector("#root"), { useCORS: true, allowTaint: true }).then(function (canvas) {
        setImg(canvas.toDataURL("image/jpeg"));
      });
    }
  }, [show]);

  const handleOnSubmit = (values, setSubmitting, resetForm) => {
    setSubmitting(true);
    let parsedError;
    if (error) {
      parsedError = JSON.parse(error);
    }
    const data = new FormData();
    data.append(
      "PARAMETERS",
      JSON.stringify({
        department_Id: municipalDetails.Department_Id,
        department_Service_Version_Id: municipalDetails?.Department_Service_Version_Id,
        service_Id: municipalDetails?.Service_Id,
        support_Request_Type_Id: supportTypeId,
        client_Profile_Id: clientProfiles?.Client_Profile_Id,
        client_Notes: values.client_notes,
        page_Inputs_Data: JSON.stringify(inputsData),
        page_URL: location.pathname,
        error_Request_Posted_Data: JSON.stringify({
          method: parsedError?.method,
          url: parsedError?.url,
          headers: {
            Authorization: parsedError?.headers["Authorization"],
          },
        }),
        error_Request_Response_Data: JSON.stringify(parsedError?.data),
        error_Request_URL: parsedError?.url,
        error_Request_No: requestNo,
      })
    );

    if (img) {
      setIsUploading(true)
      data.append("Page_Image", dataURItoBlob(img));
      axios
        .post(`${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/SUPPORTREQUEST/ADD_SUPPORT_REQUEST`, data, {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        })
        .catch((err) => console.log(err))
        .finally(() => setIsUploading(false));
      setShow(false);
      resetForm();
    }
  };

  return (
    <>
      <div className={style.Municipls}>
        <Row>
          {municipalDetails ? (
            <Col>
              <p className={style.title}>{i18n.language === "en" ? municipalDetails.Department_Name_EN : municipalDetails.Department_Name_AR}</p>
              <p className={style.subtitle}>{i18n.language === "en" ? municipalDetails.Department_Section_Name_EN : municipalDetails.Department_Section_Name_AR}</p>
              <p className={style.para}>{i18n.language === "en" ? municipalDetails.Department_Section_Description_EN : municipalDetails.Department_Section_Description_AR}</p>

              {municipalDetails.Department_Section_Phone ? (
                <>
                  <div className={style.contactData}>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18.001" height="18" viewBox="0 0 18.001 18">
                        <path
                          id="Path"
                          d="M-18,13.42v3.536a1,1,0,0,0,.93,1c.437.03.794.046,1.07.046A16,16,0,0,0,0,2Q0,1.586-.046.93a1,1,0,0,0-1-.93H-4.58a.5.5,0,0,0-.5.45C-5.1.68-5.122.863-5.142,1A13.9,13.9,0,0,1-6.35,5,.456.456,0,0,0-6.2,5.57l2.158,1.542a13.047,13.047,0,0,1-6.844,6.844l-1.54-2.154A.462.462,0,0,0-13,11.653a13.9,13.9,0,0,1-4,1.2c-.139.02-.322.042-.55.064a.5.5,0,0,0-.449.5Z"
                          transform="translate(18.001)"
                        />
                      </svg>
                    </span>

                    <div className={[style.content, i18n.language === "en" ? style.contentEn : style.contentAr].join(" ")}>
                      <p className={style.header}>{t("contact_us")}</p>
                      <p className={style.data}>{municipalDetails.Department_Section_Phone}</p>
                    </div>
                  </div>
                </>
              ) : null}

              <div className={style.contactData}>
                {(i18n.language === "en" && municipalDetails.Department_Address_EN) || (i18n.language === "ar" && municipalDetails.Department_Address_AR) ? (
                  <span>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 440.3 440.3" xmlSpace="preserve">
                      <g>
                        <g>
                          <path
                            d="M365.8,76.8C333.6,27.6,280.3,0,220.4,0C161,0,107.8,27.6,74.5,76.8c-33.3,48.1-41,109.6-20.5,163.3
                                                      c5.6,14.3,14.3,29.2,25.6,42.5l129.5,152.6c3.1,3.1,6.1,5.1,10.8,5.1s7.7-2,10.8-5.1l130-152.6c11.3-13.3,20.5-27.6,25.6-42.5
                                                      C406.8,186.4,399.1,124.9,365.8,76.8z M220.4,227.7c-27.4,0-50-22.6-50-50s22.6-50,50-50s50,22.6,50,50S248.1,227.7,220.4,227.7z"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                ) : null}

                <div className={[style.content, i18n.language === "en" ? style.contentEn : style.contentAr].join(" ")}>
                  <p className={style.header}>{i18n.language === "en" ? municipalDetails.Department_Address_EN : municipalDetails.Department_Address_AR}</p>
                  <p className={style.data}>{municipalDetails.Department_Website}</p>
                </div>
              </div>

              <ul className={style.socialMedia}>
                {municipalDetails.Department_Instagram ? (
                  <li>
                    <a target="_blank" href={municipalDetails.Department_Instagram}>
                      <span>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <title>instagram-fill</title>
                          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="0" transform="translate(-633.000000, -4036.000000)">
                              <g id="Group" transform="translate(482.000000, 4036.000000)">
                                <g id="instagram-fill" transform="translate(151.000000, 0.000000)">
                                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                  <path
                                    d="M12,2 C14.717,2 15.056,2.01 16.122,2.06 C17.187,2.11 17.912,2.277 18.55,2.525 C19.21,2.779 19.766,3.123 20.322,3.678 C20.8304937,4.17789596 21.2239536,4.78258896 21.475,5.45 C21.722,6.087 21.89,6.813 21.94,7.878 C21.987,8.944 22,9.283 22,12 C22,14.717 21.99,15.056 21.94,16.122 C21.89,17.187 21.722,17.912 21.475,18.55 C21.2246766,19.2177765 20.8311207,19.822617 20.322,20.322 C19.8219615,20.8303192 19.2173093,21.2237526 18.55,21.475 C17.913,21.722 17.187,21.89 16.122,21.94 C15.056,21.987 14.717,22 12,22 C9.283,22 8.944,21.99 7.878,21.94 C6.813,21.89 6.088,21.722 5.45,21.475 C4.78232618,21.2244734 4.17752722,20.8309445 3.678,20.322 C3.16940644,19.8221857 2.77593123,19.2174693 2.525,18.55 C2.277,17.913 2.11,17.187 2.06,16.122 C2.013,15.056 2,14.717 2,12 C2,9.283 2.01,8.944 2.06,7.878 C2.11,6.812 2.277,6.088 2.525,5.45 C2.7752361,4.7821794 3.16880364,4.17732106 3.678,3.678 C4.17767098,3.16923123 4.78242858,2.77572924 5.45,2.525 C6.088,2.277 6.812,2.11 7.878,2.06 C8.944,2.013 9.283,2 12,2 Z M12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 C14.7614237,17 17,14.7614237 17,12 C17,9.23857625 14.7614237,7 12,7 L12,7 Z M18.5,6.75 C18.5,6.05964406 17.9403559,5.5 17.25,5.5 C16.5596441,5.5 16,6.05964406 16,6.75 C16,7.44035594 16.5596441,8 17.25,8 C17.9403559,8 18.5,7.44035594 18.5,6.75 Z M12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 Z"
                                    id="Shape"
                                    fill="$white"
                                    fillRule="nonzero"
                                    opacity="0.823446584"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                ) : null}

                {municipalDetails.Department_Twitter ? (
                  <li>
                    <a target="_blank" href={municipalDetails.Department_Twitter}>
                      <span>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <title>twitter-fill</title>
                          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="0" transform="translate(-520.000000, -4036.000000)">
                              <g id="Group" transform="translate(482.000000, 4036.000000)">
                                <g id="twitter-fill" transform="translate(38.000000, 0.000000)">
                                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                  <path
                                    d="M22.162,5.656 C21.398557,5.99369373 20.5889601,6.21547339 19.76,6.314 C20.6337508,5.79143332 21.2877055,4.96901423 21.6,4 C20.78,4.488 19.881,4.83 18.944,5.015 C17.6498495,3.63031951 15.5862776,3.28908903 13.9152663,4.18345292 C12.244255,5.07781681 11.3836288,6.98414575 11.818,8.829 C8.45502254,8.66044327 5.32174389,7.07199458 3.198,4.459 C2.82629012,5.09745032 2.63095514,5.82322672 2.632,6.562 C2.632,8.012 3.37,9.293 4.492,10.043 C3.82800881,10.0220962 3.1786324,9.84278055 2.598,9.52 L2.598,9.572 C2.59841319,11.5630358 4.00151442,13.2781232 5.953,13.673 C5.33661503,13.8400369 4.69030669,13.8646582 4.063,13.745 C4.61012698,15.4491831 6.18045142,16.6167778 7.97,16.65 C6.21794158,18.0260194 3.99074,18.6491034 1.779,18.382 C3.69069779,19.6114328 5.91609682,20.2641239 8.189,20.2620052 C15.882,20.2620052 20.089,13.889 20.089,8.362 C20.089,8.182 20.084,8 20.076,7.822 C20.8948641,7.23016766 21.6015987,6.49701939 22.163,5.657 L22.162,5.656 Z"
                                    id="Path"
                                    fill="$white"
                                    fillRule="nonzero"
                                    opacity="0.823446584"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                ) : null}

                {municipalDetails.Department_Facebook ? (
                  <li>
                    <a target="_blank" href={municipalDetails.Department_Facebook}>
                      <span>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                          <title>facebook-box-fill</title>
                          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="0" transform="translate(-482.000000, -4036.000000)">
                              <g id="facebook-box-fill" transform="translate(482.000000, 4036.000000)">
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                <path
                                  d="M15.402,21 L15.402,14.034 L17.735,14.034 L18.084,11.326 L15.402,11.326 L15.402,9.598 C15.402,8.814 15.62,8.279 16.744,8.279 L18.178,8.279 L18.178,5.857 C17.4837982,5.78334463 16.7860943,5.74762486 16.088,5.75 C14.021,5.75 12.606,7.012 12.606,9.33 L12.606,11.326 L10.268,11.326 L10.268,14.034 L12.606,14.034 L12.606,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L15.402,21 Z"
                                  id="Path"
                                  fill="$white"
                                  fillRule="nonzero"
                                  opacity="0.823446584"></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                ) : null}
              </ul>
              {isSupportEnabled && (
                <span className={[style.technicalSupport, "mainBtn primarymainBtn d-none d-md-flex flex-nowrap text-center"].join(" ")} onClick={(showMuicipls, handlePopup)}>
                  {t("technical_support_request")}
                </span>
              )}
            </Col>
          ) : null}
        </Row>
      </div>

      <PopUp show={show} handleClose={handleClose} title={t("technical_support_request")} smallModal={true}>
        <Container>
          <Formik initialValues={{ client_Notes: "" }} onSubmit={(values, { setSubmitting, resetForm }) => handleOnSubmit(values, setSubmitting, resetForm)}>
            <Form>
              <Row>
                <Col>
                  <label className={style.label}>{t("notes")}</label>
                  <Field as="textarea" name="client_notes" type="text" />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex justify-content-end">
                  <button type="submit" className={["mainBtn primarymainBtn", style.tSupportBtn].join(" ")}>
                    {t("confirm_technical_support_request")}
                  </button>
                </Col>
              </Row>
            </Form>
          </Formik>
        </Container>
      </PopUp>

      {isUploading && <LoadingOverlay />}
    </>
  );
};

export default ServiceMunicipal;

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}
