import React, { useState, useEffect, useRef, useCallback } from "react";
import Header from "components/UIComponents/Header/Header";
import InnerTitleCont from "components/UIComponents/InnerTitleCont/InnterTitleState";
import Footer from "components/UIComponents/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Description from "components/pages/ServiceDetails/Description/ServiceDescription";
import ServiceMunicip from "./ServiceMunicip";
import ServiceRequest from "./ServiceRequest";
import { useParams } from "react-router-dom";
import { resetDepartment } from "./serviceMunicipSlice";
import { toState, authorizedState } from "./serviceMachineSlice";
import { fetchServiceCard, resetStates } from "./serviceCardSlice";
import { useDispatch, useSelector } from "react-redux";
import ServiceMunicipal from "components/UIComponents/Municipal/ServiceMunicipal";
import LoginModal from "components/UIComponents/Login/loginModal";

const ServiceCard = (props) => {
  const { serviceId, requestDraftId, dealerId } = useParams();
  const { departmentId } = useSelector((state) => state.service.serviceMunicip);
  const { clientProfiles, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const {
    serviceData,
    serviceDepartmentsList,
    serviceDetailsData,
    serviceStepsData,
  } = useSelector((state) => state.service.serviceCard);
  const { currentState } = useSelector((state) => state.service.serviceMachine);
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const serviceMunicipRef = useRef();
  const helpTextRef = useRef();
  const [showHelp, setShowHelp] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const modalClose = () => {
    setShowModal(false);
  };
  const modalShow = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (requestDraftId) {
      dispatch(authorizedState("Request"));
    } else if (!requestDraftId && currentState !== "Info") {
      dispatch(toState("Info"));
    }
  }, [requestDraftId, serviceData]);

  const handleHelp = (val) => {
    setShowHelp(val);
  };

  useEffect(() => {
    if (showHelp) {
      serviceMunicipRef.current.style.border = "1px solid #dc3545";
      helpTextRef.current.style.display = "block";
    } else {
      serviceMunicipRef.current.style.border = "none";
      helpTextRef.current.style.display = "none";
    }
  }, [showHelp]);

  useEffect(() => {
    if (serviceId && currentState !== "Request") {
      let activeDealerId = localStorage.getItem("activeDealerId");
      const promise = dispatch(
        fetchServiceCard({
          Dealing_Type_Id: clientProfiles?.Dealing_Type_Id ?? activeDealerId,
          Service_Id: serviceId,
          Department_Id: departmentId ? departmentId : 0,
        })
      );
      return () => {
        promise.abort();
      };
    }
  }, [departmentId, clientProfiles, serviceId, currentState]);

  useEffect(() => {
    return () => {
      dispatch(resetDepartment());
      dispatch(resetStates());
    };
  }, []);

  const handleRequestStart = useCallback(() => {
    if (departmentId) {
      dispatch(authorizedState("Request"));
    } else {
      serviceMunicipRef.current.style.transition =
        "transform 200ms ease-in-out";
      serviceMunicipRef.current.style.transform = "scale(1.02)";
      setShowHelp(true);
      setTimeout(() => {
        serviceMunicipRef.current.style.transform = "scale(1)";
      }, 200);
    }
  }, [serviceMunicipRef, departmentId, setShowHelp]);

  const handleDescriptionStart = () => {
    if (departmentId) {
      dispatch(authorizedState("Request"));
    } else {
      window.scrollTo(
        0,
        document.querySelector(".pageContainer").offsetTop - 100
      );
      serviceMunicipRef.current.style.transition =
        "transform 200ms ease-in-out";
      serviceMunicipRef.current.style.transform = "scale(1.02)";
      handleHelp(true);
      setTimeout(() => {
        serviceMunicipRef.current.style.transform = "scale(1)";
      }, 200);
    }
  };

  return (
    <>
      <Header />
      {currentState === "Info" && (
        <>
          <InnerTitleCont
            breadCrumbs={`${t("menu_home")} / ${t("all_services")}`}
            title={
              i18n.language === "en"
                ? serviceData?.Service_Name_EN ?? ""
                : serviceData?.Service_Name_AR ?? ""
            }
            permissionId={{ dealingTypeId: clientProfiles?.Dealing_Type_Id }}
            startBtn={true}
            leaveBtn={false}
            startTrigger={isAuthenticated ? handleRequestStart : modalShow}
            leaveTrigger={() => {}}
          />
          <Container fluid="lg" className="pageContainer">
            <Row className="mb-5">
              <Col md={8}>
                <div className="gCard">
                  {serviceData && serviceDetailsData && serviceStepsData && (
                    <Description
                      serviceData={serviceData}
                      serviceDepartmentsList={serviceDepartmentsList}
                      serviceDetailsData={serviceDetailsData}
                      serviceStepsData={serviceStepsData}
                      onStart={
                        isAuthenticated ? handleDescriptionStart : modalShow
                      }
                    />
                  )}
                </div>
              </Col>

              <Col md={4}>
                <div className="gCard" ref={serviceMunicipRef}>
                  <span
                    ref={helpTextRef}
                    style={{
                      color: "#dc3545",
                      textAlign: "center",
                      marginBottom: "5px",
                    }}
                  >
                    {t("help_text")}
                  </span>
                  <ServiceMunicip
                    handleHelp={handleHelp}
                    municipalDetails={serviceData}
                    serviceDepartmentsList={serviceDepartmentsList}
                  >
                    <ServiceMunicipal
                      municipalDetails={serviceData}
                      isSupportEnabled={false}
                    />
                  </ServiceMunicip>
                </div>
              </Col>
            </Row>
          </Container>
          <LoginModal show={showModal} onHide={modalClose} />
        </>
      )}
      {currentState === "Request" &&
        serviceData?.Dealing_Type_Id === clientProfiles?.Dealing_Type_Id && (
          <ServiceRequest />
        )}
      <Footer />
    </>
  );
};

export default ServiceCard;
