import React, { useEffect } from "react";
import InnerTitleCont from "components/UIComponents/InnerTitleCont/InnterTitleState";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Request from "./Request";
import ServiceMunicipal from "components/UIComponents/Municipal/ServiceMunicipal";
import { toState } from "./serviceMachineSlice";
import {
  fetchServiceRequestInfo,
  fetchServiceRequestDraft,
  resetRequest,
} from "./serviceRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import style from "./Request.module.scss";

const DRAFT_SUPPORT_TYPE_ID = "1";

const ServiceRequest = () => {
  const { serviceData } = useSelector((state) => state.service.serviceCard);
  const requestData = useSelector(
    (state) => state.service.serviceRequest.serviceData
  );
  const { inputsData, error } = useSelector(
    (state) => state.service.serviceRequest
  );
  const { clientProfiles } = useSelector((state) => state.user);
  const { serviceId, requestDraftId } = useParams();
  const history = useHistory();
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();

  useEffect(() => {
    if (requestDraftId) {
      const promise = dispatch(
        fetchServiceRequestDraft({
          Request_Draft_Id: requestDraftId,
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        })
      );
      return () => {
        promise.abort();
      };
    } else {
      const promise = dispatch(
        fetchServiceRequestInfo({
          Dealing_Type_Id: serviceData?.Dealing_Type_Id,
          Service_Id: serviceData?.Service_Id,
          Department_Id: serviceData?.Department_Id,
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        })
      );
      return () => {
        promise.abort();
      };
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetRequest());
    };
  }, [dispatch]);

  const handleLeave = () => {
    dispatch(toState("Info"));
    history.push(`/service-card/${serviceId}`);
  };

  return (
    <>
      <InnerTitleCont
        breadCrumbs={
          !requestDraftId
            ? `${t("menu_home")} / ${t("all_services")}`
            : `${t("Dashboard")} / ${t("drafts")}`
        }
        title={
          i18n.language === "en"
            ? serviceData?.Service_Name_EN ?? ""
            : serviceData?.Service_Name_AR ?? ""
        }
        startBtn={false}
        leaveBtn={true}
        startTrigger={() => dispatch(toState("Request"))}
        leaveTrigger={handleLeave}
      />
      <Container
        fluid="lg"
        style={{ position: "relative", marginTop: "-50px" }}
      >
        <Row className="mb-5">
          {/* <Col md={12}>
            <div className={["gCard", style.gCard].join(" ")}>
              <ApplyService />
            </div>
          </Col> */}
          <Col md={12}>
            {/* <div className={["gCard", style.gCard].join(" ")}> */}
            <div className={[style.gCard].join(" ")}>
              <Request />
            </div>
          </Col>
          {/* <Col md={4}>
            <div className="gCard">
              <ServiceMunicipal
                municipalDetails={requestData ? requestData : serviceData}
                requestNo={requestDraftId}
                inputsData={inputsData}
                supportTypeId={DRAFT_SUPPORT_TYPE_ID}
                isSupportEnabled={true}
                error={error}
              />
            </div>
          </Col> */}
        </Row>
      </Container>
    </>
  );
};

export default ServiceRequest;
// تقديم طلب دعم فني
