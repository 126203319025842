import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Header from "components/UIComponents/Header/Header";
import Footer from "components/UIComponents/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import Grid from "components/UIComponents/Grid";
import { Column } from "devextreme-react/data-grid";
import style from "./StructuralAuditing.module.scss";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import InputMask from "react-input-mask";

const StructuralAuditing = () => {
  const { t, i18n } = useTranslation("common");
  const BuildingYearRef = useRef();
  const [areasList, setAreasList] = useState([]);
  const [searchVal, setSearchVal] = useState(null);
  const [gridData, setGridData] = useState([]);
  const { user, clientProfiles } = useSelector((state) => state.user);
  const [isReady, setIsReady] = useState(true);
  const [buildingYear, setBuildingYear] = useState({});
  const [buildingYearValid, setBuildingYearValid] = useState(true);
  let departmentName = "Department_Name_AR";
  let clientName = "Client_Name_AR";
  let requestStatus = "Request_Status_Name_AR";
  let requestExecution = "Request_Execution_User_Name_AR";
  if (i18n.language == "en") {
    departmentName = "Department_Name_EN";
    clientName = "Client_Name_EN";
    requestStatus = "Request_Status_Name_EN";
    requestExecution = "Request_Execution_User_Name_EN";
  }

  useEffect(() => {
    const getAreasList = async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/SYSTEM/DDL/GET_AREAS`
      );
      setAreasList(res.data.Data);
    };
    getAreasList();
  }, []);

  const handleSubmit = (values) => {
    setSearchVal(values);
    setIsReady(false);
    const res = axios
      .post(
        `${process.env.REACT_APP_API_URL}/REPORTS/REQUESTS/GET_PROJECT_EXECUTIVE_FILE`,
        {
          Project_No: values?.projectNo,
          Area_Id: values?.areaNumber,
          Plot_No: values?.plotNo,
          Buidling_Permission_No: values?.buildingPermitNo,
          Buidling_Permission_Year: values?.buildingPermitYear,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      )
      .then((res) => {
        setGridData(res.data.Data);
        setIsReady(true);
      });
  };

  const handlePrint = async () => {
    setIsReady(false);
    const res = axios
      .post(
        `${process.env.REACT_APP_API_URL}/REPORTS/REQUESTS/PRINT_PROJECT_EXECUTIVE_FILE_REPORT`,
        {
          Project_No: searchVal?.projectNo,
          Area_Id: searchVal?.areaNumber,
          Plot_No: searchVal?.plotNo,
          Buidling_Permission_No: searchVal?.buildingPermitNo,
          Buidling_Permission_Year: searchVal?.buildingPermitYear,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let contentType = response.headers["content-type"],
            typesArray = [
              "application/pdf",
              "image/bmp",
              "image/gif",
              "image/vnd.microsoft.icon",
              "image/jpeg",
              "image/png",
              "image/svg+xml",
              "image/tiff",
              "image/webp",
            ],
            url = window.URL.createObjectURL(
              new Blob([response.data], { type: contentType })
            ),
            link = document.createElement("a");

          link.href = url;

          typesArray.includes(contentType)
            ? link.setAttribute("target", "blank")
            : link.setAttribute("download", null);

          document.body.appendChild(link);
          link.click();
        }
        setIsReady(true);
      });
  };

  return (
    <>
      <Header />

      <Container fluid="lg">
        <Row className="justify-content-center">
          <p className={style.title}>{t("structuralAuditing")}</p>
        </Row>

        <Formik
          initialValues={{
            projectNo: "",
            areaNumber: "",
            plotNo: "",
            buildingPermitNo: "",
            buildingPermitYear: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.areaNumber) {
              errors.areaNumber = `${t("field_required")}`;
            }
            if (!values.plotNo) {
              errors.plotNo = `${t("field_required")}`;
            }
            if (!values.buildingPermitNo) {
              errors.buildingPermitNo = `${t("field_required")}`;
            }
            if (!values.buildingPermitYear) {
              errors.buildingPermitYear = `${t("field_required")}`;
            }
            return errors;
          }}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({
            setFieldValue,
            submitForm,
            errors,
            touched,
            values,
            dirty,
            isValid,
          }) => (
            <Form>
              <Row>
                <Col md={4}>
                  <label className={style.label}>{t("projectNo")}</label>
                  <Field className={style.input} name="projectNo" type="text" />
                </Col>

                <Col md={4}>
                  <label className={style.label}>{t("areaNumber")}</label>
                  <Field as="select" name="areaNumber" className={style.input}>
                    <option
                      className={style.defaultValue}
                      value=""
                      disabled
                      defaultValue
                    ></option>
                    {areasList.map((type) => (
                      <option key={type.Area_Id} value={type.Area_Id}>
                        {i18n.language === "en"
                          ? type.Area_Name_EN
                          : type.Area_Name_AR}
                      </option>
                    ))}
                  </Field>
                  <span className={style.error}>
                    {touched.areaNumber && errors.areaNumber && (
                      <div>{errors.areaNumber}</div>
                    )}
                  </span>
                </Col>

                <Col md={4}>
                  <label className={style.label}>{t("plotNo")}</label>
                  <Field className={style.input} name="plotNo" type="text" />
                  <span className={style.error}>
                    {touched.plotNo && errors.plotNo && (
                      <div>{errors.plotNo}</div>
                    )}
                  </span>
                </Col>

                <Col md={4}>
                  <label className={style.label}>{t("buildingPermitNo")}</label>
                  <Field
                    className={style.input}
                    name="buildingPermitNo"
                    type="text"
                  />
                  <span className={style.error}>
                    {touched.buildingPermitNo && errors.buildingPermitNo && (
                      <div>{errors.buildingPermitNo}</div>
                    )}
                  </span>
                </Col>

                <Col md={4}>
                  <label className={style.label}>
                    {t("buildingPermitYear")}
                  </label>
                  <label>
                    <span className={"text-danger"}>
                      {t("common:dateFormatRequest")}*
                    </span>
                  </label>
                  {/* <Field
                                        className={style.input}
                                        name="buildingPermitYear"
                                        type="text"
                                        maxLength={10}
                                        value={buildingYear || ''}
                                        onKeyUp={(e) => {
                                            let val = e.target.value
                                            let newVal = val.replace(/(\d\d\d\d)(\d\d)(\d\d)/, '$1-$2-$3');
                                            console.log(newVal)
                                            setBuildingYear(newVal)
                                        }}
                                    /> */}
                  <InputMask
                    name="buildingPermitYear"
                    className={style.input}
                    {...buildingYear}
                    ref={BuildingYearRef}
                    onChange={(e) => {
                      setBuildingYear({ mask: "9999-99-99" });
                      setBuildingYearValid(
                        e.target.value.replace(/[^0-9]/g, "").length == 8
                          ? true
                          : false
                      );
                      setFieldValue("buildingPermitYear", e.target.value);
                    }}
                    onFocus={(e) =>
                      setBuildingYearValid(e.target.value == "" ? false : true)
                    }
                    // onClick={(e) => {
                    //     // setBuildingYearValid(e.target.value ? true : false)
                    //     console.log(e.target.value == '' ? 'false' : 'f')
                    //     setBuildingYearValid(e.target.value == '' ? false : true)
                    //     // setBuildingYearValid(e.target.value.replace(/[^0-9]/g, "").length == 8 || e.target.value != '' ? true : false)
                    // }}
                    autoComplete="off"
                  />
                  <span className={style.error}>
                    {!buildingYearValid ? (
                      <div>
                        {i18n.language == "en"
                          ? "This field is required"
                          : "هذا الحقل مطلوب"}
                      </div>
                    ) : null}
                  </span>
                </Col>

                <Col
                  md={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <button
                    className="mainBtn primarymainBtn lgBtn"
                    type="submit"
                    disabled={
                      !(
                        dirty &&
                        isValid &&
                        values.buildingPermitYear?.replace(/[^0-9]/g, "")
                          ?.length == 8
                      )
                    }
                  >
                    {`${t("search")}`}
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>

        <Row className="mt-5">
          <Grid
            sourceData={gridData}
            addEnabled={false}
            editEnabled={false}
            deleteEnabled={false}
            viewEnabled={false}
          >
            <Column
              caption={t("request_no")}
              alignment="center"
              dataField="Request_No"
              dataType="string"
              width="auto"
            />
            <Column
              caption={t("request_date")}
              alignment="center"
              dataField="Request_Date"
              dataType="date"
              width="auto"
              format="yyyy-MM-dd"
            />
            <Column
              caption={t("department_name")}
              alignment="center"
              dataField={departmentName}
              dataType="string"
              width="auto"
            />
            <Column
              caption={t("applicant")}
              alignment="center"
              dataField={clientName}
              dataType="string"
              width="auto"
            />
            <Column
              caption={t("municipalEngineer")}
              alignment="center"
              dataField={requestExecution}
              dataType="string"
              width="auto"
            />
            <Column
              caption={t("serviceRequestStatus")}
              alignment="center"
              dataField={requestStatus}
              dataType="string"
              width="auto"
            />
            <Column
              caption={t("requestResult")}
              alignment="center"
              dataField="Request_Execution_Result"
              dataType="string"
              width="auto"
            />
          </Grid>
        </Row>

        <Row className="justify-content-end my-4">
          <Col md={3} className="d-flex justify-content-end">
            <button
              className="mainBtn primarymainBtn lgBtn"
              disabled={gridData.length == 0}
              onClick={() => handlePrint()}
            >
              {`${t("print")}`}
            </button>
          </Col>
        </Row>
      </Container>

      <Footer />

      {!isReady && <LoadingOverlay />}
    </>
  );
};

export default StructuralAuditing;
