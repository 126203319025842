import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setForgetPassword, setResetPassword } from "api/userAPI";

const slice = createSlice({
  name: "otp",
  initialState: {
    User_OTP_Id: "",
    OTP_Expire_Time: "",
  },
  reducers: {
    forgetPasswordSuccess: (state, { payload }) => {
      state.User_OTP_Id = payload.User_OTP_Id;
      state.OTP_Expire_Time = payload.OTP_Expire_Time;
    },
    resetPasswordSuccess: (state) => {
      state.User_OTP_Id = "";
      state.OTP_Expire_Time = "";
    },
  },
});
export default slice.reducer;

export const { forgetPasswordSuccess, resetPasswordSuccess } = slice.actions;

export const forgetPassword = (param, source, setSubmitting) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }
    const res = await setForgetPassword(param, cancelToken);

    if (res?.Status) {
      dispatch(forgetPasswordSuccess(res.Data));
    }
    if (!res?.Status) {
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    setSubmitting(false);
  }
};

export const resetPassword = (param, source, setSubmitting) => async (dispatch) => {
  try {
    let cancelToken;
    if (source) {
      cancelToken = { cancelToken: source.token };
    }

    const res = await setResetPassword(param, cancelToken);

    if (res?.Status) {
      dispatch(resetPasswordSuccess(res.Data));
    }
    if (!res?.Status) {
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("cancelled");
    } else {
      console.error(error);
    }
  } finally {
    setSubmitting(false);
  }
};
