import React from 'react'
import style from './UserServices.module.scss'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

const UserServices = props => {

    const { t, i18n } = useTranslation('common')
    const { clientProfiles } = useSelector((state) => state.user);

    return (
        <div className={style.dealing}>
            <Row>
                <p className={style.title}>{t('customer_services')}</p>
            </Row>
            <Row>
                <ul className={style.types}>
                    {props.dealers.map(dealer => (
                        <li 
                            key={dealer.Dealing_Type_Id}
                            className={[
                                clientProfiles?.Dealing_Type_Id ?
                                    clientProfiles?.Dealing_Type_Id === dealer.Dealing_Type_Id ? 'active' : style.disabledTab
                                : 
                                    props.activeTabId == dealer.Dealing_Type_Id ? 'active' : null
                            ].join(' ')}
                        >
                            <a 
                                href='#!' 
                                onClick={() => props.getServiceList(!clientProfiles?.Dealing_Type_Id && dealer.Dealing_Type_Id)}
                            >
                                {i18n.language === 'en' ? dealer.Dealing_Type_Name_EN : dealer.Dealing_Type_Name_AR}
                            </a>
                        </li>
                    ))}
                    {/* <li>
                        <NavLink to='/all' exact>{t('all')}</NavLink>
                    */}
                </ul>
            </Row>
        </div>
    )
}

export default UserServices
