import React, { useState, useEffect } from 'react'
import { Column } from 'devextreme-react/data-grid'
import { useTranslation } from 'react-i18next'
import Grid from './../../UIComponents/Grid/index'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"

const ServicesGrid = props => {

    const { t, i18n } = useTranslation('common')
    const [data, setData] = useState()
    const { user, clientProfiles } = useSelector((state) => state.user)
    const history = useHistory()

    useEffect(() => {
        const getRequestedSearch = async () => {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/REQUESTS/GET_SEARCH_CLIENTS_REQUESTS_LIST`,
                {
                    'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                    'PageIndex': '1',
                    'PageCount': '10'
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`
                    }
                }
            )
            setData(res.data.Data.Data)
        }
        getRequestedSearch()
    }, [])

    let departmentName = 'Department_Name_EN',
        serviceName = 'Service_Name_EN',
        requestStatusName = 'Request_Status_Name_EN',
        areaName = "Area_Name_EN";

    if (i18n.language === 'ar') {
        departmentName = 'Department_Name_AR'
        serviceName = 'Service_Name_AR'
        requestStatusName = 'Request_Status_Name_AR'
        areaName = "Area_Name_AR"
    }

    return (
        <>
            <Grid
                sourceData={data}
                addEnabled={false}
                editEnabled={false}
                deleteEnabled={false}
                viewEnabled={true}
                onView={({ data }) => {
                    // props.handleShow(data)
                    history.push(`/request-info/${data?.Request_Id}`)
                }}
                onDelete={({ data }) => { }}
            >
                <Column caption={t('request_no')} alignment="center" dataField="Request_No" dataType="number" width={150} />
                <Column caption={t('request_date')} alignment="center" dataField="Request_Date" dataType="date" width={150} format="yyyy-MM-dd" />
                <Column caption={t('service_name')} alignment="center" dataField={serviceName} dataType="string" />
                <Column caption={t('department_name')} alignment="center" dataField={departmentName} dataType="string" width={150} />
                <Column caption={t('request_status')} alignment="center" dataField={requestStatusName} dataType="string" width={150} />
                <Column caption={t('service_issuance')} alignment="center" dataField="Department_Service_Version_Name" dataType="string" width={120} />
                <Column caption={t("owner")} alignment="center" dataField='Owner_Name' dataType="string" width={140} visible={false} />
                <Column caption={t("plot_no")} alignment="center" dataField='Plot_No' dataType="string" width={140} visible={false} />
                <Column caption={t("area_name")} alignment="center" dataField={areaName} dataType="string" width={140} visible={false} />
                <Column caption={t("project_no")} alignment="center" dataField='Project_No' dataType="string" width={140} visible={false} />
            </Grid>
        </>
    )
}

export default ServicesGrid
