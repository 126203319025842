import React from 'react'
import Header from './../../UIComponents/Header/Header'
import Media from './../../UIComponents/Media/Media'
import AllServices from './../../UIComponents/AllServices/AllServices'
import Housing from './../../UIComponents/Housing/Housing'
import DownloadApp from './../../UIComponents/DownloadApp/DownloadApp'
import ContactUs from './../../UIComponents/ContactUs/ContactUs'
import Footer from './../../UIComponents/Footer/Footer'
import ViolationsSuspensions from './../../UIComponents/ViolationsSuspensions/ViolationsSuspensions'

const Home = () => {
    return (
        <>
            <Header />
            <Media />
            <AllServices homePage={true} />
            <Housing />
            {/* <DownloadApp homePage={true} /> */}
            <ContactUs />
            <Footer />

            <ViolationsSuspensions />
        </>
    )
}

export default Home
