import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import style from './Footer.module.scss'
import axios from 'axios'

const Municipalities = () => {

    const { t, i18n } = useTranslation('common')
    const [departments, setDepartments] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchDepartments = async () => {
            setLoading(true)
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/PORTAL/DEPARTMENTS/GET_ALL_DEPARTMENTS`)
            setDepartments(res.data.Data)
            setLoading(false)
        }
        fetchDepartments()
    }, [])

    return (
        <>
            <Row>
                <Col>
                    <p className={style.title}>{t('all_municipalities')}</p>
                </Col>
            </Row>

            <Row>
                {departments
                    .filter(department => department.Department_Id !== 0)
                    .map(department => (
                        <Col sm={4} xs={6} className='d-flex' key={department.Department_Id}>
                            <NavLink
                                className={style.municipalLink}
                                // to={`/municipal/${department.Department_Id}`} 
                                to={`/municipal/${department.Department_Id}/1`}
                            >
                                <span className={i18n.language === 'en' ? style.engArrow : null}>
                                    <svg id="arrow-left-s-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Path" d="M0,0H24V24H0Z" fill="none" />
                                        <path id="Path-2" data-name="Path" d="M0,6,6,0V12Z" transform="translate(8 6)" fill="#998350" />
                                    </svg>
                                </span>
                                <p>{i18n.language === 'en' ? department.Department_Name_EN : department.Department_Name_AR}</p>
                            </NavLink>
                        </Col>
                    ))}
            </Row>
        </>
    )
}

export default Municipalities
