import React from "react";
import style from "./UserDropdown.module.scss";
import { Row, Col } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logout } from "features/user/userSlice";

const userConfig = [
  {
    path: "/profile",
    name: "Client Porfile",
    icon: null,
    type: "tab",
    isDisabled: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: null,
    type: "tab",
    isDisabled: false,
  },
  {
    path: "/structuralAuditing",
    name: "structuralAuditing",
    icon: null,
    type: "tab",
    isDisabled: false,
  },
  {
    path: "/UserServicesSearch",
    name: "UserServicesSearch",
    icon: null,
    type: "tab",
    isDisabled: false,
  },
  {
    path: "/Invoices",
    name: "Invoices",
    icon: null,
    type: "tab",
    isDisabled: false,
  },
];

const UserDropdown = (props) => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    history.push('/homePage')
  };

  return (
    <div className={[
      style.dropdown,
      props.dropOpen ? style.open : null,
      i18n.language === "en" ? style.openEN : style.openAR
    ].join(" ")}>
      <Row className="px-2 mx-0">
        {userConfig.map((item) => (
          <Col xs={12} key={item.name}>
            <NavLink className={style.municipalLink} to={item.path}>
              <span className={i18n.language === "en" ? style.engArrow : null}>
                <svg id="arrow-left-s-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path id="Path" d="M0,0H24V24H0Z" fill="none" />
                  <path id="Path-2" data-name="Path" d="M0,6,6,0V12Z" transform="translate(8 6)" fill="#998350" />
                </svg>
              </span>
              <p className={style.municipalName}>{t(item.name)}</p>
            </NavLink>
          </Col>
        ))}
        <Col xs={12}>
          <span className={style.municipalLink} onClick={handleLogout}>
            <span className={i18n.language === "en" ? style.engArrow : null}>
              <svg id="arrow-left-s-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path" d="M0,0H24V24H0Z" fill="none" />
                <path id="Path-2" data-name="Path" d="M0,6,6,0V12Z" transform="translate(8 6)" fill="#998350" />
              </svg>
            </span>
            <p className={style.municipalName}>
              {t("logout")}
            </p>
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default UserDropdown;
