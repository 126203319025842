import React from 'react'
import style from './Pagination.module.scss'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Pagination = props => {

    const { t, i18n } = useTranslation('common')
    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(props.totalServices / props.servicesPerPage); i++) {
        pageNumbers.push(i)
    }

    return (
        <Col sx={6} className={[
            style.arrows,
            i18n.language === 'en' ? style.arrowsEn : style.arrowsAr
        ].join(' ')}>
            <span onClick={props.prevPage}>
                {props.currentPage <= 1 ?
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" cursor='default' opacity='0.2'
                        viewBox="0 0 22 22" xmlSpace="preserve">
                        <g>
                            <path d="M11,1.5c5.2,0,9.5,4.3,9.5,9.5s-4.3,9.5-9.5,9.5S1.5,16.2,1.5,11S5.8,1.5,11,1.5 M11,0C4.9,0,0,4.9,0,11
                                s4.9,11,11,11s11-4.9,11-11S17.1,0,11,0L11,0z"/>
                        </g>
                        <g>
                            <path d="M10,7C9.8,7,9.5,7.1,9.3,7.3l-3,3c-0.1,0.1-0.2,0.2-0.2,0.3C6,10.7,6,10.9,6,11c0,0.1,0,0.3,0.1,0.4
                                c0.1,0.1,0.1,0.2,0.2,0.3l3,3C9.5,14.9,9.7,15,10,15s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L9.6,12H15c0.6,0,1-0.4,1-1
                                c0-0.6-0.4-1-1-1H9.6l1.1-1.3c0.4-0.4,0.4-1,0-1.4C10.5,7.1,10.3,7,10,7L10,7z"/>
                        </g>
                    </svg>
                :
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 22 22" xmlSpace="preserve">
                <g>
                    <path d="M11,1.5c5.2,0,9.5,4.3,9.5,9.5s-4.3,9.5-9.5,9.5S1.5,16.2,1.5,11S5.8,1.5,11,1.5 M11,0C4.9,0,0,4.9,0,11
                        s4.9,11,11,11s11-4.9,11-11S17.1,0,11,0L11,0z"/>
                </g>
                <g>
                    <path d="M10,7C9.8,7,9.5,7.1,9.3,7.3l-3,3c-0.1,0.1-0.2,0.2-0.2,0.3C6,10.7,6,10.9,6,11c0,0.1,0,0.3,0.1,0.4
                        c0.1,0.1,0.1,0.2,0.2,0.3l3,3C9.5,14.9,9.7,15,10,15s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L9.6,12H15c0.6,0,1-0.4,1-1
                        c0-0.6-0.4-1-1-1H9.6l1.1-1.3c0.4-0.4,0.4-1,0-1.4C10.5,7.1,10.3,7,10,7L10,7z"/>
                </g>
            </svg>
            }
            </span>

            <span onClick={props.nextPage}>
                {props.currentPage >= props.totalServices / props.servicesPerPage ?
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" cursor='default' opacity='0.2'
                        viewBox="0 0 22 22" xmlSpace="preserve">
                        <g>
                            <path d="M11,1.5c5.2,0,9.5,4.3,9.5,9.5s-4.3,9.5-9.5,9.5S1.5,16.2,1.5,11S5.8,1.5,11,1.5 M11,0C4.9,0,0,4.9,0,11
                                s4.9,11,11,11s11-4.9,11-11S17.1,0,11,0L11,0z"/>
                        </g>
                        <g>
                            <path d="M10,7C9.8,7,9.5,7.1,9.3,7.3l-3,3c-0.1,0.1-0.2,0.2-0.2,0.3C6,10.7,6,10.9,6,11c0,0.1,0,0.3,0.1,0.4
                                c0.1,0.1,0.1,0.2,0.2,0.3l3,3C9.5,14.9,9.7,15,10,15s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L9.6,12H15c0.6,0,1-0.4,1-1
                                c0-0.6-0.4-1-1-1H9.6l1.1-1.3c0.4-0.4,0.4-1,0-1.4C10.5,7.1,10.3,7,10,7L10,7z"/>
                        </g>
                    </svg>
                    :
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 22 22" xmlSpace="preserve">
                        <g>
                            <path d="M11,1.5c5.2,0,9.5,4.3,9.5,9.5s-4.3,9.5-9.5,9.5S1.5,16.2,1.5,11S5.8,1.5,11,1.5 M11,0C4.9,0,0,4.9,0,11
                                s4.9,11,11,11s11-4.9,11-11S17.1,0,11,0L11,0z"/>
                        </g>
                        <g>
                            <path d="M10,7C9.8,7,9.5,7.1,9.3,7.3l-3,3c-0.1,0.1-0.2,0.2-0.2,0.3C6,10.7,6,10.9,6,11c0,0.1,0,0.3,0.1,0.4
                                c0.1,0.1,0.1,0.2,0.2,0.3l3,3C9.5,14.9,9.7,15,10,15s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L9.6,12H15c0.6,0,1-0.4,1-1
                                c0-0.6-0.4-1-1-1H9.6l1.1-1.3c0.4-0.4,0.4-1,0-1.4C10.5,7.1,10.3,7,10,7L10,7z"/>
                        </g>
                    </svg>
                    }
            </span>
        </Col>
    )
}

export default Pagination
