import React from "react";
import style from "./Request.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Grid from "components/UIComponents/Grid";
import { Column } from "devextreme-react/data-grid";
import { useHistory } from "react-router-dom";

const View = () => {
  const { t } = useTranslation("common");
  const history = useHistory();

  const { serviceData, loading } = useSelector((state) => state.service.serviceView);

  const customizeDownload = ({ data }) => {
    return (
      <a href={data.file} download>
        <FontAwesomeIcon size="sm" icon={faDownload} color="black" />
      </a>
    );
  };

  const handleLeave = () => {
    history.push(`/dashboard`);
  };

  return (
    <>
      <Row className="align-items-center position-relative">
        <Col xs={12}>
          <p className={style.title}>بيانات الطلب</p>
        </Col>
      </Row>
      <Row className="align-items-center position-relative mb-3">
        <Col xs={12} className={style.detailsContent}>
          <Row>
            <p className={style.details}>{t("Request Number")}</p>
            <p className={style.details}>{serviceData?.Request_Id ?? ""}</p>
          </Row>
          <Row>
            <p className={style.details}>{t("Request Date")}</p>
            <p className={style.details}>{serviceData?.Request_Date && moment(serviceData.Request_Date).lang("en").format("YYYY-MM-DD")}</p>
          </Row>
          <Row>
            <p className={style.details}>{t("department_name")}:</p>
            <p className={style.details}>{serviceData?.Department_Name_AR}</p>
          </Row>
          <Row>
            <p className={style.details}>{t("Service Version")}</p>
            <p className={style.details}>{serviceData?.Department_Service_Version_Name}</p>
          </Row>
        </Col>
      </Row>

      <Row>
        <Grid sourceData={dataArr} addEnabled={false} editEnabled={false} deleteEnabled={false}>
          <Column caption={"رقم الفاتورة"} alignment="center" dataField="Invoice_No" dataType="number" width="auto" />
          <Column caption={"قيمة الفاتورة"} alignment="center" dataField="Invoice_Amount" dataType="string" width={130} />
          <Column caption={"حالة الدفع"} alignment="center" dataField="Invoice_Status" dataType="string" width={150} />
          <Column caption={"تاريخ الدفع"} alignment="center" dataField="Invoice_Date" dataType="date" format="yyyy-MM-dd" width={130} />
          <Column caption={"رقم المرجع الإلكتروني"} alignment="center" dataField="Reference_No" dataType="string" width={150} />
          <Column caption={"رقم امر الدفع"} alignment="center" dataField="Payment_Order_No" dataType="string" width={150} />
          <Column caption={"تحميل"} alignment="center" dataField="file" cellRender={customizeDownload} width="auto" />
        </Grid>
      </Row>
      <Row className="align-items-center position-relative mb-3">
        <Col xs={12} className={`${style.detailsContent} d-flex justify-content-center`}>
          <span className={`${style.details} mx-1`} style={{ color: serviceData?.Request_Status_Id === 0 ? "#ff6347" : "#58743A" }}>
            {serviceData?.Request_Status_Name_AR}
          </span>
        </Col>
      </Row>

      {serviceData?.Request_Status_Id === 0 && (
        <Row className={`${style.step} align-items-center position-relative mb-3`}>
          <Col xs={12} className={style.detailsContent}>
            <Form.Label className={style.label}>سبب الرفض</Form.Label>
            <Form.Control as="textarea" rows={3} defaultValue={serviceData?.Request_Reject_Reasons ?? ""} disabled />
          </Col>
        </Row>
      )}

      <Row>
        <Col className="d-flex justify-content-end">
          <button type="button" className="mainBtn primarymainBtn smBtn" onClick={handleLeave}>
            {t("leave")}
          </button>
        </Col>
      </Row>
    </>
  );
};

export default View;

const dataArr = [
  {
    Invoice_No: "005-2021-SRV010-S",
    Invoice_Date: "21-04-2",
    Invoice_Status: "بانتظار الدفع",
    Invoice_Amount: "500",
    Payment_Order_No: "222-33-4323",
    Reference_No: "298313",
    file: "www.orimi.com/pdf-test.pdf",
  },
  {
    Invoice_No: "5-2021-SRV111-S",
    Invoice_Date: "1-04-1",
    Invoice_Status: "بانتظار الدفع",
    Invoice_Amount: "800",
    Payment_Order_No: "222-33-4323",
    Reference_No: "443536",
    file: "www.orimi.com/pdf-test.pdf",
  },
];
