import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { Row, Col } from "react-bootstrap"
import style from './DecisionsGroup.module.scss'
import { Formik, Form, Field } from "formik"
import moment from "moment"
import DateBox from 'devextreme-react/date-box';


const DecisionsList = props => {

    const { t, i18n } = useTranslation("common")
    const { decisionsList, activeTab, getSearchVal } = props
    const [fromDate, setFromDate] = useState(moment().format('yyyy-MM-DD'))
    const [toDate, setToDate] = useState(moment().format('yyyy-MM-DD'))

    const handleDownload = val => {
        console.log(val)
        let a = document.createElement("a"); // Create <a>
        a.href = val; // val 
        a.target = '_blank';
        a.download = val; // File name 
        a.click(); // Download file
    }

    const handleSearch = values => {
        getSearchVal({
            Decision_Code: values.Decision_Code,
            Search_Text: values.Search_Text,
            from_date: fromDate,
            to_date: toDate
        })
    }

    return (
        <>
            <Row>
                <Col>
                    <Formik
                        initialValues={{
                            Search_Text: '',
                            Decision_Code: '',
                            from_date: '',
                            to_date: '',
                        }}
                        onSubmit={handleSearch}
                    >
                        {({ setFieldValue, submitForm, resetForm, values }) => (
                            <Form>
                                <Row>
                                    <Col md={4}>
                                        <Field 
                                            name="Decision_Code" 
                                            type="text" 
                                            className={style.input}
                                            placeholder={t('decision no')}
                                            onKeyUp={() => submitForm()}
                                        />
                                    </Col>

                                    <Col md={4} className={style.dateCont}>
                                        <DateBox
                                            acceptCustomValue={false}
                                            dateSerializationFormat="yyyy-MM-dd"
                                            displayFormat="yyyy-MM-dd"
                                            type="date"
                                            placeholder={t('from date')}
                                            onValueChanged={e => {
                                                setFromDate(e.value)
                                                console.log(fromDate)
                                            }}
                                        />
                                    </Col>

                                    <Col md={4} className={style.dateCont}>
                                        <DateBox
                                            acceptCustomValue={false}
                                            dateSerializationFormat="yyyy-MM-dd"
                                            displayFormat="yyyy-MM-dd"
                                            type="date"
                                            placeholder={t('to date')}
                                            onValueChanged={e => setToDate(e.value)}
                                        />
                                    </Col>

                                    <Col md={9}>
                                        <div>
                                            <Field 
                                                name="Search_Text" 
                                                type="text" 
                                                className={style.input}
                                                placeholder={t('search decisions')}
                                                onKeyUp={() => submitForm()}
                                            />

                                            <span className={[style.searchIcon, i18n.language === "en" ? style.searchIconEn : style.searchIconAr].join(" ")}>
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                <title>search-line</title>
                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="0" transform="translate(-584.000000, -222.000000)">
                                                    <g id="search-line" transform="translate(584.000000, 222.000000)">
                                                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                                        <path
                                                        d="M18.031,16.617 L22.314,20.899 L20.899,22.314 L16.617,18.031 C15.0237382,19.3082211 13.0420011,20.0029052 11,20 C6.032,20 2,15.968 2,11 C2,6.032 6.032,2 11,2 C15.968,2 20,6.032 20,11 C20.0029052,13.0420011 19.3082211,15.0237382 18.031,16.617 Z M16.025,15.875 C17.2941129,14.5698857 18.002867,12.8204293 18,11 C18,7.132 14.867,4 11,4 C7.132,4 4,7.132 4,11 C4,14.867 7.132,18 11,18 C12.8204293,18.002867 14.5698857,17.2941129 15.875,16.025 L16.025,15.875 L16.025,15.875 Z"
                                                        id="Shape"
                                                        fill="#000000"
                                                        fillRule="nonzero"></path>
                                                    </g>
                                                    </g>
                                                </g>
                                                </svg>
                                            </span>
                                        </div>
                                    </Col>

                                    <Col md={3} className='d-flex align-items-center'>
                                        <button 
                                            className='mainBtn secondarymainBtn outlined w-100 py-2'
                                            type='reset'
                                            onClick={() => {
                                                setFromDate(null)
                                                setToDate(null)
                                                setFieldValue('from_date', null)
                                                setFieldValue('to_date', null)
                                                resetForm()
                                                submitForm()
                                            }}
                                        >
                                            {t('cancel')}
                                        </button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>

            <Row className="py-2">
                <Col md={8} className="d-flex align-items-center">
                    <span className={style.activeCategory}>
                        {
                            activeTab ?
                                <>
                                    {i18n.language === "en" ? activeTab.Decision_category_Name_EN : activeTab.Decision_category_Name_AR}
                                </>
                                : 
                                <>
                                    {t("all")}
                                </>
                        }
                    </span>
                </Col>

                <Col md={4} className="d-flex justify-content-end align-items-center">
                    <p className={style.searchResults}>
                        {t("search_results")} {decisionsList?.length}
                    </p>
                </Col>
            </Row>

            <Row>
                {decisionsList?.filter(decision => {
                    if (!props.searchVal) return true;
                    if (i18n.language === "en") {
                        if (decision.Decision_Name_EN.includes(props.searchVal) || decision.Decision_Description_EN.includes(props.searchVal)) {
                            return true;
                        }
                    } else {
                        if (decision.Decision_Name_AR.includes(props.searchVal) || decision.Decision_Description_AR.includes(props.searchVal)) {
                            return true;
                        }
                    }
                })
                .map(decision => (
                    <Col xl={12} 
                        key={decision.Decision_Id}
                        className={style.decision}
                    >
                        <p className={style.decisionTitle}>{i18n.language === "en" ? decision.Decision_Name_EN : decision.Decision_Name_AR}</p>
                        <p className={style.decisionDes}>{i18n.language === "en" ? decision.Decision_Description_EN : decision.Decision_Description_AR}</p>
                        <div 
                            className={style.download} 
                            onClick={() => handleDownload(decision.Decision_File_Url)}
                        >
                            <span>
                                <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px">
                                    <g id="Solid">
                                        <path d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z"/>
                                        <path d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z"/>
                                    </g>
                                </svg>
                            </span>
                            <p className={style.downloadTitle}>{t('Download decision')}</p>
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default DecisionsList
