import React from 'react'
import style from './ViolationsSuspensions.module.scss'
import { useTranslation } from 'react-i18next'
import Grid from 'components/UIComponents/Grid'
import { Column } from 'devextreme-react/data-grid'
import { Container, Row, Col } from 'react-bootstrap'

const SuspensionsGrid = props => {

    const { t, i18n } = useTranslation('common')

    let clientName = "Client_Name_EN",
    violationsStatus = "Violations_Status_Name_EN",
    departmentName = "Department_Name_EN",
    dealingType = "Dealing_Type_Name_EN";

    if (i18n.language === "ar") {
        clientName = "Client_Name_AR";
        violationsStatus = "Violations_Status_Name_AR";
        departmentName = "Department_Name_AR";
        dealingType = "Dealing_Type_Name_AR";
    }

    return (
        <Container>
            <Row className='justify-content-center'>
                <p className={style.gridTitle}>{t("suspensions")}</p>
            </Row>

            <Row>
                <Col>
                    <Grid 
                    sourceData={props.suspensions} 
                    addEnabled={false} 
                    editEnabled={false} 
                    deleteEnabled={false} 
                    viewEnabled={false}
                    onView={({ data }) => {}} 
                    onDelete={({ data }) => {}}
                    >
                        <Column caption={t('suspension_no')} alignment="center" dataField="Violation_Id" dataType="string" width={150} />
                        <Column caption={t('suspension_date')} alignment="center" dataField="Violation_Date" dataType="date" width={150} format="yyyy-MM-dd" />
                        <Column caption={t('client_name')} alignment="center" dataField={clientName} dataType="string" width={150} />
                        <Column caption={t('client_type')} alignment="center" dataField={dealingType} dataType="string" width={150} />
                        <Column caption={t('suspension_state')} alignment="center" dataField={violationsStatus} dataType="string" width={150} />
                        <Column caption={t('suspension_municipality')} alignment="center" dataField={departmentName} dataType="string" width={150} />
                    </Grid>
                </Col>
            </Row>
            <Row className='mt-3 d-sm-block d-md-none'>
                <Col className='d-flex justify-content-center'>
                    <button onClick={() => props.toggleSidebar(false)} className="mainBtn smBtn primarymainBtn w-100">{t("close")}</button>
                </Col>
            </Row>
        </Container>
    )
}

export default SuspensionsGrid