import React, { useState, useEffect } from 'react'
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import fallbackImg from './../../../global/images/housing_fall_back.jpg'

const Slider = props => {

    const { slider, data } = props
    let images = []

    useEffect(() => {
        if (slider == 1) {
            data?.Templates_Data?.map(img => images.push({
                original: img.Approving_Chart_Template_Image_Url,
                thumbnail: img.Approving_Chart_Template_Image_Url,
            }))
        } else if (slider == 2) {
            data?.Images_Data?.map(img => images.push({
                original: img.Approving_Chart_Image_Url,
                thumbnail: img.Approving_Chart_Image_Url,
            }))
        } else if (slider == 3) {
            let canvas = document.getElementById('myCanvas');
            let ctx = canvas.getContext('2d');
            data?.Templates_Data?.map(img => {
                ctx.fillStyle = img.Approving_Chart_Template_Color;
                ctx.fillRect(0, 0, 930, 519);
                images.push({
                    original: canvas.toDataURL(),
                    thumbnail: canvas.toDataURL(),
                    description: `${img.Approving_Chart_Template_Color} ${img.Approving_Chart_Template_Color_Name}`
                })
            })
        }

    },[slider, data])


    return (
        <>
            <div className="wrapper slider">
                <ImageGallery 
                    items={images} 
                    slideDuration={3000} 
                    autoPlay={true} 
                    infinite={true} 
                    lazyLoad={true}
                    showBullets={false}
                    showPlayButton={false}
                    slideOnThumbnailOver={true}
                    showThumbnails={slider == 3 ? false : true}
                    onErrorImageURL={fallbackImg}
                    // showNav={false}
                    // thumbnailHeight='100'
                />
            </div>

            <canvas id="myCanvas" style={{display: 'none'}} />
        </>
    )
}

export default Slider