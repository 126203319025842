import React, { useState } from "react";
import ReturnRequestsGrid from "./ReturnRequestsGrid";
import PopUp from "../../../UIComponents/PopUp/PopUp";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import style from "./../form.module.scss";
import moment from "moment";

const ReturnRequests = props => {
  const { t, i18n } = useTranslation("common");
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState();

  const handleClose = () => setShow(false);

  const handleShow = (data) => {
    setShow(true);
    setModalData(data);
  };

  return (
    <>
      <ReturnRequestsGrid handleShow={handleShow} dataIsReady={props.dataIsReady} />

      {modalData ? (
        <PopUp
          show={show}
          handleClose={handleClose}
          title={t("returned_requests")}
        >
          <Container>
            <Formik
              initialValues={{
                request_no: modalData?.Request_No,
                request_date: moment(modalData?.Request_Date)
                  .lang("en")
                  .format("YYYY-MM-DD"),
                request_status:
                  i18n.language === "en"
                    ? modalData?.Request_Status_Name_EN
                    : modalData?.Request_Status_Name_AR,
                department_name:
                  i18n.language === "en"
                    ? modalData?.Department_Name_EN
                    : modalData?.Department_Name_AR,
                service_name:
                  i18n.language === "en"
                    ? modalData?.Service_Name_EN
                    : modalData?.Service_Name_AR,
              }}
            >
              <Form className={style.dashboardData}>
                <Row
                  style={{ textAlign: i18n.language === "ar" ? "start" : null }}
                >
                  <Col md={3} sm={6}>
                    <label className={style.name}>{t("request_no")}</label>
                    <Field
                      className={style.dashboardField}
                      name="request_no"
                      type="text"
                    />
                  </Col>
                  <Col md={3} sm={6}>
                    <label className={style.name}>{t("request_date")}</label>
                    <Field
                      className={style.dashboardField}
                      name="request_date"
                      type="date"
                    />
                  </Col>
                  <Col md={3} sm={6}>
                    <label className={style.name}>{t("request_status")}</label>
                    <Field
                      className={style.dashboardField}
                      name="request_status"
                      type="text"
                    />
                  </Col>
                  <Col md={3} sm={6}>
                    <label className={style.name}>{t("department_name")}</label>
                    <Field
                      className={style.dashboardField}
                      name="department_name"
                      type="text"
                    />
                  </Col>
                  <Col sm={12}>
                    <label className={style.name}>{t("service_name")}</label>
                    <Field
                      name="service_name"
                      type="text"
                      as="textarea"
                      className={[
                        "text-center title mainName",
                        style.dashboardField,
                      ].join(" ")}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <button className="mainBtn lgBtn primarymainBtn">
                      {t("save")}
                    </button>
                  </Col>
                </Row>
              </Form>
            </Formik>
          </Container>
        </PopUp>
      ) : null}
    </>
  );
};

export default React.memo(ReturnRequests);
