import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import style from './SearchModal.module.scss'
import axios from 'axios'
import { NavLink } from "react-router-dom"

const SearchModal = props => {

    const { t, i18n } = useTranslation('common')
    const [searchResults, setSearchResults] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [resultsPerPage, setResultsPerPage] = useState(20)
    const [searchKey, setSearchKey] = useState('')
    const pageNumbers = []

    useEffect(() => {
        if (searchKey) {
            handleSearch()
        }
    }, [searchKey])

    const handleSearch = async () => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/PORTAL/GENERAL/GET_GENERAL_SEARCH_LIST`,
            {
                PageCount: '100',
                PageIndex: '1',
                Search_Text: searchKey
            },
        );
        setSearchResults(res?.data?.Data?.Data)
    }

    const indexOfLastPost = currentPage * resultsPerPage
    const indexOfFirstPost = indexOfLastPost - resultsPerPage
    const currentResults = searchResults?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = pageNumber => setCurrentPage(pageNumber)

    for (let i = 1; i <= Math.ceil(searchResults?.length / resultsPerPage); i++) {
        pageNumbers.push(i)
    }

    const Disabled = {
        background: '#dee2e6',
        color: 'grey',
    }

    const onClosing = () => {
        props.onHideModal(true)
        setSearchResults(null)
        setSearchKey('')
    }

    return (
        <div className={[
            props.showModal ? style.openSearchModal : style.hideSearchModal
        ].join(' ')}>
            <Container className='my-4' fluid>
                <Row>
                    <Col md={11} sm={10} xs={9} className={[
                        style.searchInput,
                        i18n.language === 'en' ? style.searchInputEn : style.searchInputAr
                    ].join(' ')}>
                        <Form>
                            <Form.Control
                                type="text"
                                value={searchKey}
                                placeholder={t('search_for')}
                                onChange={e => setSearchKey(e.target.value)}
                            />
                            <span className={[
                                style.searchIcon,
                                i18n.language === 'en' ? style.searchIconEn : style.searchIconAr
                            ].join(' ')}>
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <title>search-line</title>
                                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="0" transform="translate(-584.000000, -222.000000)">
                                            <g id="search-line" transform="translate(584.000000, 222.000000)">
                                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                                <path d="M18.031,16.617 L22.314,20.899 L20.899,22.314 L16.617,18.031 C15.0237382,19.3082211 13.0420011,20.0029052 11,20 C6.032,20 2,15.968 2,11 C2,6.032 6.032,2 11,2 C15.968,2 20,6.032 20,11 C20.0029052,13.0420011 19.3082211,15.0237382 18.031,16.617 Z M16.025,15.875 C17.2941129,14.5698857 18.002867,12.8204293 18,11 C18,7.132 14.867,4 11,4 C7.132,4 4,7.132 4,11 C4,14.867 7.132,18 11,18 C12.8204293,18.002867 14.5698857,17.2941129 15.875,16.025 L16.025,15.875 L16.025,15.875 Z" id="Shape" fill="#000000" fillRule="nonzero"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </Form>
                    </Col>

                    <Col md={1} sm={2} xs={3} className={style.closeCont}>
                        <span
                            className={style.closeIcon}
                            onClick={e => onClosing(e)}
                        >
                            <svg height="311pt" viewBox="0 0 311 311.07733" width="311pt" xmlns="http://www.w3.org/2000/svg">
                                <path d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" />
                                <path d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
                            </svg>
                        </span>
                    </Col>
                </Row>

                <Container fluid="lg">

                    <Row className="justify-content-end align-items-center mt-5">
                        {searchResults ?
                            <Col md={3} className="d-flex justify-content-end">
                                <p className={style.resultsNo}>
                                    {t("search_results")} {searchResults?.length}
                                </p>
                            </Col>
                            : null}
                    </Row>

                    <Row>
                        {currentResults?.map((res, i) => (
                            <Col
                                xl={12}
                                key={i}
                                className={[
                                    style.resContainer,
                                    i18n.language === "en" ? style.arrowEn : null
                                ].join(" ")}
                            >
                                <p className={style.resTitle}>
                                    {i18n.language === "en" ? res.Name_EN : res.Name_AR}
                                </p>
                                <p className={style.resDesc}>
                                    {i18n.language === "en" ? res.Description_EN : res.Description_AR}
                                </p>
                                <p
                                    className={style.resContent}
                                    dangerouslySetInnerHTML={
                                        { __html: i18n.language == 'en' ? res.Content_EN : res.Content_AR }
                                    }
                                >
                                </p>

                                <span className="d-flex align-items-center">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22 22" xmlSpace="preserve">
                                        <path d="M0,11C0,4.9,4.9,0,11,0c6.1,0,11,4.9,11,11s-4.9,11-11,11S0,17.1,0,11z M6,11c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3
                                            l3,3C9.5,14.9,9.7,15,10,15s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L9.6,12H15c0.6,0,1-0.4,1-1s-0.4-1-1-1H9.6l1.1-1.3
                                            c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-3,3c-0.1,0.1-0.2,0.2-0.2,0.3C6,10.7,6,10.9,6,11z"
                                        />
                                    </svg>
                                    <NavLink
                                        className={style.resLink}
                                        to={
                                            res.Description_No == 1 ? '../decisions_group'
                                                : res.Description_No == 2 ? `../service-card/${res?.ID}`
                                                    : res.Description_No == 3 ? '../homePage'
                                                        : res.Description_No == 4 ? '../housing_models'
                                                            : res.Description_No == 5 ? '../common_questions'
                                                                : '../homePage'
                                        }
                                        onClick={() => onClosing()}
                                    >
                                        {t("details")}
                                    </NavLink>
                                </span>
                            </Col>
                        ))}
                    </Row>

                    <Row className="my-2">
                        <nav>
                            <ul className="pagination">
                                {
                                    pageNumbers?.map(number => (
                                        <li key={number} className='page-item'>
                                            <button
                                                disabled={number === currentPage}
                                                href='!#'
                                                className='page-link'
                                                onClick={() => paginate(number)}
                                                style={number === currentPage ? Disabled : null}
                                            >
                                                {number}
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                        </nav>
                    </Row>

                </Container>

            </Container>
        </div>
    )
}

export default SearchModal
