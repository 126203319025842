import React from "react";
import { Formik } from "formik";

const FormWrapper = ({ children, initialValues, validation, onSubmit }) => {
  return (
    <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validation} onSubmit={onSubmit}
    render={children}
    >
    </Formik>
  );
};

export default FormWrapper;
