import React, { useState, useEffect } from "react";
import transitionVairant from "./transitionVairant.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { Form } from "formik";
import FormWrapper from "components/UIComponents/Login/FormWrapper";
import { login } from "features/user/userSlice";
import InputField from "components/UIComponents/Login/Input";
import TransitionControl from "components/UIComponents/StateMachine/TransitionControl";
import { toState } from "./userMachineSlice";
import * as Yup from "yup";
import { Container, Row, Col } from "react-bootstrap";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import formStyle from "./formStyle.module.scss";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";

const validationSchema = Yup.object({
  Login_Name: Yup.string().required("This field is required"),
  Login_Password: Yup.string().required("This field is required"),
});

const SavePasswordCheck = withStyles({
  root: {
    color: "#58743A",
    padding: "1px",
    "&$checked": {
      color: "#58743A",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const LoginForm = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("auth");
  const { loading, error } = useSelector((state) => state.user);
  const [savePassword, setSavePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const onSubmit = (values) => {
    dispatch(login(values));
  };

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Row className="justify-content-center mx-0">
        <Col lg={4} md={5} sm={12} className={formStyle.cardStyle}>
          <Row>
            <Col sm={12}>
              <p className={formStyle.heading}>
                <Trans i18nKey={"Login"} t={t} />
              </p>

              <div className="border-bottom w-75 my-3 mb-3 mx-auto"></div>
            </Col>
          </Row>

          {/* <button type="button" className={`${styles.btn} ${styles.uaePassBtn}`}>
            SIGN IN WITH UAE PASS
          </button> */}
          {/* <hr className={styles.hr} /> */}

          <FormWrapper initialValues={{ Login_Name: "", Login_Password: "" }} validation={validationSchema} onSubmit={onSubmit}>
            {(props) => (
              <Row>
                <Form className={`${formStyle.form}`}>
                  <Col md={12}>
                    <label className={`${formStyle.label}`}>{t("NameorLicenseNum")} </label>
                    <InputField name="Login_Name" className={`${formStyle.input}`} type="text" autoComplete="off" />
                  </Col>

                  <Col md={12}>
                    <label className={`${formStyle.label}`}>{t("Password")}</label>
                    <InputField
                      name="Login_Password"
                      className={`${formStyle.input}`}
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="off"
                    />
                    {props.values.Login_Password ?
                      <span
                        className={[
                          formStyle.passwordIcon,
                          i18n.language === "ar" ? formStyle.passwordIconAR : formStyle.passwordIconEN
                        ].join(' ')}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ?
                          <svg className={`${formStyle.eyeSvg}`} version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                              fill="#000000" stroke="none">
                              <path d="M2275 4194 c-631 -96 -1266 -455 -1865 -1054 -197 -197 -345 -370
                          -379 -440 -22 -46 -26 -69 -26 -140 0 -113 24 -162 151 -309 438 -508 1011
                          -946 1508 -1154 344 -143 604 -193 960 -184 339 8 607 75 951 237 401 187 882
                          553 1252 950 261 281 288 324 288 460 0 116 -23 162 -165 325 -384 440 -832
                          803 -1271 1029 -300 154 -557 241 -839 281 -131 18 -441 18 -565 -1z m477
                          -549 c146 -26 292 -84 423 -170 77 -50 250 -223 300 -300 125 -191 184 -386
                          185 -611 1 -357 -157 -673 -445 -889 -190 -143 -410 -215 -657 -215 -610 0
                          -1099 490 -1098 1099 1 296 100 545 300 755 119 125 246 212 393 270 66 26
                          192 61 252 69 70 10 276 5 347 -8z"/>
                              <path d="M2445 3340 c-193 -31 -373 -133 -495 -283 -62 -76 -133 -217 -157
                          -312 -24 -101 -22 -286 5 -386 75 -276 288 -487 572 -565 97 -27 290 -25 393
                          4 418 118 661 530 562 952 -21 89 -93 232 -155 307 -101 125 -271 233 -420
                          267 -84 20 -229 27 -305 16z"/>
                            </g>
                          </svg>
                          :
                          <svg className={`${formStyle.eyeSvg}`} version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                              fill="#000000" stroke="none">
                              <path d="M4905 5104 c-16 -9 -298 -283 -625 -610 l-595 -594 -110 54 c-847
                            418 -1660 321 -2530 -305 -333 -239 -745 -642 -994 -972 -52 -68 -63 -124 -36
                            -178 24 -47 181 -240 312 -384 229 -250 528 -516 770 -682 l72 -50 -565 -564
                            c-311 -310 -574 -581 -585 -601 -24 -48 -24 -89 1 -138 23 -45 80 -80 130 -80
                            17 0 47 9 68 19 46 25 4864 4844 4886 4888 61 123 -78 260 -199 197z m-2097
                            -1524 c46 -12 116 -35 155 -51 73 -30 217 -115 217 -127 0 -4 -47 -54 -104
                            -111 l-104 -104 -53 30 c-148 84 -337 112 -507 77 -394 -81 -656 -458 -592
                            -852 14 -82 33 -141 76 -226 l35 -70 -103 -103 c-56 -57 -106 -103 -110 -103
                            -12 0 -97 144 -127 217 -60 145 -76 232 -75 408 1 129 5 175 22 235 55 203
                            146 366 281 500 155 156 372 266 586 300 97 15 308 4 403 -20z"/>
                              <path d="M3871 3236 l-323 -323 21 -66 c107 -348 0 -760 -268 -1028 -268 -268
                            -680 -375 -1028 -268 l-66 21 -241 -241 c-132 -132 -237 -243 -234 -246 12
                            -12 229 -85 326 -110 192 -48 283 -59 507 -59 172 1 232 5 330 23 357 65 706
                            212 1055 444 367 245 736 586 1050 972 159 195 159 215 0 410 -231 284 -582
                            633 -781 775 l-25 19 -323 -323z"/>
                              <path d="M2876 2241 c-321 -321 -414 -420 -401 -425 55 -21 254 4 359 45 211
                            83 391 285 451 508 19 69 31 252 19 274 -8 14 -86 -60 -428 -402z"/>
                            </g>
                          </svg>
                        }
                      </span>
                      : null}
                  </Col>

                  <Row className="mx-0 mt-1 mb-0 align-items-center">
                    <Col sm={6}>
                      <FormGroup>
                        <FormControlLabel
                          className="mx-0"
                          control={<SavePasswordCheck checked={savePassword} onChange={() => setSavePassword(!savePassword)} name="savePassword" />}
                          label={<span className={formStyle.checkbox}>{t("keep_me_signed")}</span>}
                        />
                      </FormGroup>
                    </Col>

                    <Col sm={6} style={{ textAlign: "end" }}>
                      <TransitionControl callback={() => dispatch(toState("Forgot"))} triggerLabel={<Trans i18nKey={"forgot your password"} t={t} />} styles={transitionVairant} />
                    </Col>
                  </Row>

                  <Col md={12}>
                    <button
                      type="submit"
                      className={[formStyle.primBtn, formStyle.formBtn].join(" ")}
                      data-cursor={`${props.isValid && props.dirty && loading !== "pending" ? "pointer" : loading !== "pending" ? "not-allowed" : "wait"}`}
                      disabled={!(props.isValid && props.dirty) || loading === "pending"}>
                      {loading !== "pending" ? <Trans i18nKey={"Login"} t={t} /> : <Trans i18nKey={"Please wait"} t={t} />}
                    </button>
                  </Col>
                </Form>
              </Row>
            )}
          </FormWrapper>

          <Row>
            <Col sm={12}>
              <div className="border-bottom w-75 my-0 mb-3 mx-auto"></div>

              <TransitionControl
                callback={() => dispatch(toState("Register"))}
                additional={<Trans i18nKey={"You don't have an account"} t={t} />}
                triggerLabel={<Trans i18nKey={"Register a new account"} t={t} />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
          {i18n.language === "ar" ? error.Error_AR : error.Error_EN}
        </MuiAlert>
      </Snackbar>
      {loading === "pending" && <LoadingOverlay />}
    </>
  );
};

export default LoginForm;
