import React, { useState, useEffect } from "react";
import Header from "./../../UIComponents/Header/Header";
import InnerTitleCont from "./../../UIComponents/InnerTitleCont/InnerTitleCont";
import Footer from "./../../UIComponents/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import UserServices from "./UserServices/UserServices";
import SearchList from "./SearchList/SearchList";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Pagination from "./Pagination/Pagination";
import { useSelector } from "react-redux";

const Services = () => {
  const { t, i18n } = useTranslation("common");
  const history = useHistory();

  const [dealers, setDealers] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [servicesPerPage, setServicesPerPage] = useState(20);
  const [searchVal, setSearchVal] = useState("");
  const [activeTabName, setActiveTabName] = useState();
  const [activeTabId, setActiveTabId] = useState();
  const { user, clientProfiles } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchDealersTypes = async () => {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/PORTAL/SERVICES/GET_DEALERS_TYPES_LIST`
      );
      setDealers(res.data.Data);
      setLoading(false);
    };
    fetchDealersTypes();
  }, []);

  useEffect(() => {
    if (dealers.length > 0) {
      if (clientProfiles?.Dealing_Type_Id) {
        getServiceList(clientProfiles?.Dealing_Type_Id);
      } else {
        getServiceList(dealers[0].Dealing_Type_Id);
      }
    }
  }, [dealers]);

  const getServiceList = (id) => {
    if (id === false) return;
    const fetchserviceList = async () => {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/PORTAL/SERVICES/GET_SERVICES_LIST`,
        {
          Dealing_Type_Id: id,
        },
        user?.access_token
          ? {
            headers: {
              Authorization: `Bearer ${user?.access_token}`,
            },
          }
          : false
      );
      setServiceList(res.data.Data);
      setLoading(false);
    };
    fetchserviceList();
    let tab = dealers.filter((dealer) =>
      dealer.Dealing_Type_Id === id ? dealer : null
    );
    setActiveTabName(
      i18n.language === "en"
        ? tab[0].Dealing_Type_Name_EN
        : tab[0].Dealing_Type_Name_AR
    );
    setActiveTabId(id);
    setCurrentPage(1)
  };

  const getSearchVal = (val) => {
    setSearchVal(val);
  };

  const indexOfLastPost = currentPage * servicesPerPage;
  const indexOfFirstPost = indexOfLastPost - servicesPerPage;
  const currentServices = serviceList.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Header />
      <InnerTitleCont
        breadCrumbs={t("menu_home")}
        title={t("all_services")}
        startBtn={false}
        leaveBtn={false}
      />
      <Container fluid="lg" className="pageContainer">
        <Row className="mb-5">
          <Col md={4}>
            <div className="gCard">
              <UserServices
                dealers={dealers}
                getServiceList={getServiceList}
                activeTabId={activeTabId}
              />
            </div>
          </Col>
          <Col md={8}>
            <div className="gCard">
              <SearchList
                serviceList={currentServices}
                searchVal={searchVal}
                getSearchVal={getSearchVal}
                activeTabName={activeTabName}
                activeTabId={activeTabId}
              />
            </div>

            <Row className="mt-5">
              <Pagination
                servicesPerPage={servicesPerPage}
                totalServices={serviceList.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Services;
