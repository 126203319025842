import React, { useEffect } from "react";
import InnerTitleCont from "components/UIComponents/InnerTitleCont/InnterTitleState";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import View from "./View";
import { useDispatch, useSelector } from "react-redux";
import Header from "components/UIComponents/Header/Header";
import Footer from "components/UIComponents/Footer/Footer";
import { useParams, useHistory } from "react-router-dom";
import { fetchCompletedRequestInfo, fetchUnderProcessRequestInfo, fetchRejectedRequestInfo, resetRequest } from "./ServiceViewSlice";

const ServiceView = () => {
  const { clientProfiles } = useSelector((state) => state.user);
  const { requestStatusId, requestId } = useParams();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (requestStatusId && requestId) {
      if (requestStatusId === "3") {
        const promise = dispatch(
          fetchCompletedRequestInfo({
            Request_Id: requestId,
            Client_Profile_Id: clientProfiles.Client_Profile_Id,
          })
        );
        return () => {
          promise.abort();
          dispatch(resetRequest());
        };
      } else if (requestStatusId === "2") {
        const promise = dispatch(
          fetchUnderProcessRequestInfo({
            Request_Id: requestId,
            Client_Profile_Id: clientProfiles.Client_Profile_Id,
          })
        );
        return () => {
          promise.abort();
          dispatch(resetRequest());
        };
      } else if (requestStatusId === "0") {
        const promise = dispatch(
          fetchRejectedRequestInfo({
            Request_Id: requestId,
            Client_Profile_Id: clientProfiles.Client_Profile_Id,
          })
        );
        return () => {
          promise.abort();
          dispatch(resetRequest());
        };
      }
    } else {
      history.push(`/dashboard`);
    }
  }, []);

  const handleLeave = () => {
    history.push(`/dashboard`);
  };

  return (
    <>
      <Header />

      <InnerTitleCont
        breadCrumbs={`${t("menu_home")} / ${t("all_services")} / اسم الطلب`}
        title={t("service_view")}
        startBtn={false}
        leaveBtn={true}
        startTrigger={() => {}}
        leaveTrigger={handleLeave}
      />
      <Container fluid="lg" style={{ position: "relative", marginTop: "-50px" }}>
        <Row className="mb-5">
          <Col xs={12}>
            <div className="gCard">
              <View />
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default ServiceView;
