import React, { useState, useRef, useEffect } from "react";
import style from "./Icons.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { NavLink } from "react-router-dom";
import LanguageBtn from "../../LanguageBtn/LanguageBtn";
import SearchModal from "./../../SearchModal/SearchModal";
// import UserDropdown from "./UserDropdown/UserDropdown";
import useDetectOutsideClick from "hooks/useDetectOutsideClick";
import UserLogin from './../UserLogin/UserLogin'

const Icons = () => {
  const { t, i18n } = useTranslation("common");
  const [showModal, setShowModal] = useState(false);
  const { user, clientProfiles, isAuthenticated } = useSelector((state) => state.user);
  const dropdownRef = useRef(null);
  const [dropOpen, setDropOpen] = useDetectOutsideClick(dropdownRef, false);

  const onHideModal = () => {
    setShowModal(false);
  };

  const onCloseDropdown = () => {
    setDropOpen(false);
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflowY = 'scroll'
      document.body.style.overflowX = 'hidden'
    }
  }, [showModal])

  return (
    <>

      <ul className={style.list}>
        <li style={{ zIndex: 9 }} onClick={() => setShowModal(true)}>
          <span className={[style.icon, i18n.language === "en" ? style.iconEn : style.iconAr].join(" ")}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <title>search-line</title>
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="0" transform="translate(-584.000000, -222.000000)">
                  <g id="search-line" transform="translate(584.000000, 222.000000)">
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <path
                      d="M18.031,16.617 L22.314,20.899 L20.899,22.314 L16.617,18.031 C15.0237382,19.3082211 13.0420011,20.0029052 11,20 C6.032,20 2,15.968 2,11 C2,6.032 6.032,2 11,2 C15.968,2 20,6.032 20,11 C20.0029052,13.0420011 19.3082211,15.0237382 18.031,16.617 Z M16.025,15.875 C17.2941129,14.5698857 18.002867,12.8204293 18,11 C18,7.132 14.867,4 11,4 C7.132,4 4,7.132 4,11 C4,14.867 7.132,18 11,18 C12.8204293,18.002867 14.5698857,17.2941129 15.875,16.025 L16.025,15.875 L16.025,15.875 Z"
                      id="Shape"
                      fill="#000000"
                      fillRule="nonzero"></path>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </li>

        <li style={{ zIndex: 9 }} className={style.stickyIcon}>
          <span className={[style.icon, i18n.language === "en" ? style.iconEn : style.iconAr].join(" ")}>
            <LanguageBtn />
          </span>
        </li>

        <li style={{ zIndex: 9 }} className={style.stickyIcon}>
          <span className={[style.icon, i18n.language === "en" ? style.iconEn : style.iconAr].join(" ")}>
            <svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path d="m289.039 123.038c0-18.217-14.821-33.038-33.039-33.038s-33.039 14.821-33.039 33.038c0 18.218 14.821 33.039 33.039 33.039s33.039-14.821 33.039-33.039z" />
                <path d="m136 186.077v30h83.039v79.02l-54.51 94.413 25.981 15 65.49-113.433 65.49 113.433 25.981-15-54.51-94.413v-79.02h83.039v-30z" />
                <path d="m437.02 74.98c-48.353-48.351-112.64-74.98-181.02-74.98s-132.667 26.629-181.02 74.98c-48.351 48.353-74.98 112.64-74.98 181.02s26.629 132.667 74.98 181.02c48.353 48.351 112.64 74.98 181.02 74.98s132.667-26.629 181.02-74.98c48.351-48.353 74.98-112.64 74.98-181.02s-26.629-132.667-74.98-181.02zm-31.02 171.097h-83.039v40.98l65.49 113.433-77.942 45-54.509-94.413-54.51 94.413-77.942-45 65.49-113.433v-40.98h-83.038v-90h96.342c-5.941-9.614-9.38-20.931-9.38-33.039-.001-34.76 28.278-63.038 63.038-63.038s63.039 28.278 63.039 63.038c0 12.108-3.439 23.425-9.38 33.039h96.341z" />
              </g>
            </svg>
          </span>
        </li>

        {/* <li style={{zIndex: 9}}>
          <span className={[style.icon, i18n.language === "en" ? style.iconEn : style.iconAr].join(" ")}>
            <svg width="19px" height="20px" viewBox="0 0 19 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <title>android</title>
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="0" transform="translate(-536.000000, -224.000000)" fill="#000000" fillRule="nonzero">
                  <g id="android" transform="translate(536.549378, 224.000000)">
                    <path
                      d="M3.382,1.96802272 C4.97574183,0.691087947 6.95780391,-0.00323355041 9,1.13220873e-05 C11.125,1.13220873e-05 13.078,0.736022724 14.618,1.96802272 L16.071,0.515022724 L17.485,1.92902272 L16.032,3.38202272 C17.3089348,4.97576456 18.0032563,6.95782664 18.0000114,9.00002272 L18.0000114,10.0000227 L0,10.0000227 L0,9.00002272 C0,6.87502272 0.736,4.92202272 1.968,3.38202272 L0.515,1.93002272 L1.929,0.516022724 L3.382,1.96902272 L3.382,1.96802272 Z M0,12.0000227 L18,12.0000227 L18,19.0000227 C18,19.5523075 17.5522847,20.0000227 17,20.0000227 L1,20.0000227 C0.44771525,20.0000227 0,19.5523075 0,19.0000227 L0,12.0000227 Z M6,7.00002272 C6.55228475,7.00002272 7,6.55230747 7,6.00002272 C7,5.44773797 6.55228475,5.00002272 6,5.00002272 C5.44771525,5.00002272 5,5.44773797 5,6.00002272 C5,6.55230747 5.44771525,7.00002272 6,7.00002272 Z M12,7.00002272 C12.5522847,7.00002272 13,6.55230747 13,6.00002272 C13,5.44773797 12.5522847,5.00002272 12,5.00002272 C11.4477153,5.00002272 11,5.44773797 11,6.00002272 C11,6.55230747 11.4477153,7.00002272 12,7.00002272 Z"
                      id="Shape"></path>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </li> */}

        {/* <li style={{zIndex: 9}}>
          <span className={[style.icon, i18n.language === "en" ? style.iconEn : style.iconAr].join(" ")}>
            <svg width="19px" height="23px" viewBox="0 0 19 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <title>Apple</title>
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="0" transform="translate(-485.000000, -224.000000)" fill="#000000" fillRule="nonzero">
                  <path
                    d="M494.173378,230.372 C493.297378,230.372 491.941378,229.376 490.513378,229.412 C488.629378,229.436 486.901378,230.504 485.929378,232.196 C483.973378,235.592 485.425378,240.608 487.333378,243.368 C488.269378,244.712 489.373378,246.224 490.837378,246.176 C492.241378,246.116 492.769378,245.264 494.473378,245.264 C496.165378,245.264 496.645378,246.176 498.133378,246.14 C499.645378,246.116 500.605378,244.772 501.529378,243.416 C502.597378,241.856 503.041378,240.344 503.065378,240.26 C503.029378,240.248 500.125378,239.132 500.089378,235.772 C500.065378,232.964 502.381378,231.62 502.489378,231.56 C501.169378,229.628 499.141378,229.412 498.433378,229.364 C496.585378,229.22 495.037378,230.372 494.173378,230.372 Z M497.293378,227.54 C498.073378,226.604 498.589378,225.296 498.445378,224 C497.329378,224.048 495.985378,224.744 495.181378,225.68 C494.461378,226.508 493.837378,227.84 494.005378,229.112 C495.241378,229.208 496.513378,228.476 497.293378,227.54 L497.293378,227.54 Z"
                    id="Apple"></path>
                </g>
              </g>
            </svg>
          </span>
        </li> */}

        <li>
          <UserLogin />
        </li>
      </ul>

      <div
        className={style.dropdownOverlay}
        onClick={onCloseDropdown}
        style={{ display: dropOpen ? "block" : "none" }}
      ></div>

      <SearchModal
        showModal={showModal}
        onHideModal={onHideModal}
      />

    </>
  );
};

export default Icons;
