import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import get from "lodash.get";

const RenderMachine = ({ machineInstance, states }) => {
  const { currentState } = useSelector((state) => get(state, machineInstance));

  return (
    <>
      {states.map((Entry) =>
        Entry.IsSuspended ? (
          <Suspense key={Entry.name} fallback={null}>
            <React.Fragment>{Entry.name === currentState && <Entry.Component />}</React.Fragment>
          </Suspense>
        ) : (
          <React.Fragment key={Entry.name}>{Entry.name === currentState && <Entry.Component />}</React.Fragment>
        )
      )}
    </>
  );
};

export default RenderMachine;
