import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap'
import style from './DecisionsGroup.module.scss'

const Tabs = props => {

    const { t, i18n } = useTranslation('common')
    const { categoriesList, activeTab } = props

    return (
        <div className={style.Tabs}>
            <Row>
                <Col>
                    <p className={style.tabTitle}>{t('decisions group')}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ul className={style.tabList}>
                        <li
                            className={activeTab?.Decision_category_Id ? null : style.activeTab}
                        >
                            <span 
                                className={style.tab}
                                onClick={() => props.getDecisionsList(null)}
                            >
                                {t('all')}
                            </span>
                        </li>

                        {categoriesList?.map(cat => (
                            <li 
                                key={cat.Decision_category_Id}
                                className={activeTab?.Decision_category_Id == cat.Decision_category_Id ? style.activeTab : null}
                            >
                                <span 
                                    className={style.tab}
                                    onClick={() => props.getDecisionsList(cat)}
                                >
                                    {i18n.language === 'en' ? cat.Decision_category_Name_EN : cat.Decision_category_Name_AR}
                                </span>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </div>
    )
}

export default Tabs
