import React from "react";

function Doc() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 350 350">
      <g fill="none" fillRule="nonzero" stroke="none" strokeDasharray="none" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1" opacity="1">
        <path
          fill="#B83535"
          d="M11.58 54.882v31.965A3.153 3.153 0 0014.733 90h60.534a3.153 3.153 0 003.153-3.153V54.882c-22.347-7.001-44.628-7.044-66.84 0z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#E9E9E0" d="M78.42 54.882V18.345C69.386 13.658 63.133 7.61 60.075 0H14.733a3.153 3.153 0 00-3.153 3.153v51.729h66.84z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#D9D7CA" d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path
          fill="#F9F9F9"
          d="M47.085 82.28h-4.082a3.855 3.855 0 01-3.85-3.851v-11.9a3.855 3.855 0 013.85-3.851h4.082a3.856 3.856 0 013.851 3.851v11.9a3.855 3.855 0 01-3.851 3.851zm-4.082-16.601a.851.851 0 00-.85.851v11.9c0 .469.381.851.85.851h4.082a.852.852 0 00.851-.851v-11.9a.852.852 0 00-.851-.851h-4.082zM31.02 82.28h-5.653a1.5 1.5 0 01-1.5-1.5V64.179a1.5 1.5 0 011.5-1.5h5.653a4.635 4.635 0 014.63 4.63V77.65a4.635 4.635 0 01-4.63 4.63zm-4.153-3h4.153c.899 0 1.63-.731 1.63-1.63V67.309c0-.898-.731-1.63-1.63-1.63h-4.153V79.28zM64.633 82.28h-5.947a4.34 4.34 0 01-4.336-4.336v-10.93a4.34 4.34 0 014.336-4.336h5.947a1.5 1.5 0 010 3h-5.947c-.736 0-1.336.6-1.336 1.336v10.93c0 .736.6 1.336 1.336 1.336h5.947a1.5 1.5 0 010 3z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
      </g>
    </svg>
  );
}

export default Doc;
