import React, { useState, useEffect } from 'react'
import CompletedRequestsGrid from './CompletedRequestsGrid'
import PopUp from '../../../UIComponents/PopUp/PopUp'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import style from './../form.module.scss'
import moment from 'moment'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons'
import Grid from "components/UIComponents/Grid";
import { Column } from "devextreme-react/data-grid";

const CompletedRequests = props => {

    const { t, i18n } = useTranslation('common')
    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()
    const [requestData, setRequestData] = useState()
    const [requestResults, setRequestResults] = useState()
    const [clickFix, setClickFix] = useState(false)
    const { user, clientProfiles } = useSelector((state) => state.user);

    const handleClose = () => setShow(false)

    const getRequestId = data => {
        setModalData(data)
        setClickFix(!clickFix)
    }

    useEffect(() => {
        if (modalData?.Request_Id) {
            const res = axios.post(
                `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/REQUESTS/GET_CLIENT_COMPELETED_REQUEST_INFO`,
                {
                    'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                    'Request_Id': modalData?.Request_Id
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`
                    }
                }
            ).then(res => {
                setRequestData(res.data.Data.Request_Data)
                setRequestResults(res.data.Data.Request_Results)
                setShow(true)
            })
        }
    }, [clickFix])

    const handleFileDownload = code => {
        const res = axios.post(
            `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/REQUESTS/DOWNLOAD_RESULT_FILE`,
            {
                'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                'Request_Id': modalData?.Request_Id,
                'File_Code': code
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`
                }
            }
        ).then(res => {
            let a = document.createElement("a"); // Create <a>
            a.href = "data:image/png;base64," + res.data.Data.File_Bytes; // Base64 
            a.download = res.data.Data.File_Name; // File name 
            a.click(); // Download file
        })
    }

    let invoice_status = 'Invoice_Status_Name_AR'

    if (i18n.language === 'en') {
        invoice_status = 'Invoice_Status_Name_EN'
    }

    return (
        <>
            <CompletedRequestsGrid
                getRequestId={getRequestId}
                dataIsReady={props.dataIsReady}
            />

            {modalData && requestData ?
                <PopUp
                    show={show}
                    handleClose={handleClose}
                    title={t('completed_requests')}
                >
                    <Container>
                        <Formik
                            initialValues={{
                                request_no: requestData?.Request_No,
                                request_date: moment(requestData?.Request_Date).lang("en").format('YYYY-MM-DD'),
                                service_issuance: requestData?.Department_Service_Version_Name,
                                department_name: i18n.language === 'en' ? requestData?.Department_Name_EN : requestData?.Department_Name_AR,
                                service_name: i18n.language === 'en' ? requestData?.Service_Name_EN : requestData?.Service_Name_AR,
                                request_year: requestData?.Request_Year,
                                service_code: requestData?.Service_No,
                                service_category: i18n.language === 'en' ? requestData?.Service_Category_Name_EN : requestData?.Service_Category_Name_AR,
                                dealing_type: i18n.language === 'en' ? requestData?.Dealing_Type_Name_EN : requestData?.Dealing_Type_Name_AR,
                                request_status: i18n.language === 'en' ? requestData?.Request_Status_Name_EN : requestData?.Request_Status_Name_AR
                            }}
                        >
                            <Form className={style.dashboardData}>
                                <Row
                                    style={{ 'textAlign': i18n.language === 'ar' ? 'start' : null }}
                                >
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_no')}</label>
                                        <Field className={style.dashboardField} name="request_no" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_Year')}</label>
                                        <Field className={style.dashboardField} name="request_year" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_date')}</label>
                                        <Field className={style.dashboardField} name="request_date" type='text' readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('department_name')}</label>
                                        <Field className={style.dashboardField} name="department_name" type="text" readOnly />
                                    </Col>

                                    <Col sm={12}>
                                        <Field name="service_name" type="text" as="textarea" className={['text-center title mainName', style.dashboardField].join(' ')} readOnly />
                                    </Col>

                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('service_code')}</label>
                                        <Field className={style.dashboardField} name="service_code" type="text" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('service_issuance')}</label>
                                        <Field className={style.dashboardField} name="service_issuance" type="text" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('service_category')}</label>
                                        <Field className={style.dashboardField} name="service_category" type="text" readOnly />
                                    </Col>

                                    {requestResults?.length > 0 ?
                                        <Col md={4} sm={6} className='mb-5'>
                                            <p className={style.name}>{t('attachments')}</p>
                                            {requestResults.map(file => (
                                                <div className={style.file} key={file.Result_File_Code}>
                                                    <FontAwesomeIcon className={style.fileSvg} icon={faFile} />
                                                    <div>
                                                        <p>{file.Result_File_Info.File_Name}</p>
                                                        <p>{file.Result_File_Info.File_Size}</p>
                                                    </div>
                                                    <span
                                                        className={[
                                                            style.download,
                                                            i18n.language === 'en' ? style.downloadEN : style.downloadAR
                                                        ].join(' ')}
                                                        onClick={() => handleFileDownload(file.Result_File_Code)}
                                                    >
                                                        <FontAwesomeIcon size="lg" icon={faDownload} />
                                                    </span>
                                                </div>
                                            ))}
                                        </Col>
                                        : null}

                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('dealing_type')}</label>
                                        <Field className={style.dashboardField} name="dealing_type" type="text" as="textarea" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('request_status')}</label>
                                        <Field className={style.dashboardField} name="request_status" type="text" as="textarea" readOnly />
                                    </Col>

                                    {requestData?.Invoices?.length > 0 ?
                                        <Grid
                                            sourceData={requestData?.Invoices}
                                            addEnabled={false}
                                            editEnabled={false}
                                            deleteEnabled={false}
                                            viewEnabled={false}
                                            onView={({ data }) => { }}
                                        >
                                            <Column caption={t("invoice_no")} alignment="center" dataField="Invoice_No" dataType="number" width={150} />
                                            <Column caption={t("invoice_date")} alignment="center" dataField="Invoice_Date" dataType="date" width={150} format="yyyy-MM-dd" />
                                            <Column caption={t("invoice_amount")} alignment="center" dataField="Invoice_Value" dataType="string" width={150} />
                                            <Column caption={t("payment_status")} alignment="center" dataField={invoice_status} dataType="string" width={150} />
                                            <Column caption={t("electronic_reference_no")} alignment="center" dataField="Online_Reference_No" dataType="string" width={150} />
                                            <Column caption={t("payment_order_no")} alignment="center" dataField="Payment_No" dataType="string" width={150} />
                                        </Grid>
                                        : null}
                                </Row>
                            </Form>
                        </Formik>
                    </Container>
                </PopUp>
                : null}
        </>

    )
}

export default React.memo(CompletedRequests)
