import React, { useState } from 'react'
import Header from './../../UIComponents/Header/Header'
import Footer from './../../UIComponents/Footer/Footer'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PopUp from '../../UIComponents/PopUp/PopUp'
import { Formik, Form, Field } from "formik"
import style from './Invoices.module.scss'
import InvoicesGrid from './InvoicesGrid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFile } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import { Column } from 'devextreme-react/data-grid'
import Grid from '../../UIComponents/Grid/index'

const Invoices = () => {

    const { t, i18n } = useTranslation('common')
    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()
    const [recordId, setRecordId] = useState()
    const { user, clientProfiles } = useSelector((state) => state.user)

    let invoiceDetailTypeName = 'Invoice_Detail_Type_Name_EN';
    if (i18n.language === 'ar') {
        invoiceDetailTypeName = 'Invoice_Detail_Type_Name_AR';
    }

    const handleClose = () => setShow(false)

    const handleShow = id => {
        const res = axios.post(
            `${process.env.REACT_APP_API_URL}/PORTAL/PAYMENTS/INVOICES/GET_ClIENTS_INVOICE_INFO`,
            {
                'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                'Invoice_id': id
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`
                }
            }
        ).then(res => {
            setModalData(res.data.Data)
            setShow(true)
        })
        setRecordId(id)
    }

    const handleFileDownload = code => {
        const res = axios.post(
            `${process.env.REACT_APP_API_URL}/PORTAL/PAYMENTS/INVOICES/DOWNLOAD_ClIENTS_INVOICE_INFO`,
            {
                'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                'Invoice_id': recordId,
                'File_Code': code
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`
                }
            }
        ).then(res => {
            let a = document.createElement("a"); // Create <a>
            a.href = "data:image/png;base64," + res.data.Data.File_Bytes; // Base64 
            a.download = res.data.Data.File_Name; // File name 
            a.click(); // Download file
        })
    }

    return (
        <>
            <Header />

            <Container fluid='lg'>
                <Row>
                    <Col xl={12} className='text-center'>
                        <p className={style.title}>{t('invoices_search')}</p>
                    </Col>
                </Row>

                <Row className='mt-3 mb-5'>
                    <InvoicesGrid handleShow={handleShow} />
                </Row>
            </Container>

            <Footer />

            <PopUp
                show={show}
                handleClose={handleClose}
                title={t('invoices_search')}
            >
                <Container>
                    <Formik
                        initialValues={{
                            request_no: modalData?.Request_No,
                            request_date: moment(modalData?.Request_Date).lang("en").format('YYYY-MM-DD'),
                            department_name: i18n.language === 'en' ? modalData?.Department_Name_EN : modalData?.Department_Name_AR,
                            receipt_voucher_no: modalData?.Payment_No,
                            service_name: i18n.language === 'en' ? modalData?.Service_Name_EN : modalData?.Service_Name_AR,
                            invoice_type: i18n.language === 'en' ? modalData?.Invoice_Type_Name_EN : modalData?.Invoice_Type_Name_AR,
                            invoice_no: modalData?.Invoice_No,
                            invoice_amount: modalData?.Invoice_Value,
                            payment_status: i18n.language === 'en' ? modalData?.Invoice_Status_Name_EN : modalData?.Invoice_Status_Name_AR,
                            payment_date: modalData?.Invoice_Paid_Date ? moment(modalData?.Invoice_Paid_Date).lang("en").format('YYYY-MM-DD') : '',
                            // payment_method: "",
                            client_name: i18n.language === 'en' ? modalData?.Client_Name_EN : modalData?.Client_Name_AR,
                            client_mail: modalData?.Client_Mail,
                            client_no: modalData?.Client_Phone,
                            electronic_reference_no: modalData?.Online_Reference_No,
                        }}
                    >
                        <Form className={style.invoice}>
                            <Row
                                style={{ 'textAlign': i18n.language === 'ar' ? 'start' : null }}
                            >
                                <Col md={3} sm={6}>
                                    <label className={style.name}>{t('request_no')}</label>
                                    <Field className={style.invoiceField} name="request_no" type="text" readOnly />
                                </Col>
                                <Col md={3} sm={6}>
                                    <label className={style.name}>{t('request_date')}</label>
                                    <Field className={style.invoiceField} name="request_date" type="text" readOnly />
                                </Col>
                                <Col md={3} sm={6}>
                                    <label className={style.name}>{t('department_name')}</label>
                                    <Field className={style.invoiceField} name="department_name" type="text" readOnly />
                                </Col>
                                <Col md={3} sm={6}>
                                    <label className={style.name}>{t('receipt_voucher_no')}</label>
                                    <Field className={style.invoiceField} name="receipt_voucher_no" type="text" readOnly />
                                </Col>

                                <Col sm={12}>
                                    <Field name="service_name" type="text" as="textarea" className={['text-center title mainName', style.invoiceField].join(' ')} readOnly />
                                </Col>

                                <Col md={4} sm={6}>
                                    <label className={style.name}>{t('client_name')}</label>
                                    <Field className={style.invoiceField} name="client_name" type="text" readOnly />
                                </Col>
                                <Col md={4} sm={6}>
                                    <label className={style.name}>{t('client_mail')}</label>
                                    <Field className={style.invoiceField} name="client_mail" type="text" readOnly />
                                </Col>
                                <Col md={4} sm={6}>
                                    <label className={style.name}>{t('client_no')}</label>
                                    <Field className={style.invoiceField} name="client_no" type="text" readOnly />
                                </Col>

                                <Col md={4} sm={6}>
                                    <label className={style.name}>{t('invoice_type')}</label>
                                    <Field className={style.invoiceField} name="invoice_type" type="text" readOnly />
                                </Col>
                                <Col md={4} sm={6}>
                                    <label className={style.name}>{t('invoice_no')}</label>
                                    <Field className={style.invoiceField} name="invoice_no" type="text" readOnly />
                                </Col>
                                <Col md={4} sm={6}>
                                    <label className={style.name}>{t('invoice_amount')}</label>
                                    <Field className={style.invoiceField} name="invoice_amount" type="text" readOnly />
                                </Col>
                                <Col md={4} sm={6}>
                                    <label className={style.name}>{t('payment_status')}</label>
                                    <Field className={style.invoiceField} name="payment_status" type="text" readOnly />
                                </Col>
                                <Col md={4} sm={6}>
                                    <label className={style.name}>{t('payment_date')}</label>
                                    <Field className={style.invoiceField} name="payment_date" type="text" readOnly />
                                </Col>
                                {/* <Col md={4} sm={6}>
                                    <label className={style.name}>{t('payment_method')}</label> 
                                    <Field name="payment_method" type="text" readOnly />
                                </Col> */}

                                <Col md={4} sm={6}>
                                    <label className={style.name}>{t('electronic_reference_no')}</label>
                                    <Field className={style.invoiceField} name="electronic_reference_no" type="text" readOnly />
                                </Col>

                                {modalData?.Payment_File_Code || modalData?.Invoice_File_Code ?
                                    <Col md={4} sm={6} className='mb-2'>
                                        <p className={style.name}>{t('receipt_voucher')}</p>

                                        {modalData?.Payment_File_Code ?
                                            <div className={style.file}>
                                                <FontAwesomeIcon className={style.fileSvg} icon={faFile} />
                                                <div>
                                                    <p>{modalData?.Payment_File_Code_Info?.File_Name}</p>
                                                    <p>{modalData?.Payment_File_Code_Info?.File_Size}</p>
                                                </div>
                                                <span
                                                    className={[
                                                        style.download,
                                                        i18n.language === 'en' ? style.downloadEN : style.downloadAR
                                                    ].join(' ')}
                                                    onClick={() => handleFileDownload(modalData?.Payment_File_Code)}
                                                >
                                                    <FontAwesomeIcon size="lg" icon={faDownload} />
                                                </span>
                                            </div>
                                            : null}

                                        {modalData?.Invoice_File_Code ?
                                            <div className={style.file}>
                                                <FontAwesomeIcon className={style.fileSvg} icon={faFile} />
                                                <div>
                                                    <p>{modalData?.Invoice_File_Code_Info?.File_Name}</p>
                                                    <p>{modalData?.Invoice_File_Code_Info?.File_Size}</p>
                                                </div>
                                                <span
                                                    className={[
                                                        style.download,
                                                        i18n.language === 'en' ? style.downloadEN : style.downloadAR
                                                    ].join(' ')}
                                                    onClick={() => handleFileDownload(modalData?.Invoice_File_Code)}
                                                >
                                                    <FontAwesomeIcon size="lg" icon={faDownload} />
                                                </span>
                                            </div>
                                            : null}
                                    </Col>
                                    : null}

                            </Row>
                        </Form>
                    </Formik>

                    <br />

                    <Grid
                        sourceData={modalData?.Invoice_Details}
                        addEnabled={false}
                        editEnabled={false}
                        deleteEnabled={false}
                        viewEnabled={false}
                        onView={({ data }) => { }}
                        onDelete={({ data }) => { }}
                    >
                        <Column caption={t('invoice_detail_type_name')} alignment="center" dataField={invoiceDetailTypeName} dataType="string" width='auto' />
                        <Column caption={t('invoice_detail_total_value')} alignment="center" dataField="Invoice_Detail_Total_Value" dataType="string" width={120} />
                        <Column caption={t('invoice_detail_tax_value')} alignment="center" dataField="Invoice_Detail_Tax_Value" dataType="string" width={120} />
                        <Column caption={t('invoice_detail_grand_total_value')} alignment="center" dataField="Invoice_Detail_Grand_Total_Value" dataType="string" width='auto' />
                    </Grid>

                </Container>
            </PopUp>
        </>
    )
}

export default Invoices
