import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import style from './Footer.module.scss'
import Municipalities from './Municipalities'
import Links from './Links'
import ContactUs from './ContactUs'

const Footer = () => {

    const { t, i18n } = useTranslation('common')
    const [marginBottom, setMarginBottom] = useState(false)
    const [currentYear, setCurrentYear] = useState()
    const history = useHistory()
    let { municipalId } = useParams()

    useEffect(() => {
        if (history.location.pathname.split('/')[1].split(' ')[0] === 'service_details' ||
            history.location.pathname.split('/')[1].split(' ')[0] === 'our_services') {
            setMarginBottom(true)
        }
        setCurrentYear(new Date().getFullYear())
    }, [])

    return (
        <footer className={[
            style.footer,
            marginBottom ? style.addMargin : null
        ].join(' ')}>
            <Container fluid='lg'>
                <Row>
                    <Col lg={6} md={8} xs={12} className='pb-md-0 pb-5'>
                        <Municipalities />
                    </Col>
                    {/* <Col lg={3} md={4} xs={12}>
                        <Links />
                    </Col>
                    <Col lg={3} md={12} xs={12} className='pt-md-0 pt-5'>
                        <ContactUs />
                    </Col> */}
                    <Col></Col>
                    <Col lg={5} md={4} xs={12}>
                        <ContactUs />
                    </Col>
                </Row>
            </Container>

            <Container fluid className={style.middleSection}>
                <Row className='m-0'>
                    <Col xs={4}
                        className={[
                            style.content,
                            i18n.language === 'en' ? style.borderLeft : null,
                            // 'mb-md-0 mb-1 mt-md-0 mt-4'
                        ].join(' ')}
                    >
                        <NavLink
                            className={[style.middleSectionLink, style.commonQuestions].join(' ')}
                            to='/common_questions'
                        >
                            <span className={style.icon}>
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    width="365.442px" height="365.442px" viewBox="0 0 365.442 365.442" xmlSpace="preserve"
                                >
                                    <g>
                                        <g>
                                            <path d="M212.994,274.074h-68.522c-3.042,0-5.708,1.149-7.992,3.429c-2.286,2.286-3.427,4.948-3.427,7.994v68.525
                                                c0,3.046,1.145,5.712,3.427,7.994c2.284,2.279,4.947,3.426,7.992,3.426h68.522c3.042,0,5.715-1.144,7.99-3.426
                                                c2.29-2.282,3.433-4.948,3.433-7.994v-68.525c0-3.046-1.14-5.708-3.433-7.994C218.709,275.217,216.036,274.074,212.994,274.074z"
                                            />
                                            <path d="M302.935,68.951c-7.806-14.378-17.891-26.506-30.266-36.406c-12.367-9.896-26.271-17.799-41.685-23.697
                                                C215.567,2.952,200.246,0,185.016,0C127.157,0,83,25.315,52.544,75.946c-1.521,2.473-2.046,5.137-1.571,7.993
                                                c0.478,2.852,1.953,5.232,4.427,7.135l46.824,35.691c2.474,1.52,4.854,2.281,7.139,2.281c3.427,0,6.375-1.525,8.852-4.57
                                                c13.702-17.128,23.887-28.072,30.548-32.833c8.186-5.518,18.461-8.276,30.833-8.276c11.61,0,21.838,3.046,30.692,9.132
                                                c8.85,6.092,13.271,13.135,13.271,21.129c0,8.942-2.375,16.178-7.135,21.698c-4.757,5.518-12.754,10.845-23.986,15.986
                                                c-14.842,6.661-28.457,16.988-40.823,30.978c-12.375,13.991-18.558,28.885-18.558,44.682v12.847c0,3.62,0.994,7.187,2.996,10.708
                                                c2,3.524,4.425,5.283,7.282,5.283h68.521c3.046,0,5.708-1.472,7.994-4.432c2.279-2.942,3.426-6.036,3.426-9.267
                                                c0-4.757,2.617-11.14,7.847-19.13c5.235-7.994,11.752-14.186,19.562-18.565c7.419-4.186,13.219-7.56,17.411-10.133
                                                c4.196-2.566,9.664-6.715,16.423-12.421c6.756-5.712,11.991-11.375,15.698-16.988c3.713-5.614,7.046-12.896,9.996-21.844
                                                c2.956-8.945,4.428-18.558,4.428-28.835C314.639,98.397,310.734,83.314,302.935,68.951z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <p>{t('common questions')}</p>
                        </NavLink>
                    </Col>

                    <Col xs={4}
                        className={[
                            style.content,
                            i18n.language === 'ar' ? style.borderLeft : null,
                            // 'mt-md-0 mt-1 mb-md-0 mb-5'
                        ].join(' ')}
                    >
                        <NavLink
                            className={style.middleSectionLink}
                            to={municipalId ? `/contact_us/${municipalId}` : `/contact_us`}
                        >
                            <span className={style.contactUsIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="53.1" height="53.1" viewBox="0 0 53.1 53.1">
                                    <g id="phone-fill" transform="translate(53.1)">
                                        <path id="Path" d="M0,0H-53.1V53.1H0Z" fill="#1e1e1e" />
                                        <path id="Path-2" data-name="Path" d="M-22.419,16.715v4.4a1.246,1.246,0,0,0,1.158,1.243c.544.037.989.057,1.333.057A19.928,19.928,0,0,0,0,2.491q0-.516-.057-1.333A1.246,1.246,0,0,0-1.3,0H-5.7a.623.623,0,0,0-.62.56c-.029.286-.055.514-.08.688a17.315,17.315,0,0,1-1.5,4.983.568.568,0,0,0,.183.706l2.688,1.921a16.25,16.25,0,0,1-8.524,8.525L-15.48,14.7a.575.575,0,0,0-.714-.186,17.313,17.313,0,0,1-4.982,1.5c-.173.025-.4.052-.685.08a.623.623,0,0,0-.559.62Z" transform="translate(-15.34 15.34)" fill="$alpha" />
                                    </g>
                                </svg>
                            </span>
                            <p>{t('contact_us')}</p>
                        </NavLink>
                    </Col>

                    <Col xs={4}
                        className={[
                            style.content,
                            i18n.language === 'en' ? style.borderLeft : null,
                            // 'mb-md-0 mb-1 mt-md-0 mt-4'
                        ].join(' ')}
                    >
                        <NavLink
                            className={style.middleSectionLink}
                            to='/suggestions_and_complains'
                        >
                            <span className={style.complainsIcon}>
                                <svg id="cup-fill" xmlns="http://www.w3.org/2000/svg" width="53.1" height="53.1" viewBox="0 0 53.1 53.1">
                                    <path id="Path" d="M0,0H53.1V53.1H0Z" fill="#1e1e1e" />
                                    <path id="Shape" d="M0,22.125V19.667H22.125v2.458Zm7.375-4.917a4.916,4.916,0,0,1-4.917-4.917V1.229A1.229,1.229,0,0,1,3.687,0H22.125a2.458,2.458,0,0,1,2.459,2.458V6.146A2.458,2.458,0,0,1,22.125,8.6H19.667v3.687a4.916,4.916,0,0,1-4.917,4.917Z" transform="translate(14.16 15.34)" fill="$alpha" />
                                </svg>
                            </span>
                            <p>{t('complaints_n_suggestions')}</p>
                        </NavLink>
                    </Col>
                </Row>
            </Container>

            <Container fluid='lg'>
                <Row>
                    <Col className='text-center'>
                        <p>{t('copyWritesPart1')} {currentYear}, {t('copyWritesPart2')}</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
