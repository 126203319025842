import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import style from "./Request.module.scss";
import styleNew from "./applyService.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  saveDraftInputsData,
  servieRequestSubmit,
  saveDraftAttachmentData,
  deleteDraftAttachment,
  downloadDraftAttachment,
} from "api/serviceAPI";
import {
  setError,
  resetRequest,
  fetchServiceRequestDraft,
} from "./serviceRequestSlice";
import moment from "moment";
import {
  Attachment,
  Csv,
  Doc,
  Jpg,
  Mp3,
  Mp4,
  Pdf,
  Png,
  Text,
  Zip,
} from "components/UIComponents/FileIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faDownload,
  faUpload,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import DynamicField from "components/UIComponents/Dynamics/DynamicField";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import ProgressBar from "../../components/UIComponents/ProgressBar/ProgressBar";
import * as icons from "./icons";
import Stepper from "@material-ui/core/Stepper";
import {
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import NewClientRequestInfo from "./NewClientRequestInfo";
import SubmitModal from "./SubmitModal";
import PopUp from "components/UIComponents/PopUp/PopUp";
import { Field, Formik } from "formik";
import { useLocation } from "react-router-dom";
import store from "app/store";
import html2canvas from "html2canvas";
import Alert from "react-bootstrap/Alert";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-60% + 16px)",
    right: "calc(40% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#58743a",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#58743a",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderWidth: 1,
    borderStyle: "dashed",
  },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
    backgroundColor: "#58743a",
    color: "white",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  step_label_root: {
    "@media (max-width:600px)": {
      fontSize: "8px",
    },
  },
}));

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#E5E9E1",
    "& path": {
      fill: "#4E6B31",
    },
  },
  circle: {
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "currentColor",
    // border: "solid 2px white",
    // outline: "solid 3px currentColor",
    zIndex: 1,
  },
  completed: {
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#4E6B31",
    // border: "solid 2px white",
    // outline: "solid 3px #4E6B31",
    zIndex: 1,
    "& path": {
      fill: "white",
    },
  },

  completedContainer: {
    position: "relative",
  },

  right: {
    position: "absolute",
    top: 0,
    right: 0,
    transform: `translate(10%, -50%)`,
  },

  step_label_root: {
    fontWeight: "bold !important",
    color: "red",
  },
});

// QontoStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    */
//   active: PropTypes.bool,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    */
//   completed: PropTypes.bool,

//   icon: PropTypes.node,
// };

const TASK_SUPPORT_TYPE_ID = {
  4: "1",
  5: "2",
  15: "3",
};

const Request = () => {
  const { t, i18n } = useTranslation("common");
  const {
    serviceData,
    requestDate,
    inputsDataValue,
    attachmentsDataValue,
    requestId,
    inputsData,
    attachmentsData,
    loading,
  } = useSelector((state) => state.service.serviceRequest);
  const { clientProfiles } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  // const [highlightTerms, setHighlightTerms] = useState(false);
  const [requestIdState, setRequestIdState] = useState(requestId);
  const [isModalShow, setIsModalShow] = useState(false);
  const termsRef = useRef();
  const [activeStep, setActiveStep] = React.useState(0);
  // const [inputsDataValuesList, setInputsDataValuesList] = React.useState([]);
  const [show, setShow] = useState(false);
  const [supportMessage, setSupportMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [stepState, setStepState] = useState({ 1: false, 2: false });
  const [alertMsg, setAlertMsg] = useState(null);

  const [img, setImg] = useState();

  const { requestData, currentTaskData, error } = useSelector(
    (state) => state.service.updateRequest
  );

  const { user } = useSelector((state) => state.user);

  const location = useLocation();

  useEffect(() => {
    if (show) {
      html2canvas(document.querySelector("#root"), {
        useCORS: true,
        allowTaint: true,
      }).then(function (canvas) {
        setImg(canvas.toDataURL("image/jpeg"));
      });
    }
  }, [show]);

  const handlePopup = () => setShow(true);
  const handleClose = () => setShow(false);

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed, icon } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? (
          <div className={classes.completedContainer}>
            <div className={classes.completed}>{icons.stepperIcons[icon]}</div>
            <span className={classes.right}>
              {stepState[icon] ? icons.right : icons.wrong}
            </span>
          </div>
        ) : (
          <div className={classes.circle}>{icons.stepperIcons[icon]}</div>
        )}
      </div>
    );
  }

  const handleOnSubmit = () => {
    let parsedError;
    if (error) {
      parsedError = JSON.parse(error);
    }
    const data = new FormData();

    data.append(
      "PARAMETERS",
      JSON.stringify({
        department_Id: serviceData?.Department_Id,
        department_Service_Version_Id:
          serviceData?.Department_Service_Version_Id,
        service_Id: serviceData?.Service_Id,
        support_Request_Type_Id: currentTaskData?.Task_Type_Id
          ? TASK_SUPPORT_TYPE_ID?.[currentTaskData.Task_Type_Id]
          : "",
        client_Profile_Id: clientProfiles?.Client_Profile_Id,
        client_Notes: supportMessage,
        page_Inputs_Data: JSON.stringify(inputsData),
        page_URL: location.pathname,
        error_Request_Posted_Data: JSON.stringify({
          method: parsedError?.method,
          url: parsedError?.url,
          headers: {
            Authorization: parsedError?.headers["Authorization"],
          },
        }),
        error_Request_Response_Data: JSON.stringify(parsedError?.data),
        error_Request_URL: parsedError?.url,
        error_Request_No: requestData?.Request_No,
      })
    );

    if (img) {
      setIsUploading(true);
      data.append("Page_Image", dataURItoBlob(img));
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/SUPPORTREQUEST/ADD_SUPPORT_REQUEST`,
          data,
          {
            headers: {
              Authorization: `Bearer ${user?.access_token}`,
            },
          }
        )
        .catch((err) => console.log(err))
        .finally(() => setIsUploading(false));
      setShow(false);
      setSupportMessage("");
    }
  };

  const handleTerms = () => {
    setAcceptedTerms((prev) => !prev);
  };

  const dispatchServiceRequestData = () => {
    const promise = dispatch(
      fetchServiceRequestDraft({
        Request_Draft_Id: requestId,
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      })
    );

    return () => {
      promise.abort();
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsSubmiting(true);
    servieRequestSubmit(
      {
        Department_Id: serviceData.Department_Id,
        Dealing_Type_Id: serviceData.Dealing_Type_Id,
        Service_Id: serviceData.Service_Id,
        Client_Profile_Id: clientProfiles.Client_Profile_Id,
        Request_Draft_Id: requestId,
      },
      source
    )
      .then((response) => {
        if (response.Status) {
          // history.push(`/dashboard`);
          setIsModalShow(true);
          setRequestIdState(requestId);
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => {
        setIsSubmiting(false);
      });

    return () => {
      source.cancel();
    };
  };

  const handleModalClick = () => {
    setIsModalShow(false);
  };
  const handleDefaultValue = (item) => {
    if (inputsDataValue?.length) {
      const dataValue = inputsDataValue.filter(
        (dataValue) =>
          dataValue?.Department_Service_Detail_Id ===
          item.Department_Service_Detail_Id
      );

      if (dataValue) return dataValue[0]?.Service_Detail_Value;
      else {
        return null;
      }
    }
    return null;
  };

  const handleSupportChange = (e) => {
    setSupportMessage(e.target.value);
  };

  const handleInputsDataValues = (item) => {
    if (inputsDataValue?.length) {
      const dataValue = inputsDataValue.filter(
        (dataValue) =>
          dataValue?.Department_Service_Detail_Id ===
          item.Department_Service_Detail_Id
      );

      if (dataValue) return dataValue[0];
      else {
        return null;
      }
    }
  };

  const handleFileValue = (item) => {
    if (attachmentsDataValue?.length) {
      const fileValue = attachmentsDataValue.filter(
        (fileValue) =>
          fileValue?.Department_Service_Detail_Id ===
          item.Department_Service_Detail_Id
      );
      if (fileValue) return fileValue[0]?.Files_Data;
      else {
        return null;
      }
    }
    return null;
  };
  const handleSave = () => {
    history.push(`/dashboard`);
  };

  const onInputSave = async (e, item) => {
    await saveDraftInputsData({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Draft_Id: requestId,
      Request_Draft_Data: [
        {
          Service_Detail_Id: item.Service_Detail_Id,
          Service_Detail_Value: e.target.value,
        },
      ],
    }).catch((err) => {
      dispatch(setError(err));
    });
  };

  const classes = useQontoStepIconStyles();

  function getSteps() {
    return [
      <StepLabel
        StepIconComponent={QontoStepIcon}
        classes={{
          active: classes.step_label_root,
          completed: classes.step_label_root,
        }}
      >
        {t("serviceInput")}
      </StepLabel>,

      <StepLabel
        StepIconComponent={QontoStepIcon}
        classes={{
          active: classes.step_label_root,
          completed: classes.step_label_root,
        }}
      >
        {t("ServiceAttachments")}
      </StepLabel>,

      // <StepLabel
      //   StepIconComponent={QontoStepIcon}
      //     classes={{
      //   active: classes.step_label_root,
      //   completed: classes.step_label_root,
      // }}
      // >
      //   {t("contractor_data")}
      // </StepLabel>,

      // <StepLabel
      //   StepIconComponent={QontoStepIcon}
      //     classes={{
      //   active: classes.step_label_root,
      //   completed: classes.step_label_root,
      // }}
      // >
      //   {t("land_data")}
      // </StepLabel>,

      // <StepLabel
      //   StepIconComponent={QontoStepIcon}
      //     classes={{
      //   active: classes.step_label_root,
      //   completed: classes.step_label_root,
      // }}
      // >
      //   {t("financial_data")}
      // </StepLabel>,

      // <StepLabel
      //   StepIconComponent={QontoStepIcon}
      //     classes={{
      //   active: classes.step_label_root,
      //   completed: classes.step_label_root,
      // }}
      // >
      //   {t("charts")}
      // </StepLabel>,

      // <StepLabel
      //   StepIconComponent={QontoStepIcon}
      //     classes={{
      //   active: classes.step_label_root,
      //   completed: classes.step_label_root,
      // }}
      // >
      //   {t("receipts")}
      // </StepLabel>,

      <StepLabel
        StepIconComponent={QontoStepIcon}
        classes={{
          active: classes.step_label_root,
          completed: classes.step_label_root,
        }}
      >
        {t("data_review")}
      </StepLabel>,
    ];
  }

  const steps = getSteps();
  const handleNext = async () => {
    setIsUploading(true);
    dispatchServiceRequestData();

    if (alertMsg) {
      setAlertMsg(null)
    }

    let serviceFormId = null;
    if (activeStep === 0) {
      serviceFormId = 1;
    } else if (activeStep === 1) {
      serviceFormId = 2;
    }
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/REQUESTS/VALIDATE_DRAFT_INPUTS_DATA`,
        {
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Draft_Id: requestId,
          Service_Form_Id: serviceFormId,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data.Data);
        setIsUploading(false);

        setStepState((prev) => ({
          ...prev,
          [activeStep + 1]: res.data.Data === "Inputs Valid" ? true : false,
        }));

        if (res.data.Data === "Inputs Valid") {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          res?.data.Data.map(e => console.log(e))
          // console.log(res?.data.Data)
          setAlertMsg(<div>{res?.data?.Data?.map(e => <p>{i18n.language == 'ar' ? e?.Errors_AR[0] : e?.Errors_EN[0]}</p>)}</div>)
          // res?.data?.Data?.map(e => setAlertMsg(<p>{i18n.language == 'ar' ? e?.Errors_AR[0] : e?.Errors_EN[0]}</p>))
        }

      })
      .catch((err) => setIsUploading(false));
  };
  const handleBack = () => {
    if (alertMsg) {
      setAlertMsg(null)
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    dispatchServiceRequestData();
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  // const reSort = (inputsData) => {

  //   let notValidated = [];
  //   let validated = [];

  //   inputsData.map(ele => ele <10 ?  notValidated.push(ele) : )

  //   return (inputsData = [...validated, ...notValidated]);
  // };

  const handleInputsdata = (inputsData) => {
    if (inputsData?.length > 0) {
      let data = [...inputsData].sort(
        (a, b) =>
          Number(a.Department_Service_Detail_No) -
          Number(b.Department_Service_Detail_No)
      );

      // let notValidated = [];
      // let validated = [];

      // data.map((ele) =>
      //   ele.Department_Service_Detail_Type_Id < 10
      //     ? notValidated.push(ele)
      //     : validated.push(ele)
      // );

      // data = [...validated, ...notValidated];

      return data.map((item) => (
        <Col md={6} className="mt-5">
          <DynamicField
            key={item.Department_Service_Detail_Id}
            defaultValue={() => handleDefaultValue(item)}
            onSave={onInputSave}
            item={item}
            inputsData={() => handleInputsDataValues(item)}
            autoSave={item.Department_Service_Detail_Type_Id === 10}
          // inputsData={inputsDataValue}
          />
        </Col>
      ));
    }
  };


  const scroll = React.useCallback((node) => {
    if (node) {
      window.scrollTo({
        top: node.getBoundingClientRect().top,
        behavior: 'smooth'
      })
    }
  }, [])

  return (
    <>
      {/* // New Header */}
      <Row className="mb-5">
        <Col smd={12}>
          <div style={{ borderRadius: "20px" }} className="gCard" ref={scroll}>
            <Row>
              <Col
                md={2}
                className="d-flex justify-content-center  align-items-center"
              >
                {icons.icon}
              </Col>
              {icons.line}
              <Col md={4}>
                <div className="w-75 mx-4">
                  {/* Title */}
                  <div
                    style={{ color: "#4E6B31" }}
                    className="font-weight-bold me-3"
                  >
                    {t("Service Information")}
                  </div>
                  {/* 1 */}
                  <div className="d-flex justify-content-between">
                    <div className="w-50">
                      <span className="font-weight-bold">
                        {t("drafts_number")}
                      </span>
                    </div>
                    <div className="w-50">
                      <span className={styleNew.greyColor}>{requestId}</span>
                    </div>
                  </div>
                  {/* 2 */}
                  <div className="d-flex justify-content-between">
                    <div className="w-50">
                      <span className="font-weight-bold">
                        {t("Draft Date")}
                      </span>
                    </div>
                    <div className="w-50">
                      <span className={styleNew.greyColor}>
                        {requestDate &&
                          moment(requestDate).lang("en").format("YYYY-MM-DD")}
                      </span>
                    </div>
                  </div>
                  {/* 3*/}
                  <div className="d-flex justify-content-between">
                    <div className="w-50">
                      <span className="font-weight-bold">
                        {t("department_name")}
                      </span>
                    </div>
                    <div className="w-50">
                      <span className={styleNew.greyColor}>
                        {i18n.language === "ar"
                          ? serviceData?.Department_Name_AR
                          : serviceData?.Department_Name_EN}
                      </span>
                    </div>
                  </div>
                  {/* 4 */}
                  <div className="d-flex justify-content-between">
                    <div className="w-50">
                      <span className="font-weight-bold">
                        {t("Service Version")}
                      </span>
                    </div>
                    <div className="w-50">
                      <span className={styleNew.greyColor}>
                        {serviceData?.Department_Service_Version_Name}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              {icons.line}
              <Col md={3}>
                <div className={`mx-4 ${styleNew.w90}`}>
                  <div className="font-weight-bold mb-1 ">
                    {t("menu_municipality_of_sharjah")}
                  </div>
                  <div className={`${styleNew.navyGreenColor} mb-1`}>
                    {t("engineering_section")}
                  </div>
                  <div
                    className={`d-flex justify-content-between ${styleNew.w90}`}
                  >
                    {icons.facebookIcon}
                    {icons.instagarmIcon}
                    {icons.locationIcon}
                    {icons.phoneIcon}
                  </div>
                </div>
              </Col>
              <Col className={styleNew.technicalSupportContainer} md={2}>
                <button
                  onClick={handlePopup}
                  className={`mainBtn primarymainBtn p-2 ${styleNew.technicalSupportButton}`}
                >
                  {t("technicalSupport_request")}
                </button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* // New Header End */}

      <Form onSubmit={handleSubmit}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          className={i18n.language === "ar" ? "ar-dir" : ""}
          connector={<QontoConnector />}
        >
          {steps.map((label, index) => (
            <Step key={index}>{label}</Step>
          ))}
        </Stepper>

        <Row>
          {activeStep === 0 ? (
            <Col xl={12} className={style.step}>
              <div>
                {loading !== "pending" ? (
                  <Row>
                    {handleInputsdata(inputsData)}
                  </Row>
                ) : (
                  <div>{t("Loading")}</div>
                )}
              </div>
            </Col>
          ) : activeStep === 1 ? (
            <Col xl={12} className={style.step}>
              <Row lg="2">
                {attachmentsData?.length > 0 &&
                  [...attachmentsData]
                    .sort(
                      (a, b) =>
                        Number(a.Department_Service_Detail_No) -
                        Number(b.Department_Service_Detail_No)
                    )
                    .map((item) => {
                      return (
                        <FileUpload
                          key={item.Department_Service_Detail_Id}
                          defaultValue={() => handleFileValue(item)}
                          item={item}
                        />
                      );
                    })}
              </Row>
            </Col>
          ) : activeStep === 2 ? (
            <NewClientRequestInfo
              inputsData={inputsData}
              inputsDataValue={inputsDataValue}
              attachmentsData={attachmentsData}
              attachmentsDataValue={attachmentsDataValue}
            />
          ) : (
            false
          )}
          <Col md={12}>
            <Alert show={!!alertMsg} variant={'danger'} className="d-flex justify-content-between align-items-center" transition={true}>
              {alertMsg}

              <span className={style.dismiss} onClick={() => setAlertMsg(null)}>
                <svg height="311pt" viewBox="0 0 311 311.07733" width="311pt" xmlns="http://www.w3.org/2000/svg">
                  <path d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" />
                  <path d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
                </svg>
              </span>
            </Alert>
          </Col>
        </Row>

        {activeStep === 2 && (
          <Col xl={12} className={style.step}>
            <div className={style.terms}>
              <input
                className={style.checkBox}
                type="checkbox"
                defaultChecked={acceptedTerms}
                onChange={handleTerms}
              />
              <p ref={termsRef}>{t("terms and conditions")}</p>
            </div>

            <div
              className={style.terms}
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "ar"
                    ? serviceData?.Service_Rules_AR
                    : serviceData?.Service_Rules_EN,
              }}
            ></div>
          </Col>
        )}

        <div className={`${styleNew.nextPreviousContainer} mb-5`}>
          <div>
            {activeStep < 2 && (
              <button
                type={"button"}
                onClick={handleSave}
                className={`mainBtn primarymainBtn p-2 ${styleNew.nextButton}`}
              >
                {t("save")}
              </button>
            )}
          </div>
          <div style={{ display: "flex", gap: "25px" }}>
            {activeStep > 0 && (
              <button
                type="button"
                onClick={handleBack}
                className={`mainBtn secondarymainBtn outlined p-2 ${styleNew.previousButton}`}
              >
                {t("previous")}
              </button>
            )}
            {activeStep < 2 && (
              <button
                type={"button"}
                onClick={handleNext}
                className={`mainBtn primarymainBtn p-2 ${styleNew.nextButton}`}
              >
                {t("next")}
              </button>
            )}
            {acceptedTerms && activeStep === 2 && (
              <button
                type={"submit"}
                className={`mainBtn primarymainBtn p-2 ${styleNew.nextButton}`}
              >
                {t("submitService")}
              </button>
            )}
          </div>
        </div>

        <SubmitModal isShow={isModalShow} handleClick={handleModalClick} />

        <PopUp
          show={show}
          handleClose={handleClose}
          title={t("technical_support_request")}
          smallModal={true}
        >
          <Container>
            <Row>
              <Col>
                <label className={styleNew.label}>{t("notes")}</label>
                <Form.Control
                  onChange={handleSupportChange}
                  value={supportMessage}
                  name="client_notes"
                  as="textarea"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <button
                  onClick={handleOnSubmit}
                  className={[
                    "mainBtn primarymainBtn",
                    styleNew.tSupportBtn,
                  ].join(" ")}
                >
                  {t("confirm_technical_support_request")}
                </button>
              </Col>
            </Row>
          </Container>
        </PopUp>
      </Form>
      {isSubmiting && <LoadingOverlay />}
      {isUploading && <LoadingOverlay />}
    </>
  );
};

export default Request;

const FileUpload = ({ item, defaultValue }) => {
  const { t, i18n } = useTranslation("common");
  const [uploadedFiles, setUploadedFiles] = useState(
    defaultValue ? defaultValue : []
  );

  const { clientProfiles } = useSelector((state) => state.user);
  const { requestId } = useSelector((state) => state.service.serviceRequest);
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [isEllipsis, setIsEllipsis] = useState(true);
  const [progressValue, setProgressValue] = useState(0);
  const [isTextExpanded, setIsTextExpanded] = React.useState(false);

  const onChange = async (e) => {
    var files = e.target.files[0];

    if (files) {
      const data = new FormData();

      data.append(
        "PARAMETERS",
        JSON.stringify({
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Draft_Id: requestId,
          Service_Detail_Id: item.Service_Detail_Id,
        })
      );

      setIsUploading(true);

      data.append("Attached_File", e.target.files[0]);

      const response = await saveDraftAttachmentData(data, handleProgress)
        .catch((err) => {
          dispatch(setError(err));
        })
        .finally(() => {
          setIsUploading(false);
          setProgressValue(0);
        });
      if (response?.Status) {
        setUploadedFiles(response?.Data?.Files_Data);
      }
      e.target.value = null;
    }
  };

  const removeFile = async (f) => {
    const response = await deleteDraftAttachment({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Draft_Id: requestId,
      Service_Detail_Id: item.Service_Detail_Id,
      File_Code: f.File_Code,
    });
    if (response?.Status) {
      setUploadedFiles((prev) => prev.filter((x) => x !== f));
    }
  };

  const download = async (f) => {
    await downloadDraftAttachment({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Draft_Id: requestId,
      Service_Detail_Id: item.Service_Detail_Id,
      File_Code: f.File_Code,
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const handleToggleEllipsis = () => {
    setIsEllipsis(!isEllipsis);
  };

  const handleProgress = (progress) => {
    var progressValue = (progress.loaded / progress.total) * 100;
    setProgressValue(Math.trunc(progressValue));
  };

  const handleExpandLabel = (setState, title, ref) => {
    setIsTextExpanded((prev) => !prev);
  };

  return (
    <>
      <Col className="mt-5" key={item.Department_Service_Detail_Id} xs={12}>
        <div>
          {item.Department_Service_Detail_Required ? (
            <Form.Label
              onClick={handleExpandLabel}
              className={
                isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
              }
            >
              {i18n.language === "ar"
                ? item?.Department_Service_Detail_Title_AR
                : item?.Department_Service_Detail_Title_EN}
              <span className={style.required}>({t("required")})</span>
            </Form.Label>
          ) : (
            <Form.Label
              onClick={handleExpandLabel}
              className={
                isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
              }
            >
              {i18n.language === "ar"
                ? item?.Department_Service_Detail_Title_AR
                : item?.Department_Service_Detail_Title_EN}
            </Form.Label>
          )}
          <div className={style.fileContainer}>
            <label className={styleNew.fileContainer}>
              <Form.Control
                className={styleNew.file}
                type="text"
                required={
                  item.Department_Service_Detail_Required &&
                  !uploadedFiles?.length
                }
              ></Form.Control>
              <button className={styleNew.fileButton}>
                <div>{t("chooseFile")}</div>
                <div>{icons.upload}</div>
              </button>
              <div className={styleNew.progressBarContainer}>
                {isUploading && (
                  <ProgressBar value={progressValue} color="#D0B166" />
                )}
              </div>
            </label>
            <Form.Control
              className={style.file}
              type="file"
              onChange={onChange}
              required={
                item.Department_Service_Detail_Required &&
                !uploadedFiles?.length
              }
            />
            <Form.Text className={styleNew.disclaimr}>
              *<span> {t("Required File Format")}</span>
              {i18n.language === "ar"
                ? item.File_Content_Type_Name_AR
                : item.File_Content_Type_Name_EN}
            </Form.Text>

            {uploadedFiles?.length > 0 && (
              <div className={styleNew.files}>
                {uploadedFiles.map((uploadedFile) => (
                  <div
                    key={uploadedFile.File_Code}
                    className="d-flex justify-content-between  px-2 py-1"
                  >
                    <div className="d-flex flex-wrap ">
                      {getFileIcon(uploadedFile.File_Content)}
                      <span
                        onClick={handleToggleEllipsis}
                        className={`${styleNew.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                          } mx-1`}
                      >
                        {uploadedFile.File_Name}
                      </span>
                      <span className={`${styleNew.fileContent} mx-1`}>
                        {uploadedFile.File_Size}
                      </span>
                    </div>
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className="mx-1"
                        size="1x"
                        color={"black"}
                        icon={faDownload}
                        onClick={() => download(uploadedFile)}
                      />
                      <FontAwesomeIcon
                        className="mx-1"
                        size="1x"
                        color={"red"}
                        icon={faTrashAlt}
                        onClick={() => removeFile(uploadedFile)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Col>

      {isUploading && <LoadingOverlay />}
    </>
  );
};

const getFileIcon = (fileType) => {
  return (
    {
      "image/jpeg": <Jpg />,
      "image/png": <Png />,
      "video/mp4": <Mp4 />,
      "application/pdf": <Pdf />,
      "application/vnd.ms-powerpoint": <Doc />,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        <Doc />,
      "application/msword": <Doc />,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        <Doc />,
      "audio/mpeg": <Mp3 />,
      "application/zip": <Zip />,
      "text/csv": <Csv />,
      "text/plain": <Text />,
    }[fileType] ?? <Attachment />
  );
};
