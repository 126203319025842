import React, { useState, useEffect } from 'react'
import ServicesSupportGrid from './ServicesSupportGrid'
import PopUp from '../../../UIComponents/PopUp/PopUp'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import style from './../form.module.scss'
import moment from 'moment'

const ServicesSupport = props => {

    const { t, i18n } = useTranslation('common')
    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()
    const [clickFix, setClickFix] = useState(false)

    const handleClose = () => setShow(false)

    const handleShow = data => {
        setModalData(data)
        setClickFix(!clickFix)
    }

    useEffect(() => {
        if (modalData?.Support_Request_Id) {
            setShow(true)
        }
    }, [clickFix])

    return (
        <>
            <ServicesSupportGrid
                handleShow={handleShow}
                dataIsReady={props.dataIsReady}
            />

            {modalData ?
                <PopUp
                    show={show}
                    handleClose={handleClose}
                    title={t('technical_support_requests')}
                >
                    <Container>
                        <Formik
                            initialValues={{
                                request_no: modalData?.Support_Request_No,
                                request_date: moment(modalData?.Support_Request_Date).lang("en").format('YYYY-MM-DD'),
                                request_status: i18n.language === 'en' ? modalData?.Support_Request_Status_Name_EN : modalData?.Support_Request_Status_Name_AR,
                                department_name: i18n.language === 'en' ? modalData?.Department_Name_EN : modalData?.Department_Name_AR,
                                service_name: i18n.language === 'en' ? modalData?.Service_Name_EN : modalData?.Service_Name_AR,
                                page_url: modalData?.Page_URL,
                                client_notes: modalData?.Client_Notes,
                                process_result_notes: modalData?.Process_Result_Notes,
                                support_request_type: i18n.language === 'en' ? modalData?.Support_Request_Type_Name_EN : modalData?.Support_Request_Type_Name_AR,
                            }}
                        >
                            <Form className={style.dashboardData}>
                                <Row
                                    style={{ 'textAlign': i18n.language === 'ar' ? 'start' : null }}
                                >
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_no')}</label>
                                        <Field className={style.dashboardField} name="request_no" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_date')}</label>
                                        <Field className={style.dashboardField} name="request_date" type='text' readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_status')}</label>
                                        <Field className={style.dashboardField} name="request_status" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('department_name')}</label>
                                        <Field className={style.dashboardField} name="department_name" type="text" readOnly />
                                    </Col>
                                    <Col sm={12}>
                                        {/* <label className={style.name}>{t('service_name')}</label>  */}
                                        <Field name="service_name" type="text" as="textarea" className={['text-center title mainName', style.dashboardField].join(' ')} readOnly />
                                    </Col>
                                    <Col sm={12}>
                                        <label className={style.name}>{t('notes')}</label>
                                        <Field className={style.dashboardField} name="client_notes" type="text" as="textarea" readOnly />
                                    </Col>
                                    <Col md={6} sm={6}>
                                        <label className={style.name}>{t('page_url')}</label>
                                        <Field className={style.dashboardField} name="page_url" type="text" readOnly />
                                    </Col>
                                    <Col md={6} sm={6}>
                                        <label className={style.name}>{t('request_type')}</label>
                                        <Field className={style.dashboardField} name="support_request_type" type="text" readOnly />
                                    </Col>
                                    <Col sm={12}>
                                        <label className={style.name}>{t('process_result_notes')}</label>
                                        <Field className={style.dashboardField} name="process_result_notes" type="text" as="textarea" readOnly />
                                    </Col>
                                </Row>
                            </Form>
                        </Formik>
                    </Container>
                </PopUp>
                : null}
        </>

    )
}

export default React.memo(ServicesSupport)
