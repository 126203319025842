import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import BlankIMG1 from "./../../../global/images/Links-BlankIMGs-01.svg";
import BlankIMG2 from "./../../../global/images/Links-BlankIMGs-02.svg";
import BlankIMG3 from "./../../../global/images/Links-BlankIMGs-03.svg";
import BlankIMG4 from "./../../../global/images/Links-BlankIMGs-04.svg";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import style from "./ShortLinks.module.scss";
import Header from "./../../UIComponents/Header/Header";
import Footer from "./../../UIComponents/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";

const ShortLinks = () => {
    const { t, i18n } = useTranslation('common');
    const { user } = useSelector((state) => state.user);
    const { linkCode } = useParams();
    const history = useHistory();
    const [data, setData] = useState();
    const [activeImg, setActiveImg] = useState();

    useEffect(() => {
        getShortLinks()
    }, [])

    useEffect(() => {
        let activeImg
        if (data?.Link_Status_Id == 0) {
            activeImg = BlankIMG4
        } else if (data?.Link_Status_Id == 1) {
            activeImg = BlankIMG2
        } else if (data?.Link_Status_Id == 3) {
            activeImg = BlankIMG3
        } else if (data?.Link_Status_Id == 4) {
            activeImg = BlankIMG1
        }
        setActiveImg(activeImg)

        if (data?.Link_URL) {
            setTimeout(() => {
                window.location.href = data?.Link_URL
            }, 1000)
        }
    }, [data])

    const getShortLinks = async () => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/SYSTEM/GENERAL/Get_Short_Link`,
            // 'https://muns.aisworx.services/API/SYSTEM/GENERAL/Get_Short_Link',
            {
                Link_Code: linkCode
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`,
                }
            }
        );
        setData(res.data.Data);
    };

    return (
        <>
            <Header />

            <Container fluid="lg" className={style.shortLinksContainer}>
                {!data ?
                    <>
                        <p className={style.pendingMsg}>{t('The requested link is being verified')}</p>
                        <LoadingOverlay />
                    </>
                    : <div className={style.dataBox}>
                        <img className={style.statusImage} src={activeImg} />
                        <p className={style.statusMsg}>{i18n.language == 'ar' ? data?.Link_Message_AR : data?.Link_Message_EN}</p>
                        {data?.Link_URL && <>
                            <p className={style.browserHint}>{t('short links browser hint')}</p>
                            <a className={style.link} href={data?.Link_URL}>{t('click here')}</a>
                        </>}
                    </div>
                }
            </Container>

            <Footer />
        </>
    )
}

export default ShortLinks