import React from 'react'

const Pagination = props => {

    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(props.totalDecision / props.noPerPage); i++) {
        pageNumbers.push(i)
    }

    const Disabled = {
        background: '#dee2e6',
        color: 'grey',
    }

    return (
        <nav>
            <ul className="pagination">
                {
                    pageNumbers.map(number => (
                        <li key={number} className='page-item'>
                            <button 
                                disabled={number === props.currentPage}
                                href='!#' 
                                className='page-link'
                                onClick={() => props.paginate(number)}
                                style={number === props.currentPage ? Disabled : null}
                            >
                                {number}
                            </button>
                        </li>
                    ))
                }
            </ul>
        </nav>
    )
}

export default Pagination
