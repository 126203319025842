import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import TitleHeader from './TitleHeader/TitleHeader'
import LogoHeader from './LogoHeader/LogoHeader'
import Menu from './Menu/Menu'

const Header = props => {

    return (
        <header>
            <Container fluid='lg'>
                <TitleHeader />
                <LogoHeader />
                <Menu municipal={props.municipal} />
            </Container>
        </header>
    )
}

export default Header
