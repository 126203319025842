import React from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import inputStyles from "./input.module.css";

const SelectField = ({ label, children, ...props }) => {
  const [field, meta] = useField(props);
  const { t } = useTranslation("auth");
  return (
    <>
      <label className={inputStyles.wrapper}>
        {label}
        <select {...field} {...props}>
          {children}
        </select>
        {meta.touched && meta.error ? <div className={inputStyles.error}>{t(`${meta.error}`)}</div> : null}
      </label>
    </>
  );
};

export default SelectField;
