import React, { useState, useEffect } from 'react'
import { Column } from 'devextreme-react/data-grid'
import { useTranslation } from 'react-i18next'
import Grid from '../../UIComponents/Grid/index'
import axios from 'axios'
import { useSelector } from 'react-redux'

const InvoicesGrid = props => {

    const { t, i18n } = useTranslation('common')
    const [data, setData] = useState()
    const { user, clientProfiles } = useSelector((state) => state.user)

    useEffect(() => {
        const getInvoicesList = async () => {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/PORTAL/PAYMENTS/INVOICES/GET_CLIENTS_INVOICES_LIST`,
                {
                    'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                    'PageIndex': '1',
                    'PageCount': '10'
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`
                    }
                }
            )
            setData(res.data.Data.Data)
        }
        getInvoicesList()
    }, [])

    let invoiceTypeName = 'Invoice_Type_Name_EN',
        invoiceStatusName = 'Invoice_Status_Name_EN',
        departmentName = 'Department_Name_EN',
        serviceName = 'Service_Name_EN',
        areaName = "Area_Name_EN";


    if (i18n.language === 'ar') {
        invoiceTypeName = 'Invoice_Type_Name_AR'
        invoiceStatusName = 'Invoice_Status_Name_AR'
        departmentName = 'Department_Name_AR'
        serviceName = 'Service_Name_AR'
        areaName = "Area_Name_AR"
    }

    return (
        <>
            <Grid
                sourceData={data}
                addEnabled={false}
                editEnabled={false}
                deleteEnabled={false}
                viewEnabled={true}
                onView={({ data }) => {
                    props.handleShow(data.Invoice_Id)
                }}
                onDelete={({ data }) => { }}
            >
                <Column caption={t('invoice_no')} alignment="center" dataField="Invoice_No" dataType="string" width={90} />
                <Column caption={t('invoice_serial_no')} alignment="center" dataField="Invoice_Id" dataType="string" width={90} />


                <Column caption={t('request_no')} alignment="center" dataField="Request_No" dataType="string" width={150} />
                <Column caption={t('request_date')} alignment="center" dataField="Request_Date" dataType="date" width={120} format="yyyy-MM-dd" />

                <Column caption={t('service_name')} alignment="center" dataField={serviceName} dataType="string" />

                <Column caption={t('invoice_type')} alignment="center" dataField={invoiceTypeName} dataType="string" width={150} />
                <Column caption={t('invoice_amount')} alignment="center" dataField="Invoice_Value" dataType="string" width={90} />

                <Column caption={t('payment_status')} alignment="center" dataField={invoiceStatusName} dataType="string" width={150} visible={false} />
                <Column caption={t('payment_date')} alignment="center" dataField="Invoice_Paid_Date" dataType="date" width={120} visible={false} format="yyyy-MM-dd" />

                <Column caption={t('reference_no')} alignment="center" dataField="Online_Reference_No" dataType="string" visible={false} />
                <Column caption={t('department_name')} alignment="center" dataField={departmentName} dataType="string" visible={false} />

                <Column caption={t("owner")} alignment="center" dataField='Owner_Name' dataType="string" width={140} visible={false} />
                <Column caption={t("plot_no")} alignment="center" dataField='Plot_No' dataType="string" width={140} visible={false} />
                <Column caption={t("area_name")} alignment="center" dataField={areaName} dataType="string" width={140} visible={false} />
                <Column caption={t("project_no")} alignment="center" dataField='Project_No' dataType="string" width={140} visible={false} />
            </Grid>
        </>
    )

}

export default InvoicesGrid
