import React from "react";

function Png() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 350 350">
      <g fill="none" fillRule="nonzero" stroke="none" strokeDasharray="none" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1" opacity="1">
        <path
          fill="#B83535"
          d="M11.58 54.882v31.965A3.153 3.153 0 0014.733 90h60.534a3.153 3.153 0 003.153-3.153V54.882c-22.347-7.001-44.628-7.044-66.84 0z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#E9E9E0" d="M78.42 54.882V18.345C69.386 13.658 63.133 7.61 60.075 0H14.733a3.153 3.153 0 00-3.153 3.153v51.729h66.84z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#D9D7CA" d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path
          fill="#F9F9F9"
          d="M31.15 62.719h-5.944a1.5 1.5 0 00-1.5 1.5V80.82a1.5 1.5 0 103 0v-5.247h4.444a4.343 4.343 0 004.338-4.339v-4.177a4.343 4.343 0 00-4.338-4.338zm1.338 8.514a1.34 1.34 0 01-1.338 1.339h-4.444v-6.854h4.444a1.34 1.34 0 011.338 1.338v4.177zM62.699 82.351h-5.824a3.6 3.6 0 01-3.596-3.595V66.305a3.603 3.603 0 013.58-3.595l5.482-.023h.007a1.5 1.5 0 01.007 3l-5.482.023a.598.598 0 00-.594.595v12.451c0 .328.268.595.596.595h5.824a.596.596 0 00.595-.595v-5.099a.596.596 0 00-.595-.595h-2.386a1.5 1.5 0 010-3h2.386a3.599 3.599 0 013.595 3.595v5.099a3.599 3.599 0 01-3.595 3.595zM48.53 82.335a1.5 1.5 0 01-1.333-.813L41.449 70.38v10.454a1.5 1.5 0 11-3 0V64.202c0-.692.474-1.294 1.146-1.458a1.501 1.501 0 011.687.771l5.748 11.142V64.202a1.5 1.5 0 013 0v16.633a1.502 1.502 0 01-1.5 1.5z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
      </g>
    </svg>
  );
}

export default Png;
