import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import style from "./TitleHeader.module.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";
import LanguageBtn from "./../LanguageBtn/LanguageBtn";
import "moment/locale/ar";

const TitleHeader = () => {
  const { t, i18n } = useTranslation("common");
  const [fontDropState, setFontDropState] = useState(false);
  const fontIncBtn = useRef();
  const fontDecBtn = useRef();
  let bodyFontsize = window.getComputedStyle(document.body).getPropertyValue('font-size')
  let htmlTag = document.querySelector("html")

  let date = moment().format('dddd, MMMM Do YYYY h:mm a')
  date = date.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) { return d.charCodeAt(0) - 1632; })
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) { return d.charCodeAt(0) - 1776; });

  let hijriAR = new Intl.DateTimeFormat('ar-FR-u-ca-islamic', {
    day: 'numeric', 
    month: 'long',
    year : 'numeric'
  }).format(Date.now()); 

  let hijriEN = new Intl.DateTimeFormat('en-FR-u-ca-islamic', {
    day: 'numeric', 
    month: 'long',
    year : 'numeric'
  }).format(Date.now());  

  useEffect(() => {
    if (bodyFontsize == '20px') fontIncBtn.current.classList.add(`${style.disabledBtn}`)
    else if (bodyFontsize == '18px') fontIncBtn.current.classList.remove(`${style.disabledBtn}`) 
    else if (bodyFontsize == '14px') fontDecBtn.current.classList.remove(`${style.disabledBtn}`)
    else if (bodyFontsize == '12px') fontDecBtn.current.classList.add(`${style.disabledBtn}`)
  }, [])

  const handleFontSizeInc = () => {
    if (bodyFontsize == '12px') {
      htmlTag.style.fontSize = "14px";
      fontDecBtn.current.classList.remove(`${style.disabledBtn}`)
    } else if (bodyFontsize == '14px') {
      htmlTag.style.fontSize = "16px";
    } else if (bodyFontsize == '16px') {
      htmlTag.style.fontSize = "18px";
    } else if (bodyFontsize == '18px') {
      htmlTag.style.fontSize = "20px";
      fontIncBtn.current.classList.add(`${style.disabledBtn}`)
    }
  }

  const handleFontSizeDec = () => {
    if (bodyFontsize == '20px') {
      htmlTag.style.fontSize = "18px";
      fontIncBtn.current.classList.remove(`${style.disabledBtn}`)
    } else if (bodyFontsize == '18px') {
      htmlTag.style.fontSize = "16px";
    } else if (bodyFontsize == '16px') {
      htmlTag.style.fontSize = "14px";
    } else if (bodyFontsize == '14px') {
      htmlTag.style.fontSize = "12px";
      fontDecBtn.current.classList.add(`${style.disabledBtn}`)
    }
  }

  const handleColorAcc = () => {
    let theme = document.getElementById('theme')
    if (theme.classList.contains('mainTheme')) {
      theme.classList.add('colorBlind1')
      theme.classList.remove('mainTheme')
    } else if (theme.classList.contains('colorBlind1')) {
      theme.classList.add('colorBlind2')
      theme.classList.remove('colorBlind1')
    } else if (theme.classList.contains('colorBlind2')) {
      theme.classList.add('mainTheme')
      theme.classList.remove('colorBlind2')
    }
  }

  return (
    <Row className="pt-4 align-items-center">
      <Col className={[style.title, "order-last order-sm-first"].join(" ")} sm={4} xs={12}>
        {t("header_title")}
      </Col>

      <Col sm={4} xs={6} className={style.dates}>
        <span>{date}</span>
        <span>{i18n.language === 'ar' ? hijriAR : hijriEN}</span>
      </Col>

      <Col 
        className={[
          style.iconsContainer, 
          "d-flex justify-content-end align-items-center"
        ].join(" ")} 
        sm={4} 
        xs={6}
      >
        <LanguageBtn />

        <span 
          className={[style.brightIcon, "d-flex"].join(" ")}
          onClick={() => handleColorAcc()}
        >
          <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Bright</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="0" transform="translate(-459.000000, -35.000000)" fill="#000000" fillRule="nonzero">
                <path
                  d="M465.5,48 C461.91005,48 459,45.08995 459,41.5 C459,37.91005 461.91005,35 465.5,35 C469.08995,35 472,37.91005 472,41.5 C472,45.08995 469.08995,48 465.5,48 Z M465.5,46.7 L465.5,36.3 C462.628119,36.3 460.3,38.6281193 460.3,41.5 C460.3,44.3718807 462.628119,46.7 465.5,46.7 L465.5,46.7 Z"
                  id="Bright"></path>
              </g>
            </g>
          </svg>
        </span>

        <span 
          className={[style.fontIcon, "d-flex"].join(" ")}
          onClick={() => setFontDropState(!fontDropState)}
        >
          <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>Font</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="0" transform="translate(-359.000000, -34.000000)">
                <g id="Font" transform="translate(359.000000, 34.000000)">
                  <polygon id="Path" points="0 0 15.3576 0 15.3576 15.3576 0 15.3576"></polygon>
                  <path
                    d="M2.5596,1.9197 L12.798,1.9197 C13.151407,1.9197 13.4379,2.20619299 13.4379,2.5596 L13.4379,12.798 C13.4379,13.151407 13.151407,13.4379 12.798,13.4379 L2.5596,13.4379 C2.20619299,13.4379 1.9197,13.151407 1.9197,12.798 L1.9197,2.5596 C1.9197,2.20619299 2.20619299,1.9197 2.5596,1.9197 Z M6.3151731,9.5985 L9.0424269,9.5985 L9.5671449,10.8783 L10.9851633,10.8783 L8.3187,4.4793 L7.0389,4.4793 L4.3724367,10.8783 L5.791095,10.8783 L6.315813,9.5985 L6.3151731,9.5985 Z M6.8398911,8.3187 L7.6788,6.27102 L8.5177089,8.3187 L6.840531,8.3187 L6.8398911,8.3187 Z"
                    id="Shape"
                    fill="#000000"
                    fillRule="nonzero"></path>
                </g>
              </g>
            </g>
          </svg>
          
          <div 
            className={[
              style.fontDropOverlay,
              fontDropState ? style.show : style.hide
            ].join(' ')}>
          </div>
          
          <div 
            className={[
              style.fontDropdown,
              fontDropState ? style.dropOpen : style.dropClose,
              i18n.language == 'en' ? style.dropEn : style.dropAr
            ].join(' ')}
          >
            <span 
              className={[style.fontIcon].join(' ')}
              ref={fontIncBtn}
              onClick={() => handleFontSizeInc()}
            >
              <svg 
                version="1.0" 
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                  <path d="M3913 5071 c-111 -41 -202 -129 -244 -238 -19 -49 -23 -83 -27 -219
                    l-4 -162 -162 -4 c-137 -4 -170 -8 -219 -27 -270 -106 -344 -450 -140 -654 26
                    -27 77 -62 113 -80 64 -32 66 -32 237 -35 l173 -4 0 -127 c0 -213 28 -305 123
                    -400 61 -62 130 -97 217 -113 170 -29 352 68 428 230 25 53 27 69 32 232 l5
                    175 175 5 c172 5 176 6 241 37 81 40 150 111 190 197 96 205 -6 454 -221 538
                    -46 18 -82 22 -222 26 l-166 4 -4 172 c-3 168 -4 172 -35 238 -40 86 -106 151
                    -191 191 -87 41 -217 49 -299 18z"
                  />
                  <path d="M1795 4436 c-106 -34 -194 -104 -238 -193 -14 -26 -359 -869 -767
                    -1872 -502 -1234 -746 -1845 -753 -1887 -28 -164 65 -339 221 -416 62 -31 73
                    -33 172 -33 99 0 110 2 172 33 44 21 84 52 120 91 61 65 49 40 299 658 l134
                    332 750 1 750 0 174 -427 c96 -236 185 -450 198 -477 37 -73 107 -142 182
                    -179 62 -30 73 -32 171 -32 98 0 110 2 170 32 132 65 216 186 227 326 3 43 1
                    94 -5 120 -10 39 -1487 3678 -1514 3732 -22 41 -81 106 -125 136 -90 63 -239
                    86 -338 55z m306 -1922 c105 -258 199 -490 210 -516 l19 -48 -421 0 c-398 0
                    -421 1 -417 18 7 26 413 1021 415 1018 2 -1 89 -214 194 -472z"
                  />
                </g>
              </svg>
            </span>

            <span 
              className={[style.fontIcon].join(' ')}
              ref={fontDecBtn}
              onClick={() => handleFontSizeDec()}
            >
              <svg 
                version="1.0" 
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#000000" stroke="none"
                >
                  <path d="M1795 4756 c-106 -34 -194 -104 -238 -193 -50 -98 -1507 -3691 -1518
                    -3743 -35 -166 60 -354 220 -433 61 -30 72 -32 171 -32 99 0 110 2 172 33 44
                    21 84 52 120 91 61 65 49 40 299 658 l134 332 750 1 751 0 173 -428 c96 -235
                    187 -453 204 -485 36 -69 107 -136 185 -174 51 -25 67 -28 162 -28 98 0 110 2
                    170 32 132 65 216 186 227 326 3 43 1 94 -6 120 -9 39 -1485 3678 -1513 3732
                    -22 41 -81 106 -125 136 -90 63 -239 86 -338 55z m320 -1956 c108 -267 201
                    -495 206 -507 l9 -23 -421 0 c-398 0 -421 1 -417 18 16 59 414 1018 419 1009
                    4 -7 95 -230 204 -497z"
                  />
                  <path d="M3303 4755 c-196 -54 -313 -214 -301 -416 8 -142 94 -266 228 -332
                    l65 -32 745 0 745 0 57 23 c84 34 160 107 205 200 36 71 38 81 37 166 -1 178
                    -90 311 -254 378 l-55 23 -710 2 c-590 2 -719 0 -762 -12z"
                  />
                </g>
              </svg>
            </span>
          </div>
        </span>
      </Col>
    </Row>
  );
};

export default TitleHeader;
