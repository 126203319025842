import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import style from './AllServices.module.scss'
import ServicesCard from './ServicesCard/ServicesCard'
import { NavLink } from 'react-router-dom'

const AllServices = props => {

    const { t, i18n } = useTranslation('common')
    const backgroundRef = useRef()
    const buttonRef = useRef()
    const servicesRef = useRef()
    const [cardHeight, setCardHeight] = useState()

    const updateDimensions = () => {
        setCardHeight(document.getElementById('servicesCard')?.offsetHeight);
    }

    useEffect(() => {
        setInterval(() => {
            updateDimensions()
        }, 1);
    }, [])

    useEffect(() => {
        // console.log(cardHeight)
        if (window.innerWidth < 767) {
            // console.log('window.innerWidth < 767')
            backgroundRef.current.style.height = `${cardHeight + 200}px`
            buttonRef.current.style.top = `${cardHeight + 250}px`
        } 
        else if (window.innerWidth > 768) {
            // console.log('window.innerWidth > 768')
            // Card height + 280 card absolute top + 70 space before and after
            backgroundRef.current.style.height = `${cardHeight + 280 + 70}px`
            // Card height + 280 card absolute top
            buttonRef.current.style.top = `${cardHeight + 280 - 50}px`
        } 
        else if (window.innerWidth = 768) {
            // backgroundRef.current.style.height = `${cardHeight + 450}px`
            backgroundRef.current.style.height = `${cardHeight + 280 + 70}px`
            buttonRef.current.style.top = `${cardHeight + 250}px`
        }
    }, [cardHeight])

    const handleAnimation = () => {
        if (i18n.language == 'en') {
            servicesRef?.current?.classList?.add('servicesSlideEN')
            setTimeout(() => servicesRef?.current?.classList?.remove('servicesSlideEN'), 2000);
        } else {
            servicesRef?.current?.classList?.add('servicesSlideAR')
            setTimeout(() => servicesRef?.current?.classList?.remove('servicesSlideAR'), 2000);
        }
        
    }

    window.addEventListener("resize", updateDimensions);

    return (
        <Container fluid='lg' className={style.container}>
            <Row>
                <Col sm={6}>
                    <p className={style.title}>{t('all_services')}</p>
                    <p className={style.para}>{t('all_services_para')}</p>
                </Col>
                <Col sm={6} className={style.background} id='backgroundSer' ref={backgroundRef}></Col>
            </Row>

            <Row 
                className={[
                    style.servicesCard,
                    i18n.language === 'en' ? style.servicesCardEn : style.servicesCardAr
                ].join(' ')}
            >
                <Col md={10} className='gCard'>
                    <ServicesCard 
                        homePage={props.homePage} 
                        handleDimensions={updateDimensions} 
                        handleAnimation={handleAnimation}
                        servicesRef={servicesRef}
                    />
                </Col>
            </Row>

            <Row 
                className={[
                    style.buttonContainer,
                    i18n.language === 'en' ? style.buttonContainerEn : style.buttonContainerAr
                ].join(' ')}
                ref={buttonRef}
            >
                <Col>
                    <NavLink 
                        className={[
                            style.allServicesBtn,
                            i18n.language === 'en' ? style.allServicesBtnEn : null
                        ].join(' ')} 
                        exact 
                        to='/our_services' 
                    >
                        <span>
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 22 22" xmlSpace="preserve">
                                <path d="M0,11C0,4.9,4.9,0,11,0c6.1,0,11,4.9,11,11s-4.9,11-11,11S0,17.1,0,11z M6,11c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3
                                    l3,3C9.5,14.9,9.7,15,10,15s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L9.6,12H15c0.6,0,1-0.4,1-1s-0.4-1-1-1H9.6l1.1-1.3
                                    c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-3,3c-0.1,0.1-0.2,0.2-0.2,0.3C6,10.7,6,10.9,6,11z"/>
                            </svg>
                        </span>
                        <p>{t('all_services')}</p>
                    </NavLink>
                </Col>
            </Row>
        </Container>
    )
}

export default AllServices
