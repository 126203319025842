import React, { useState, useEffect } from 'react'
import Grid from 'components/UIComponents/Grid'
import { Column } from 'devextreme-react/data-grid'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import style from "./../Dashboard.module.scss"
import { Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

const PaymentsGrid = props => {

  const { t, i18n } = useTranslation('common')
  const [data, setData] = useState()
  const { user, clientProfiles } = useSelector((state) => state.user);
  const history = useHistory()

  useEffect(() => {
    const getPaymentsList = async () => {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/DASHBOARD/GET_CLIENT_DASHBOARD_PAYMENTS_LISTS`,
        {
          'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
          'PageIndex': '1',
          'PageCount': '1000'
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`
          }
        }
      ).then(data => {
        setData(data.data.Data.Data)
        props.dataIsReady(false)
      })
    }
    getPaymentsList()
  }, [])

  let serviceName = 'Service_Name_EN',
    invoiceTypeName = 'Invoice_Type_Name_EN',
    invoicePayerName = 'Invoice_Payer_Name_EN',
    departmentName = 'Department_Name_EN',
    areaName = "Area_Name_EN";

  if (i18n.language === 'ar') {
    serviceName = 'Service_Name_AR';
    invoiceTypeName = 'Invoice_Type_Name_AR';
    invoicePayerName = 'Invoice_Payer_Name_AR';
    departmentName = 'Department_Name_AR';
    areaName = "Area_Name_AR";
  }

  const downloadFile = (invoiceId, fileCode) => {
    const res = axios.post(
      `${process.env.REACT_APP_API_URL}/PORTAL/PAYMENTS/INVOICES/DOWNLOAD_ClIENTS_INVOICE_INFO`,
      {
        'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
        'Invoice_id': invoiceId,
        'File_Code': fileCode
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`
        }
      }
    ).then(res => {
      let a = document.createElement("a"); // Create <a>
      a.href = "data:image/png;base64," + res.data.Data.File_Bytes; // Base64 
      a.download = res.data.Data.File_Name; // File name 
      a.click(); // Download file
    })
  }

  const customizeDownload = ({ data }) => {
    if (data.Payment_File_Code) {
      return (
        <span className='p-4' onClick={() => downloadFile(data.Invoice_Id, data.Payment_File_Code)}>
          <FontAwesomeIcon size="sm" icon={faDownload} color="#58743A" />
        </span>
      );
    }
  }

  return (
    <>
      <Row className='justify-content-center'>
        <p className={style.gridTitle}>{t("insurance_fees_fines")}</p>
      </Row>

      <Row>
        <Grid
          sourceData={data}
          addEnabled={false}
          editEnabled={false}
          deleteEnabled={false}
          viewEnabled={true}
          onView={({ data }) => {
            history.push({
              pathname: `/payments-view/${data?.Invoice_Id}`,
              state: { data: data }
            })

          }}
          onDelete={({ data }) => { }}
        >
          <Column caption={t('payment_no')} alignment="center" dataField="Payment_No" dataType="string" width="auto" />
          <Column caption={t('bond_date')} alignment="center" dataField="Invoice_Date" dataType="date" format="yyyy-MM-dd" width="auto" />
          <Column caption={t('invoice_value')} alignment="center" dataField="Invoice_Value" dataType="string" width="auto" />
          <Column caption={t('payment_order_no')} alignment="center" dataField="Online_Reference_No" dataType="string" width="auto" />
          <Column caption={t('payment_date')} alignment="center" dataField="Invoice_Paid_Date" dataType="date" format="yyyy-MM-dd" width="auto" />
          <Column caption={t('payment_value')} alignment="center" dataField="Invoice_Paid_Value" dataType="string" width="auto" />
          <Column caption={t('payer_name')} alignment="center" dataField={invoicePayerName} dataType="string" width="auto" />
          <Column caption={t("owner")} alignment="center" dataField='Owner_Name' dataType="string" width="auto" />
          <Column caption={t('department_name')} alignment="center" dataField={departmentName} dataType="string" width="auto" />
          <Column caption={t('request_no')} alignment="center" dataField="Request_No" dataType="string" width="auto" />
          <Column caption={t('download')} alignment="center" dataField="Payment_File_Code" cellRender={customizeDownload} width="auto" />

          <Column caption={t('service_name')} alignment="center" dataField={serviceName} dataType="string" width="auto" visible={false} />
          <Column caption={t("project_no")} alignment="center" dataField='Project_No' dataType="string" width="auto" visible={false} />
          <Column caption={t("area_name")} alignment="center" dataField={areaName} dataType="string" width="auto" visible={false} />
          <Column caption={t("plot_no")} alignment="center" dataField='Plot_No' dataType="string" width="auto" visible={false} />
        </Grid>
      </Row>
    </>
  )
}

export default PaymentsGrid
