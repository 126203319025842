import { createSlice } from "@reduxjs/toolkit";
import { resetStates } from "./serviceCardSlice";

const slice = createSlice({
  name: "serviceMachine",
  initialState: {
    currentState: "Info",
    isAuthorized: false,
  },
  reducers: {
    toState: (state, { payload }) => {
      state.currentState = payload;
    },
    resetMachine: (state) => {
      state.currentState = "Info";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStates, (state) => {
      state.currentState = "Info";
    });
  },
});
export default slice.reducer;

export const { prevState, toState, resetMachine } = slice.actions;

export const authorizedState = (stateName) => (dispatch, getState) => {
  const { clientProfiles } = getState().user;
  const { serviceData } = getState().service.serviceCard;

  if (serviceData?.Dealing_Type_Id === clientProfiles?.Dealing_Type_Id) {
    dispatch(toState(stateName));
  }
};
