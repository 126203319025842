import React from 'react'
import Header from './../../UIComponents/Header/Header'
import DepartmentContactUs from './../../UIComponents/DepartmentContactUS/DepartmentContactUs'
import Footer from './../../UIComponents/Footer/Footer'

const ContactUS = () => {

    return (
        <>
            <Header />
            <DepartmentContactUs municipalPage={false} />
            <Footer />
        </>
    )
}

export default ContactUS
