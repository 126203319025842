import React from "react";

function Attachment() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 350 350">
      <g fill="none" fillRule="nonzero" stroke="none" strokeDasharray="none" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1" opacity="1">
        <path
          fill="#E9E9E0"
          d="M78.42 18.345v68.502A3.153 3.153 0 0175.267 90H14.733a3.153 3.153 0 01-3.153-3.153V3.153A3.153 3.153 0 0114.733 0h45.343c3.057 7.61 9.31 13.658 18.344 18.345z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#D9D7CA" d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path
          fill="#B83535"
          d="M60.163 32.067a10.81 10.81 0 00-8.182-1.576 10.818 10.818 0 00-6.912 4.654 1.5 1.5 0 002.502 1.655 7.836 7.836 0 015.008-3.369 7.846 7.846 0 015.929 1.138c3.63 2.4 4.63 7.306 2.23 10.936L42.428 73.2a7.839 7.839 0 01-5.008 3.368 7.836 7.836 0 01-5.928-1.139 7.836 7.836 0 01-3.369-5.008 7.834 7.834 0 011.137-5.926l.002-.002 12.601-19.059a3.09 3.09 0 014.278-.872 3.058 3.058 0 011.316 1.958 3.064 3.064 0 01-.444 2.318L34.527 67.724a1.5 1.5 0 102.503 1.654l12.485-18.884a6.042 6.042 0 00.882-4.572 6.036 6.036 0 00-2.602-3.861c-2.798-1.853-6.582-1.081-8.435 1.72L26.759 62.84l-.004.007c-3.307 5.008-1.925 11.774 3.083 15.085a10.827 10.827 0 005.99 1.804c3.537-.001 7.01-1.717 9.102-4.882L63.241 47.16c3.313-5.009 1.932-11.78-3.078-15.093z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
      </g>
    </svg>
  );
}

export default Attachment;
