import React from "react";
import Modal from "react-bootstrap/Modal";
import modal from "./loginModal.module.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoginModal = (props) => {
  const { t, i18n } = useTranslation("common");
  return (
    <Modal className={modal.container} {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className={"d-flex flex-column align-items-center p-5"}>
        <p className={modal.content}>{t("Kindly Login")}</p>
        <NavLink exact to="/login">
          <button className={[modal.siginIn, i18n.language === "en" ? modal.signEn : modal.signAr].join(" ")}>{t("login_in")}</button>
        </NavLink>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
