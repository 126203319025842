import React, { useState, useEffect } from 'react'
import style from './CommonQuestions.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Footer from './../../UIComponents/Footer/Footer'
import Header from './../../UIComponents/Header/Header'
import { Formik, Form, Field } from "formik"
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import { Accordion, Card } from 'react-bootstrap'


const CommonQuestions = () => {

    const { t, i18n } = useTranslation('common')
    const [questions, setQuestions] = useState()
    const [open, setOpen] = useState(null);

    useEffect(() => {
        handleQuestions()
    }, [])

    const handleQuestions = async (val) => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/PORTAL/QUESTIONS/GET_QUESTION_LIST`,
            {
                PageIndex: '1',
                PageCount: '100',
                Search_Text: val?.Search_Text
            }
        );
        setQuestions(res.data.Data.Data)
    }

    return (
        <>
            <Header />

            <Container className={style.commonQuestions}>
                <Row>
                    <Col>
                        <p className={style.mainTitle}>{t('common questions')}</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Formik
                            initialValues={{
                                Search_Text: ''
                            }}
                            // onSubmit={handleQuestions}
                        >
                            {({ setFieldValue, submitForm, errors, touched, values }) => (
                                <Form className='w-100'>
                                    <Row>
                                        <Col md={4}>
                                            <label className={style.label}>{t('Search the common questions')}</label>
                                            <Field 
                                                name="Search_Text" 
                                                type="text" 
                                                className={style.input} 
                                                onKeyUp={() => handleQuestions(values)}
                                            />
                                        </Col>
                                        <Col md={4} 
                                            className='d-flex justify-content-end align-items-center mt-2'
                                        >
                                            <NavLink
                                                className={[
                                                    'mainBtn primarymainBtn py-2 px-3',
                                                    style.askBtn
                                                ].join(' ')}
                                                to='/suggestions_and_complains'
                                            >
                                                {t('ask a question')}
                                            </NavLink>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>

                <Row className={style.questionsCont}>
                    {
                        questions && questions.map(q => (
                            <Col md={10} key={q.Question_Id}>
                                <Accordion activeKey={open}>
                                    <Card className={style.accCard}>
                                        <Card.Header className={style.accHeader}>
                                            <Accordion.Toggle 
                                                eventKey={q.Question_Id} 
                                                className={style.accBtn}
                                                onClick={() => setOpen(open == q.Question_Id ? null : q.Question_Id)}
                                            >
                                                {i18n.language == 'en' ? q.Question_EN : q.Question_AR}

                                                <span className={[
                                                    style.accArrow,
                                                    open == q.Question_Id ? style.upArrow : null
                                                ].join(' ')}>
                                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" xmlSpace="preserve"
                                                    >
                                                        <g>
                                                            <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                
                                            </Accordion.Toggle>
                                        </Card.Header>

                                        <Accordion.Collapse eventKey={q.Question_Id}>
                                            <Card.Body 
                                                dangerouslySetInnerHTML={
                                                    {__html: i18n.language == 'en' ? q.Question_Answer_EN : q.Question_Answer_AR}
                                                }
                                            >
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Col>
                        ))
                    }
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default CommonQuestions
