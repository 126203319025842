import React from 'react'
import { Container, Modal } from 'react-bootstrap'
import style from './PopUp.module.scss'
import { useTranslation } from 'react-i18next'

const PopUp = props => {

    const { t, i18n } = useTranslation('common')

    return (
        <Modal 
            show={props.show} 
            onHide={props.handleClose} 
            className={[
                style.modal,
                !props.smallModal ? style.fullModal: null
            ].join(' ')}
        >
            <Modal.Header 
                closeButton 
                className={style.modalHeader}
                style={{
                    'flexDirection': i18n.language === 'en' ? 'row' : 'row-reverse',
                }}
            >
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body 
                className='mb-4 mt-2'
                style={{
                    'direction': i18n.language === 'en' ? 'ltr' : 'rtl',
                }}
            >
                <Container fluid>{props.children}</Container>
            </Modal.Body>
        </Modal>
    )
}

export default PopUp
