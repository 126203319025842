import React, { useEffect, useState } from 'react'
import style from './DepartmentContactUs.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import DepartmentMap from './DepartmentMap/DepartmentMap'
import { useParams } from 'react-router-dom'
import Info from './Info/Info'
import axios from 'axios'

const DepartmentContactUs = props => {

    const { t } = useTranslation('common')
    const [servicesCenters, setServicesCenters] = useState([])
    const [loading, setLoading] = useState(false)
    let { municipalId } = useParams()

    useEffect(() => {
        const fetchServicesCenters = async() => {
            setLoading(true)
            const res =  await axios.post(
                `${process.env.REACT_APP_API_URL}/PORTAL/DEPARTMENTS/GET_ALL_DEPARTMENT_SERVICES_CENTERS`,
                {
                    "Department_Id": municipalId ? municipalId : 0
                }
            )
            setServicesCenters(res?.data.Data)
            setLoading(false)
        }
        fetchServicesCenters()
        console.log(servicesCenters);

    }, [municipalId])

    return (
        <>
            <Container fluid className={style.mapContainer}>
                <Row>
                    <Col className='text-center'>
                        <p className={style.title}>{t('get_in_touch')}</p>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col md={8} className='text-center'>
                        <p className={style.para}>{t('map_para')}</p>
                    </Col>
                </Row>
                <Row className={style.mapRow}>
                    <DepartmentMap 
                        servicesCenters={servicesCenters} 
                        muniLongitudes={props.muniLongitudes} 
                        muniLatitudes={props.muniLatitudes} 
                        name={props.name}
                    />
                </Row>
            </Container>

            <Container fluid='lg'>
                <div>
                    <Info servicesCenters={servicesCenters} />
                </div>
            </Container>
        </>
    )
}

export default DepartmentContactUs
