import React, { useState } from "react";
import style from "components/pages/ServiceDetails/AvailableMunicip/AvailableMunicip.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { departmentSelected } from "./serviceMunicipSlice";
import { useDispatch, useSelector } from "react-redux";

const ServiceMunicip = ({ municipalDetails, serviceDepartmentsList, handleHelp, children }) => {
  const { t, i18n } = useTranslation("common");
  const { departmentId } = useSelector((state) => state.service.serviceMunicip);
  const [showDetails, setShowDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={style.sideCard}>{departmentId ? children : <MunicipList serviceDepartmentsList={serviceDepartmentsList} />}</div>

      <div className={style.fixedDiv} onClick={() => setShowModal(true)}>
        {departmentId ? (
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>{t("Available_in")} +</span>
            {municipalDetails ? (
              <span
                className="mainBtn secondarymainBtn p-2"
                onClick={() => {
                  setShowDetails(true);
                  handleHelp(false);
                }}>{`${i18n.language === "en" ? municipalDetails.Department_Name_EN : municipalDetails.Department_Name_AR} +`}</span>
            ) : null}
          </div>
        ) : (
          <span>{t("Available_in")} +</span>
        )}
      </div>

      {showModal ? (
        <>
          <div className={style.Modal}>
            {departmentId ? null : <MunicipList />}
            {departmentId && !showDetails ? <MunicipList /> : null}
            {departmentId && showDetails ? children : null}

            <div className="d-flex justify-content-center mt-4 mb-3">
              <button
                className="mainBtn secondarymainBtn outlined smBtn"
                onClick={() => {
                  setShowModal(false);
                  setShowDetails(false);
                }}>
                {t("close")}
              </button>
            </div>
          </div>

          <div
            className={style.overlay}
            onClick={() => {
              setShowModal(false);
              setShowDetails(false);
            }}></div>
        </>
      ) : null}
    </>
  );
};

export default ServiceMunicip;

const MunicipList = ({ serviceDepartmentsList }) => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();
  return (
    <div className={style.Municipls}>
      <Row>
        <Col>
          <p className={style.title}>{t("Available_in")}</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <ul className={style.municipList}>
            {serviceDepartmentsList &&
              serviceDepartmentsList.map((item) => (
                <li key={item.Department_Id} onClick={() => dispatch(departmentSelected(item.Department_Id))}>
                  {i18n.language === "en" ? item.Department_Name_EN : item.Department_Name_AR}
                </li>
              ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
};
