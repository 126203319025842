import React, { useState, useEffect, useRef } from 'react'
import style from './HousingModels.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Footer from './../../UIComponents/Footer/Footer'
import Header from './../../UIComponents/Header/Header'
import { Formik, Form, Field } from "formik"
import Card from './../../UIComponents/Housing/Card'
import axios from 'axios'


const HousingModels = () => {

    const { t, i18n } = useTranslation('common')
    const [formVal, setFormVal] = useState()
    const [roomsNo, setRoomsNo] = useState('')
    const [floorsDrop, setFloorsDrop] = useState('')
    const [costDrop, setCostDrop] = useState('')
    const [bedroomsDrop, setBedroomsDrop] = useState('')
    const [areaDrop, setAreaDrop] = useState('')
    const [areaTypeDrop, setAreaTypeDrop] = useState('')
    const divRref = useRef();

    useEffect(() => {
        handleSearch()
        getFloors()
        getCost()
        getBedrooms()
        getArea()
        getAreaType()
    }, [])

    const handleSearch = (values) => {
        setFormVal(values)
    }

    const getFloors = async () => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/SYSTEM/DDL/GET_APPROVING_CHARTS_FLOORS`
        );
        setFloorsDrop(res.data.Data)
    }

    const getCost = async () => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/SYSTEM/DDL/GET_APPROVING_CHARTS_COST`
        );
        setCostDrop(res.data.Data)
    }

    const getBedrooms = async () => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/SYSTEM/DDL/GET_APPROVING_CHARTS_BEDROOMS`
        );
        setBedroomsDrop(res.data.Data)
    }

    const getArea = async () => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/SYSTEM/DDL/GET_APPROVING_CHARTS_AREA`
        );
        setAreaDrop(res.data.Data)
    }

    const getAreaType = async () => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/SYSTEM/DDL/GET_AREA_TYPES`
        );
        setAreaTypeDrop(res.data.Data)
    }

    return (
        <>
            <Header />

            <Container fluid>
                <Row
                    className={[
                        style.backWrap, 
                        style.housingModels
                    ].join(' ')}
                >
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md={10} sm={12}>

                                <Row className='flex-column'>
                                    <p className={style.title1}>{t('menu_home')}</p>
                                    <p className={style.title2}>{t('Housing Models')}</p>
                                </Row>
                                
                                <div className={style.searchCard}>
                                    <Formik
                                        initialValues={{
                                            From_Area: '',
                                            To_Area: '',
                                            Floor_No: '',
                                            Room_No: '',
                                            From_Cost: '',
                                            To_Cost: '',
                                            Area_Type_Id: '',
                                            PageIndex: '1',
                                            PageSize: '100'
                                        }}
                                        onSubmit={handleSearch}
                                    >
                                        {({ setFieldValue, submitForm, resetForm, values }) => (
                                            <Form>
                                                <Row className={style.fieldsRow}>
                                                    <Col lg={4} md={6} className="px-md-1">
                                                        <label className={style.label}>{t('space')}</label>
                                                        <Field 
                                                            as="select"
                                                            name="From_Area" 
                                                            type="text" 
                                                            className={style.input} 
                                                            onChange={e => {
                                                                setFieldValue('From_Area', e.target.value)
                                                                submitForm()
                                                            }} 
                                                        >
                                                            <option value="" defaultValue>{t('Minimum space')}</option>
                                                            {areaDrop && areaDrop.map((type) => (
                                                                <option key={type.Record_Id} value={type.Record_Id}>
                                                                    {i18n.language === "en" ? type.Record_Name_EN : type.Record_Name_AR}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    </Col>

                                                    <Col lg={4} md={6} className='d-flex align-items-end px-md-1'>
                                                        <Field 
                                                            as="select"
                                                            name="To_Area" 
                                                            type="text" 
                                                            className={style.input} 
                                                            onChange={e => {
                                                                setFieldValue('To_Area', e.target.value)
                                                                submitForm()
                                                            }} 
                                                        >
                                                            <option value="" defaultValue>{t('Maximum space')}</option>
                                                            {areaDrop && areaDrop.map((type) => (
                                                                <option key={type.Record_Id} value={type.Record_Id}>
                                                                    {i18n.language === "en" ? type.Record_Name_EN : type.Record_Name_AR}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    </Col>

                                                    <Col lg={4} md={12} className='d-flex align-items-end px-md-1'>
                                                        <Field 
                                                            as="select"
                                                            name="Area_Type_Id" 
                                                            type="text" 
                                                            className={style.input}
                                                            onChange={e => {
                                                                setFieldValue('Area_Type_Id', e.target.value)
                                                                submitForm()
                                                            }} 
                                                        >
                                                            <option value="" defaultValue>{t('area type')}</option>
                                                            {areaTypeDrop && areaTypeDrop.map((type) => (
                                                                <option key={type.Record_Id} value={type.Record_Id}>
                                                                    {i18n.language === "en" ? type.Record_Name_EN : type.Record_Name_AR}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg={4} md={6} className="px-md-1">
                                                        <label className={style.label}>{t('cost')}</label>
                                                        <Field 
                                                            as="select"
                                                            name="From_Cost" 
                                                            type="text" 
                                                            className={style.input}
                                                            onChange={e => {
                                                                setFieldValue('From_Cost', e.target.value)
                                                                submitForm()
                                                            }} 
                                                        >
                                                            <option value="" defaultValue>{t('Minimum price')}</option>
                                                            {costDrop && costDrop.map((type) => (
                                                                <option key={type.Record_Id} value={type.Record_Id}>
                                                                    {i18n.language === "en" ? type.Record_Name_EN : type.Record_Name_AR}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    </Col>
                                                    
                                                    <Col lg={4} md={6} className='d-flex align-items-end px-md-1'>
                                                        <Field 
                                                            as="select"
                                                            name="To_Cost" 
                                                            type="text" 
                                                            className={style.input} 
                                                            onChange={e => {
                                                                setFieldValue('To_Cost', e.target.value)
                                                                submitForm()
                                                            }}
                                                        >
                                                            <option value="" defaultValue>{t('Maximum price')}</option>
                                                            {costDrop && costDrop.map((type, i) => (
                                                                <option key={type.Record_Id} value={type.Record_Id}>
                                                                    {i18n.language === "en" ? type.Record_Name_EN : type.Record_Name_AR}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    </Col>
                                                    
                                                    <Col lg={4} md={12} className="px-md-1">
                                                        <label className={style.label}>{t('floors no')}</label>
                                                        <Field 
                                                            as="select"
                                                            name="Floor_No" 
                                                            type="text" 
                                                            className={style.input} 
                                                            onChange={e => {
                                                                setFieldValue('Floor_No', e.target.value)
                                                                submitForm()
                                                            }}
                                                        >
                                                            <option value="" defaultValue>{t('floors no')}</option>
                                                            {floorsDrop && floorsDrop.map((type, i) => (
                                                                <option key={i} value={type.Record_Id}>
                                                                    {i18n.language === "en" ? type.Record_Name_EN : type.Record_Name_AR}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                    </Col>
                                                </Row>

                                                <Row className='justify-content-between'>
                                                    <Col lg={6} md={12}>
                                                        <label className={style.label}>
                                                            <span className='px-2'>
                                                                <svg width="21px" height="13px" viewBox="0 0 21 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                    <title>bed</title>
                                                                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <g id="2" transform="translate(-1514.000000, -1798.000000)" fill="#58743A" fillRule="nonzero">
                                                                            <g id="Group-3" transform="translate(1197.000000, 1397.000000)">
                                                                                <g id="bed" transform="translate(317.000000, 401.000000)">
                                                                                    <path d="M2.39076749,3.92334558 C2.39076749,2.85585269 3.28242953,1.98733938 4.37847156,1.98733938 L8.84039103,1.98733938 C9.53323918,1.98733938 10.1441179,2.33464305 10.5,2.8596222 C10.8559256,2.3346007 11.4667608,1.98733938 12.159609,1.98733938 L16.6215719,1.98733938 C17.7175705,1.98733938 18.6092325,2.85585269 18.6092325,3.92334558 L18.6092325,4.01250098 L19.1068325,4.01250098 L19.1068325,2.12460904 C19.1068325,0.953094456 18.1282873,0 16.9254893,0 L4.0744672,0 C2.87166925,0 1.89312397,0.953094456 1.89312397,2.12460904 L1.89312397,4.01250098 L2.39072401,4.01250098 L2.39072401,3.92334558 L2.39076749,3.92334558 Z" id="Path"></path>
                                                                                    <path d="M20.1645642,4.91654938 L0.835392318,4.91654938 C0.37475462,4.91654938 4.013321e-14,5.28155707 4.013321e-14,5.73021412 L4.013321e-14,10.4537135 C4.013321e-14,10.9023705 0.37475462,11.2673782 0.835392318,11.2673782 L1.18314269,11.2673782 L0.772251949,12.3968775 C0.68662978,12.6321969 0.813127946,12.890557 1.05468769,12.9739099 C1.10595663,12.9915715 1.15826921,13 1.20971209,13 C1.40074215,13 1.57972683,12.8842039 1.64717223,12.6987776 L2.16790634,11.2673359 L18.8320937,11.2673359 L19.3528278,12.6987776 C19.4202732,12.8842039 19.5992144,13 19.7902879,13 C19.8417308,13 19.8940434,12.9915715 19.9453123,12.9739099 C20.1869155,12.8905146 20.3133702,12.6321546 20.2277481,12.3968775 L19.8168573,11.2673782 L20.1646077,11.2673782 C20.6252454,11.2673782 21,10.9023705 21,10.4537135 L21,5.73021412 C21,5.28155707 20.6252454,4.91654938 20.1645642,4.91654938 Z" id="Path"></path>
                                                                                    <path d="M12.159609,2.89143014 C11.5754301,2.89143014 11.1001379,3.35431816 11.1001379,3.92330323 L11.1001379,4.01245862 L17.6809995,4.01245862 L17.6809995,3.92330323 C17.6809995,3.35431816 17.2057508,2.89143014 16.6215719,2.89143014 L12.159609,2.89143014 Z" id="Path"></path>
                                                                                    <path d="M3.31900051,3.92334558 L3.31900051,4.01245862 L9.89986209,4.01245862 L9.89986209,3.92330323 C9.89986209,3.35431816 9.4246134,2.89143014 8.84039103,2.89143014 L4.37847156,2.89143014 C3.7942492,2.89143014 3.31900051,3.35436052 3.31900051,3.92334558 Z" id="Path"></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            {t('number of bedrooms')}
                                                        </label>
                                                        <div className={`${style.numbers} px-md-0`}>
                                                            {
                                                                bedroomsDrop && bedroomsDrop.map(n => (
                                                                    <span
                                                                        key={n.Record_Id}
                                                                        className={[
                                                                            style.number,
                                                                            roomsNo == n.Record_Id ? style.active : null
                                                                        ].join(' ')}
                                                                        onClick={() => {
                                                                            setRoomsNo(n.Record_Id)
                                                                            setFieldValue('Room_No', n.Record_Id)
                                                                            submitForm()
                                                                        }}
                                                                    >
                                                                        {i18n.language == 'ar' ? n.Record_Name_AR : n.Record_Name_EN}
                                                                    </span>
                                                                ))
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} md={5} xs={12} className='d-flex align-items-end px-lg-3 px-1 pt-lg-0 pt-md-5 pt-2'>
                                                        <button 
                                                            className='mainBtn primarymainBtn lgBtn w-100'
                                                            type='submit'
                                                            onClick={() => {
                                                                divRref.current.scrollIntoView({ behavior: 'smooth' });
                                                            }}
                                                        > 
                                                            {t('search')}
                                                        </button>
                                                    </Col>
                                                    <Col lg={3} md={7} xs={12} className='d-flex align-items-end px-lg-0 px-1 pt-lg-0 pt-md-5 pt-2'>
                                                        <button 
                                                            className='mainBtn secondarymainBtn outlined lgBtn w-100'
                                                            type='reset'
                                                            onClick={() => {
                                                                setRoomsNo(null)
                                                                setFieldValue('Room_No', null)
                                                                resetForm()
                                                                submitForm()
                                                                divRref.current.scrollIntoView({ behavior: 'smooth' });
                                                            }}
                                                        > 
                                                            {t('showAllResults')}
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Row className={style.cardsWrapper}>
                    <Container ref={divRref}>
                        <Card 
                            formVal={formVal} 
                            limited={false} 
                        />
                    </Container>
                </Row>
                
            </Container>

            <Footer />
        </>
    )
}

export default HousingModels
