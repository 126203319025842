import React from "react";

function Pdf() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 350 350">
      <g fill="none" fillRule="nonzero" stroke="none" strokeDasharray="none" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1" opacity="1">
        <path
          fill="#B83535"
          d="M11.58 54.882v31.965A3.153 3.153 0 0014.733 90h60.534a3.153 3.153 0 003.153-3.153V54.882c-22.347-7.001-44.628-7.044-66.84 0z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#E9E9E0" d="M78.42 54.882V18.345C69.386 13.658 63.133 7.61 60.075 0H14.733a3.153 3.153 0 00-3.153 3.153v51.729h66.84z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#D9D7CA" d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path
          fill="#F9F9F9"
          d="M32.116 62.679h-5.944a1.5 1.5 0 00-1.5 1.5v16.602a1.5 1.5 0 103 0v-5.248h4.444a4.343 4.343 0 004.338-4.338v-4.177a4.343 4.343 0 00-4.338-4.339zm1.338 8.515c0 .737-.6 1.338-1.338 1.338h-4.444v-6.854h4.444a1.34 1.34 0 011.338 1.339v4.177zM46.109 82.28h-5.652a1.5 1.5 0 01-1.5-1.5V64.179a1.5 1.5 0 011.5-1.5h5.652a4.635 4.635 0 014.63 4.63V77.65a4.635 4.635 0 01-4.63 4.63zm-4.152-3h4.152c.898 0 1.63-.731 1.63-1.63V67.309c0-.898-.731-1.63-1.63-1.63h-4.152V79.28zM63.828 62.679h-8.782a1.5 1.5 0 00-1.5 1.5V80.78a1.5 1.5 0 003 0v-6.801h4.251a1.5 1.5 0 000-3h-4.251v-5.301h7.282a1.5 1.5 0 000-2.999z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
      </g>
    </svg>
  );
}

export default Pdf;
