import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./Inputs.module.scss";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { useSelector } from "react-redux";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";

const Inputs = () => {
  const { t, i18n } = useTranslation("common");
  const [messagesTypes, setMessagesTypes] = useState([]);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [onSubmitLoad, setOnSubmitLoad] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType, setAlertType] = useState("");
  const { user, clientProfiles } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchMessagesTypes = async () => {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/SYSTEM/DDL/GET_CLIENT_MESSAGE_TYPES`);
      setMessagesTypes(res.data.Data.filter((type) => type.Record_Id !== 1));
    };
    fetchMessagesTypes();
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          message_type: "",
          message: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = `${t("field_required")}`;
          }
          if (!values.email) {
            errors.email = `${t("field_required")}`;
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = `${t("invalid_email")}`;
          }
          if (!values.phone) {
            errors.phone = `${t("field_required")}`;
          }
          // else if (!/^(?:\+971|00971|0)(?:2|3|4|6|7|9|50|52|54|55|56|58)[0-9]{7}$/i.test(values.phone)) {
          //   errors.phone = `${t("invalid_phone")}`;
          // }

          if (!values.message_type) {
            errors.message_type = `${t("field_required")}`;
          }
          if (!values.message) {
            errors.message = `${t("field_required")}`;
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setOnSubmitLoad(true);
          const res = axios
            .post(`${process.env.REACT_APP_API_URL}/PORTAL/CLIENTMESSAGES/Send_Client_Message`, {
              Client_Profile_Id: clientProfiles?.Client_Profile_Id,
              Login_Name: user?.Login_Name,
              User_Mail: user?.User_Mail,
              User_Phone: user?.User_Phone,
              Client_Message_Type_Id: values.message_type,
              Client_Message_Name: values.name,
              Client_Message_Phone: values.phone,
              Client_Message_Mail: values.email,
              Client_Message_Message: values.message,
            })
            .then((res) => {
              if (res.status === 200) {
                setAlertMsg(`${t("sent_succesfully")}`);
                setAlertType("success");
              } else {
                setAlertMsg(i18n.language === "en" ? res.Error_EN : res.Error_AR);
                setAlertType("danger");
              }
              setShowSuccessAlert(true);
            })
            .finally(() => {
              setOnSubmitLoad(false);
            });
          resetForm();
        }}>
        {({ submitForm, errors, touched }) => (
          <Form className={style.Form}>
            <Col md={6}>
              <Field name="name" type="text" placeholder={`${t("name")}`} />
              <span>{touched.name && errors.name && <div>{errors.name}</div>}</span>
            </Col>

            <Col md={6}>
              <Field name="email" type="email" placeholder={`${t("email")}`} />
              <span>{touched.email && errors.email && <div>{errors.email}</div>}</span>
            </Col>

            <Col md={6}>
              <Field name="phone" type="tel" placeholder={`${t("phone")}`} />
              <span>{touched.phone && errors.phone && <div>{errors.phone}</div>}</span>
            </Col>

            <Col md={6}>
              <Field as="select" name="message_type" className={style.select}>
                <option value="" disabled defaultValue>{`${t("message_type")}`}</option>
                {messagesTypes.map((type) => (
                  <option key={type.Record_Id} value={type.Record_Id}>
                    {i18n.language === "en" ? type.Record_Name_EN : type.Record_Name_AR}
                  </option>
                ))}
              </Field>
              <span>{touched.message_type && errors.message_type && <div>{errors.message_type}</div>}</span>
            </Col>

            <Col md={6}>
              <Field as="textarea" name="message" type="text" placeholder={`${t("message")}`} />
              <span>{touched.message && errors.message && <div>{errors.message}</div>}</span>
            </Col>

            <Col md={12}>
              <Alert show={showSuccessAlert} variant={alertType} className="mt-5 d-flex justify-content-between align-items-center" transition={true}>
                {alertMsg}

                <span className={style.dismiss} onClick={() => setShowSuccessAlert(false)}>
                  <svg height="311pt" viewBox="0 0 311 311.07733" width="311pt" xmlns="http://www.w3.org/2000/svg">
                    <path d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" />
                    <path d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
                  </svg>
                </span>
              </Alert>

              <button className="mainBtn primarymainBtn lgBtn" type="submit">
                {`${t("send")}`}
              </button>
            </Col>
          </Form>
        )}
      </Formik>
      {onSubmitLoad && <LoadingOverlay />}
    </>
  );
};

export default Inputs;

// 0509597011
