import React from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import inputStyles from "./input.module.css";

const InputField = ({ label, customInvalid, customValid, ...props }) => {
  const [field, meta] = useField(props);
  const { t } = useTranslation("auth");
  if (customValid) {
    return (
      <>
        <label className={inputStyles.wrapper}>
          {label}
          <input {...field} {...props} />
          <div className={inputStyles.correct}>{customValid}</div>
        </label>
      </>
    );
  }
  if (customInvalid) {
    return (
      <>
        <label className={inputStyles.wrapper}>
          {label}
          <input {...field} {...props} />
          <div className={inputStyles.error}>{customInvalid}</div>
        </label>
      </>
    );
  }
  return (
    <>
      <label className={inputStyles.wrapper}>
        {label}
        <input {...field} {...props} />
        {meta.touched && meta.error ? <div className={inputStyles.error}>{t(`${meta.error}`)}</div> : null}
      </label>
    </>
  );
};

export default InputField;
