import React from "react";

function Csv() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 350 350">
      <g fill="none" fillRule="nonzero" stroke="none" strokeDasharray="none" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1" opacity="1">
        <path
          fill="#B83535"
          d="M11.58 54.882v31.965A3.153 3.153 0 0014.733 90h60.534a3.153 3.153 0 003.153-3.153V54.882c-22.347-7.001-44.628-7.044-66.84 0z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#E9E9E0" d="M78.42 54.882V18.345C69.386 13.658 63.133 7.61 60.075 0H14.733a3.153 3.153 0 00-3.153 3.153v51.729h66.84z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#D9D7CA" d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path
          fill="#F9F9F9"
          d="M33.635 82.312h-5.947a4.34 4.34 0 01-4.336-4.336v-10.93a4.34 4.34 0 014.336-4.336h5.947a1.5 1.5 0 110 3h-5.947c-.737 0-1.336.6-1.336 1.336v10.93c0 .736.599 1.336 1.336 1.336h5.947a1.5 1.5 0 110 3zM46.959 82.312h-6.334a1.5 1.5 0 110-3h6.334a.95.95 0 00.948-.948V74.96a.95.95 0 00-.948-.948h-3.885a3.953 3.953 0 01-3.949-3.948v-3.403a3.953 3.953 0 013.949-3.949h4.312a1.5 1.5 0 010 3h-4.312a.95.95 0 00-.949.949v3.403a.95.95 0 00.949.948h3.885a3.952 3.952 0 013.948 3.948v3.404a3.953 3.953 0 01-3.948 3.948zM65.63 62.759a1.494 1.494 0 00-1.902.938L59.486 76.18l-4.24-12.484a1.5 1.5 0 00-2.84.964l5.66 16.664c.005.016.017.027.022.042.049.131.112.256.195.369l.008.014c.081.107.183.199.292.282.025.019.049.038.076.055.106.07.218.132.344.175l.013.002c.15.05.308.078.469.078s.319-.028.469-.078l.013-.002c.126-.043.239-.105.345-.176l.073-.053c.11-.083.212-.176.294-.284l.006-.01c.085-.114.147-.24.197-.373.006-.015.017-.026.022-.042l5.661-16.664a1.495 1.495 0 00-.935-1.9z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
      </g>
    </svg>
  );
}

export default Csv;
