import React, { useState, useEffect } from 'react'
import style from './Info.module.scss'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const Info = props => {

    const { t, i18n } = useTranslation('common')
    const [departmentData, setDepartmentData] = useState([])
    const [loading, setLoading] = useState(false)
    let { municipalId } = useParams()

    useEffect(() => {
        const fetchDepartmentData = async() => {
            setLoading(true)
            const res =  await axios.post(
                `${process.env.REACT_APP_API_URL}/PORTAL/DEPARTMENTS/GET_DEPARTMENT_INFO`,
                {
                    "Department_Id": municipalId ? municipalId : 0
                }
            )
            setDepartmentData(res.data.Data)
            setLoading(false)
        }
        fetchDepartmentData()
    }, [municipalId])

    return (
        <Row className={[
            departmentData.length > 0 || props.servicesCenters.length > 0 ? style.info : style.hideInfo
        ].join(' ')}> 
            <Col sm={6}>
                <Row className='align-items-center'>
                    {departmentData.Department_Logo_Url ?
                        <img className={style.log} src={departmentData.Department_Logo_Url} />
                    : null}
                    {departmentData.Department_Name_AR ?
                        <p className={style.mainTitle}>{i18n.language === 'en' ? departmentData.Department_Name_EN : departmentData.Department_Name_AR}</p>
                    : null}
                </Row>

                <Row className={style.MunicipalInfo}>
                    <Col 
                        className='d-flex flex-column align-items-start'
                        xs={12} 
                    >
                        {departmentData.Department_Telephone ?
                            <div>
                                <p>{t('phone_label')}:</p>
                                <span>{departmentData.Department_Telephone}</span>
                            </div>
                        : null}
                        
                        {departmentData.Department_Email ?
                            <div>
                                <p>{t('email_label')}:</p>
                                <span>{departmentData.Department_Email}</span>
                            </div>
                        : null}
                        
                        {departmentData.Department_Address_AR ?
                            <div>
                                <p>{t('address_label')}:</p>
                                <span>{i18n.language === 'en' ? departmentData.Department_Address_EN : departmentData.Department_Address_AR}</span>
                            </div>
                        : null}
                        
                    </Col>
                
                    <Col xs={12} >
                        <ul className={style.socialMedia}>
                            {departmentData.Department_Instagram ?
                                <li>
                                    <a 
                                        target="_blank"
                                        href={`./../../${departmentData.Department_Instagram}`}
                                    >
                                        <span>
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                <title>instagram-fill</title>
                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="0" transform="translate(-633.000000, -4036.000000)">
                                                        <g id="Group" transform="translate(482.000000, 4036.000000)">
                                                            <g id="instagram-fill" transform="translate(151.000000, 0.000000)">
                                                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                                                <path d="M12,2 C14.717,2 15.056,2.01 16.122,2.06 C17.187,2.11 17.912,2.277 18.55,2.525 C19.21,2.779 19.766,3.123 20.322,3.678 C20.8304937,4.17789596 21.2239536,4.78258896 21.475,5.45 C21.722,6.087 21.89,6.813 21.94,7.878 C21.987,8.944 22,9.283 22,12 C22,14.717 21.99,15.056 21.94,16.122 C21.89,17.187 21.722,17.912 21.475,18.55 C21.2246766,19.2177765 20.8311207,19.822617 20.322,20.322 C19.8219615,20.8303192 19.2173093,21.2237526 18.55,21.475 C17.913,21.722 17.187,21.89 16.122,21.94 C15.056,21.987 14.717,22 12,22 C9.283,22 8.944,21.99 7.878,21.94 C6.813,21.89 6.088,21.722 5.45,21.475 C4.78232618,21.2244734 4.17752722,20.8309445 3.678,20.322 C3.16940644,19.8221857 2.77593123,19.2174693 2.525,18.55 C2.277,17.913 2.11,17.187 2.06,16.122 C2.013,15.056 2,14.717 2,12 C2,9.283 2.01,8.944 2.06,7.878 C2.11,6.812 2.277,6.088 2.525,5.45 C2.7752361,4.7821794 3.16880364,4.17732106 3.678,3.678 C4.17767098,3.16923123 4.78242858,2.77572924 5.45,2.525 C6.088,2.277 6.812,2.11 7.878,2.06 C8.944,2.013 9.283,2 12,2 Z M12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 C14.7614237,17 17,14.7614237 17,12 C17,9.23857625 14.7614237,7 12,7 L12,7 Z M18.5,6.75 C18.5,6.05964406 17.9403559,5.5 17.25,5.5 C16.5596441,5.5 16,6.05964406 16,6.75 C16,7.44035594 16.5596441,8 17.25,8 C17.9403559,8 18.5,7.44035594 18.5,6.75 Z M12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 Z" id="Shape" fill="$white" fillRule="nonzero" opacity="0.823446584"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                </li>
                            :null}
                        
                            {departmentData.Department_Twitter ? 
                                <li>
                                    <a 
                                        target="_blank"
                                        href={departmentData.Department_Twitter} 
                                    >
                                        <span>
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                <title>twitter-fill</title>
                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="0" transform="translate(-520.000000, -4036.000000)">
                                                        <g id="Group" transform="translate(482.000000, 4036.000000)">
                                                            <g id="twitter-fill" transform="translate(38.000000, 0.000000)">
                                                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                                                <path d="M22.162,5.656 C21.398557,5.99369373 20.5889601,6.21547339 19.76,6.314 C20.6337508,5.79143332 21.2877055,4.96901423 21.6,4 C20.78,4.488 19.881,4.83 18.944,5.015 C17.6498495,3.63031951 15.5862776,3.28908903 13.9152663,4.18345292 C12.244255,5.07781681 11.3836288,6.98414575 11.818,8.829 C8.45502254,8.66044327 5.32174389,7.07199458 3.198,4.459 C2.82629012,5.09745032 2.63095514,5.82322672 2.632,6.562 C2.632,8.012 3.37,9.293 4.492,10.043 C3.82800881,10.0220962 3.1786324,9.84278055 2.598,9.52 L2.598,9.572 C2.59841319,11.5630358 4.00151442,13.2781232 5.953,13.673 C5.33661503,13.8400369 4.69030669,13.8646582 4.063,13.745 C4.61012698,15.4491831 6.18045142,16.6167778 7.97,16.65 C6.21794158,18.0260194 3.99074,18.6491034 1.779,18.382 C3.69069779,19.6114328 5.91609682,20.2641239 8.189,20.2620052 C15.882,20.2620052 20.089,13.889 20.089,8.362 C20.089,8.182 20.084,8 20.076,7.822 C20.8948641,7.23016766 21.6015987,6.49701939 22.163,5.657 L22.162,5.656 Z" id="Path" fill="$white" fillRule="nonzero" opacity="0.823446584"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                </li>
                            :null}

                            {departmentData.Department_Facebook ? 
                                <li>
                                    <a 
                                        target="_blank"
                                        href={departmentData.Department_Facebook}
                                    >
                                        <span>
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                <title>facebook-box-fill</title>
                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="0" transform="translate(-482.000000, -4036.000000)">
                                                        <g id="facebook-box-fill" transform="translate(482.000000, 4036.000000)">
                                                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path d="M15.402,21 L15.402,14.034 L17.735,14.034 L18.084,11.326 L15.402,11.326 L15.402,9.598 C15.402,8.814 15.62,8.279 16.744,8.279 L18.178,8.279 L18.178,5.857 C17.4837982,5.78334463 16.7860943,5.74762486 16.088,5.75 C14.021,5.75 12.606,7.012 12.606,9.33 L12.606,11.326 L10.268,11.326 L10.268,14.034 L12.606,14.034 L12.606,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,20 C21,20.5522847 20.5522847,21 20,21 L15.402,21 Z" id="Path" fill="$white" fillRule="nonzero" opacity="0.823446584"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                    </a>
                                </li>
                            :null}
                        </ul>
                    </Col>
                </Row>
            </Col>

            <Col sm={6} className='mt-0 mt-sm-4'> 
                {props.servicesCenters.length > 0 ?
                    <>
                        <Row>
                            <p className={style.title}>{t('services_centers')}</p>
                        </Row>

                        <Row className={style.serviceCenter}>
                            {props.servicesCenters.map(center => 
                                <Col 
                                    sm={12} 
                                    key={center.Department_Service_Center_Id}
                                    className='d-flex flex-column align-items-start mb-3'
                                >
                                    {center.Department_Service_Center_Name_AR ? 
                                        <div>
                                            <p>{t('service_center')}:</p>
                                            <span>{i18n.language === 'en' ? center.Department_Service_Center_Name_EN : center.Department_Service_Center_Name_AR}</span>
                                        </div>
                                    : null}

                                    {center.Department_Service_Center_Telephone ? 
                                        <div>
                                            <p>{t('phone_label')}:</p>
                                            <span>{center.Department_Service_Center_Telephone}</span>
                                        </div>
                                    : null}
                                    
                                    {center.Department_Service_Center_Email ? 
                                        <div>
                                            <p>{t('email_label')}:</p>
                                            <span>{center.Department_Service_Center_Email}</span>
                                        </div>
                                    : null}

                                    {center.Department_Service_Center_Address_AR ? 
                                        <div>
                                            <p>{t('address_label')}:</p>
                                            <span>{i18n.language === 'en' ? center.Department_Service_Center_Address_EN : center.Department_Service_Center_Address_AR}</span>
                                        </div>
                                    :null}
                                </Col>
                            )}
                        </Row>
                    </>
                : null}
            </Col>
        </Row>
    )
}

export default Info