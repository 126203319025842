import React from "react";

function Jpg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 350 350">
      <g fill="none" fillRule="nonzero" stroke="none" strokeDasharray="none" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1" opacity="1">
        <path
          fill="#B83535"
          d="M11.58 54.882v31.965A3.153 3.153 0 0014.733 90h60.534a3.153 3.153 0 003.153-3.153V54.882c-22.347-7.001-44.628-7.044-66.84 0z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#E9E9E0" d="M78.42 54.882V18.345C69.386 13.658 63.133 7.61 60.075 0H14.733a3.153 3.153 0 00-3.153 3.153v51.729h66.84z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#D9D7CA" d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path
          fill="#F9F9F9"
          d="M46.58 62.719h-5.944a1.5 1.5 0 00-1.5 1.5V80.82a1.5 1.5 0 103 0v-5.247h4.444a4.343 4.343 0 004.338-4.339v-4.177a4.343 4.343 0 00-4.338-4.338zm1.338 8.514a1.34 1.34 0 01-1.338 1.339h-4.444v-6.854h4.444a1.34 1.34 0 011.338 1.338v4.177zM31.782 82.319H27.7a3.855 3.855 0 01-3.85-3.851v-2.526a1.5 1.5 0 113 0v2.526c0 .469.381.851.85.851h4.082a.852.852 0 00.851-.851v-14.25a1.5 1.5 0 113 0v14.25a3.855 3.855 0 01-3.851 3.851zM62.555 82.351H56.73a3.599 3.599 0 01-3.595-3.595V66.305a3.602 3.602 0 013.579-3.595l5.482-.023h.007a1.5 1.5 0 01.007 3l-5.482.023a.597.597 0 00-.593.595v12.451c0 .328.267.595.595.595h5.824a.597.597 0 00.596-.595v-5.099a.597.597 0 00-.596-.595h-2.386a1.5 1.5 0 010-3h2.386a3.6 3.6 0 013.596 3.595v5.099a3.6 3.6 0 01-3.595 3.595z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
      </g>
    </svg>
  );
}

export default Jpg;
