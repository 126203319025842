import React from "react";

function Text() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 350 350">
      <g fill="none" fillRule="nonzero" stroke="none" strokeDasharray="none" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1" opacity="1">
        <path
          fill="#B83535"
          d="M11.58 54.882v31.965A3.153 3.153 0 0014.733 90h60.534a3.153 3.153 0 003.153-3.153V54.882c-22.347-7.001-44.628-7.044-66.84 0z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#E9E9E0" d="M78.42 54.882V18.345C69.386 13.658 63.133 7.61 60.075 0H14.733a3.153 3.153 0 00-3.153 3.153v51.729h66.84z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#D9D7CA" d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path
          fill="#F9F9F9"
          d="M34.604 62.687h-8.816a1.5 1.5 0 100 3h2.908v15.164a1.5 1.5 0 103 0V65.687h2.908a1.5 1.5 0 100-3zM46.69 72.519l4.021-7.599a1.5 1.5 0 00-2.652-1.402l-3.065 5.794-3.066-5.794a1.5 1.5 0 00-2.651 1.402l4.02 7.599-4.02 7.599a1.5 1.5 0 102.651 1.402l3.066-5.794 3.065 5.794a1.5 1.5 0 102.652-1.402l-4.021-7.599zM64.212 62.687h-8.815a1.5 1.5 0 000 3h2.908v15.164a1.5 1.5 0 003 0V65.687h2.907a1.5 1.5 0 000-3z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
      </g>
    </svg>
  );
}

export default Text;
