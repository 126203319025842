import React from 'react'
import InspectionDatesGrid from './InspectionDatesGrid'

const InspectionDates = props => {

    return (
        <>
            <InspectionDatesGrid dataIsReady={props.dataIsReady} />
        </>
    )
}

export default React.memo(InspectionDates)
