import React from 'react'
import style from './ViolationsSuspensions.module.scss'
import { useTranslation } from 'react-i18next'
import Grid from 'components/UIComponents/Grid'
import { Column } from 'devextreme-react/data-grid'
import { Container, Row, Col } from 'react-bootstrap'
import formStyle from "../../../features/user/formStyle.module.scss";

const ViolationsGrid = props => {

    const { t, i18n } = useTranslation('common')

    let violationHolder = "Violation_Holder_Name_EN",
    violationType = "Violation_Type_Name_EN";

    if (i18n.language === "ar") {
        violationHolder = "Violation_Holder_Name_AR";
        violationType = "Violation_Type_Name_AR";
    }

    return (
        <Container>
            <Row className='justify-content-center'>
                <p className={style.gridTitle}>{t("violations")}</p>
            </Row>

            <Row>
                <Col>
                    <Grid 
                    sourceData={props.violations} 
                    addEnabled={false} 
                    editEnabled={false} 
                    deleteEnabled={false} 
                    viewEnabled={false}
                    onView={({ data }) => {}} 
                    onDelete={({ data }) => {}}
                    >
                        <Column caption={t('violation_no')} alignment="center" dataField="Violation_Id" dataType="string" width={150} />
                        <Column caption={t('violation_date')} alignment="center" dataField="Violation_Date" dataType="date" width={150} format="yyyy-MM-dd" />
                        <Column caption={t('violation_name')} alignment="center" dataField={violationHolder} dataType="string" width={150} />
                        <Column caption={t('violation_type')} alignment="center" dataField={violationType} dataType="string" width={150} />
                        {/* <Column caption={t('violation_state')} alignment="center" dataField="" dataType="string" /> */}
                        {/* <Column caption={t('violation_municipality')} alignment="center" dataField="" dataType="string" /> */}
                    </Grid>
                </Col>
            </Row>
            <Row className='mt-3 d-sm-block d-md-none'>
                <Col className='d-flex justify-content-center'>
                    <button onClick={() => props.toggleSidebar(false)} className="mainBtn smBtn primarymainBtn w-100">{t("close")}</button>
                </Col>
            </Row>
        </Container>
    )
}

export default ViolationsGrid