import { useEffect, useState, useCallback } from "react";

/**
 * @param {React.node} ref reference to the element root
 * @param {boolean} initialState [default: false]
 */
const useDetectOutsideClick = (ref, initialState = false, buttons = [1]) => {
  const [isActive, setIsActive] = useState(initialState);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target) && buttons.includes(event.buttons)) {
        setIsActive(false);
      }
    },
    [ref, setIsActive, buttons]
  );

  useEffect(() => {
    if (!isActive) return;
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isActive, handleClickOutside]);

  return [isActive, setIsActive];
};

export default useDetectOutsideClick;
