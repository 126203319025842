import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchServiceRequestInfo, fetchServiceRequestDraft } from "./serviceRequestSlice";
import { loadSession, saveSession, removeSession } from "utils";

const initialState = {
  Active_Status_Id: 0,
  Department_Address_AR: "",
  Department_Address_EN: "",
  Department_Code: "",
  Department_Description_AR: "",
  Department_Description_EN: "",
  Department_Email: "",
  Department_Facebook: "",
  Department_Financial_Code: "",
  Department_Id: 0,
  Department_Instagram: "",
  Department_Logo_Url: "",
  Department_Name_AR: "",
  Department_Name_EN: "",
  Department_No: 0,
  Department_Telephone: "",
  Department_Twitter: "",
  Department_Website: "",
  Department_latitudes: 0,
};
const municipal = loadSession("Municipal");

const slice = createSlice({
  name: "serviceMunicip",
  initialState: {
    loading: "idle",
    departmentData: municipal ? municipal : initialState,
    departmentId: municipal?.Department_Id,
    error: null,
  },
  reducers: {
    departmentSelected: (state, { payload }) => {
      state.departmentId = payload;
      state.departmentData = initialState;
    },
    departmentDataSelected: (state, { payload }) => {
      saveSession(payload, "Municipal");
      state.departmentData = payload;
      state.departmentId = payload?.Department_Id;
    },

    resetDepartment: (state) => {
      state.loading = "idle";
      state.departmentId = null;
      state.departmentData = initialState;
      removeSession("Municipal");
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(fetchServiceRequestInfo.fulfilled, fetchServiceRequestDraft.fulfilled), (state, { payload }) => {
      state.departmentId = payload?.Data?.Service_Data?.Department_Id;
    });
  },
});
export default slice.reducer;

export const { departmentSelected, departmentDataSelected, resetDepartment } = slice.actions;
