import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import style from "./Request.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import {
  saveAdditionalRequestInputNotes,
  submitAdditionalRequest,
  saveAdditionalRequestAttachmentData,
  deleteAdditionalRequestAttachment,
  downloadAdditionalRequestAttachment,
  downloadAttachment,
} from "api/serviceAPI";
import { setError } from "./updateRequestSlice";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faDownload,
  faUpload,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  Attachment,
  Csv,
  Doc,
  Excel,
  File,
  Jpg,
  Mp3,
  Mp4,
  Pdf,
  Png,
  Text,
  Zip,
} from "components/UIComponents/FileIcons";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import ProgressBar from "../../components/UIComponents/ProgressBar/ProgressBar";
// import { Accordion, Card } from 'react-bootstrap'

const RequestAdd = () => {
  const { t, i18n } = useTranslation("common");
  const {
    serviceData,
    requestData,
    requestId,
    requiredChangesAttachments,
    requiredChangesNotes,
    currentTaskData,
    loading,
    oldRequiredChangesList
  } = useSelector((state) => state.service.updateRequest);
  const { clientProfiles } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [highlightTerms, setHighlightTerms] = useState(false);
  const [isEllipsis, setIsEllipsis] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const termsRef = useRef();

  useEffect(() => {
    if (termsRef && highlightTerms && !acceptedTerms) {
      termsRef.current.style.transform = "scale(1.01)";
      termsRef.current.style.textDecoration = "underline";
    } else {
      termsRef.current.style.transform = "";
      termsRef.current.style.textDecoration = "";
    }
  }, [termsRef, highlightTerms, acceptedTerms]);

  const handleTerms = () => {
    setAcceptedTerms(Number(!acceptedTerms));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsSubmiting(true);
    submitAdditionalRequest(
      {
        Task_Id: currentTaskData?.Task_Id,
        Client_Profile_Id: clientProfiles.Client_Profile_Id,
        Request_Id: requestId,
      },
      source
    )
      .then((response) => {
        if (response.Status) {
          history.push(`/dashboard`);
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => {
        setIsSubmiting(false);
      });
    return () => {
      source.cancel();
    };
  };

  const download = async (f) => {
    await downloadAttachment({
      File_Code: f.File_Code,
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const handleSave = () => {
    history.push(`/dashboard`);
  };

  const handleToggleEllipsis = () => {
    setIsEllipsis(!isEllipsis);
  };

  console.log(
    serviceData,
    requestData,
    requestId,
    requiredChangesAttachments,
    requiredChangesNotes,
    currentTaskData,
    loading,
    oldRequiredChangesList
  )

  return (
    <>
      <Row className="align-items-center position-relative">
        <Col xs={6} className="d-flex justify-content-end mb-2">
          <button className="mainBtn primarymainBtn smBtn" onClick={handleSave}>
            {t("save")}
          </button>
        </Col>
      </Row>

      <Row className="align-items-center position-relative">
        <Col xs={12} className={style.detailsContent}>
          <Row>
            <p className={style.details}>{t("Request Number")}</p>
            <p className={style.details}>{requestData?.Request_No}</p>
          </Row>
          <Row>
            <p className={style.details}>{t("Request Date")}</p>
            <p className={style.details}>
              {requestData?.Request_Date &&
                moment(requestData.Request_Date)
                  .lang("en")
                  .format("YYYY-MM-DD")}
            </p>
          </Row>
          <Row className="justify-content-between">
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("service_name")}:</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Service_Name_AR
                    : serviceData?.Service_Name_EN}
                </p>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("service_code")}:</p>
                <p className={style.details}>{serviceData?.Service_Code}</p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Service Version")}</p>
                <p className={style.details}>
                  {serviceData?.Department_Service_Version_Name}
                </p>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Service Category")}</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Service_Category_Name_AR
                    : serviceData?.Service_Category_Name_EN}
                </p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("department_name")}:</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Department_Name_AR
                    : serviceData?.Department_Name_EN}
                </p>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Section Name")}</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Department_Section_Name_AR
                    : serviceData?.Department_Section_Name_EN}
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      {oldRequiredChangesList?.length > 0 ?
        <Row>
          <Col xl={12} className={style.step}>
            <Accordion>
              <Accordion.Toggle
                className={style.accBtn}
                eventKey='0'
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className={[style.stepHeader, 'd-flex justify-content-between align-items-baseline'].join(' ')}>
                  <div className='d-flex'>
                    <span className={style.number}>1</span>
                    <p className={style.stepTitle}>
                      {t("previous_modifications")}
                    </p>
                  </div>
                  <span className={[style.accArrow, isOpen ? style.upArrow : null].join(' ')}>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" xmlSpace="preserve"
                    >
                      <g>
                        <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                                c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                                c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/>
                      </g>
                    </svg>
                  </span>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='0'>
                <div className={[style.stepContent, 'py-2'].join(' ')}>
                  {oldRequiredChangesList?.map(x => (
                    <>
                      {loading !== "pending" ? (
                        <Row>
                          {x.Required_Changes_Date && (
                            <Col xs={12} className='d-flex mt-2'>
                              <p className={[style.details, 'mb-1'].join(' ')}>{t("inDate")}:</p>
                              <p className={[style.details, 'mb-1'].join(' ')}>
                                {x.Required_Changes_Date &&
                                  moment(x.Required_Changes_Date)
                                    .lang("en")
                                    .format("YYYY-MM-DD")}
                              </p>
                            </Col>
                          )}

                          {x.Required_Changes_Notes && (
                            <Col xs={12} className='d-flex'>
                              <p className={[style.details, 'mb-1'].join(' ')}>{x.Required_Changes_Notes}</p>
                            </Col>
                          )}

                          {x.Required_Changes_Attachments?.length > 0 && (
                            <Col xs={12} className='d-flex'>
                              <div className={[style.files, style.oldRequestFiles, 'w-100'].join(' ')}>
                                {x.Required_Changes_Attachments.map((uploadedFile) => (
                                  <div
                                    key={uploadedFile.File_Code}
                                    className="d-flex justify-content-between mb-1"
                                  >
                                    <div className="d-flex">
                                      {getFileIcon(uploadedFile.File_Content)}
                                      <span
                                        onClick={handleToggleEllipsis}
                                        className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                                          } mx-1`}
                                      >
                                        {uploadedFile.File_Name}
                                      </span>
                                      <span className={`${style.fileContent} mx-1`}>
                                        {uploadedFile.File_Size}
                                      </span>
                                    </div>

                                    <div className="d-flex">
                                      <FontAwesomeIcon
                                        className="mx-1"
                                        size="1x"
                                        color={"#e9e9e0"}
                                        icon={faDownload}
                                        onClick={() => download(uploadedFile)}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Col>
                          )}
                        </Row>
                      ) : (<div>{t("Loading")}</div>)}
                    </>
                  ))}
                </div>
              </Accordion.Collapse>
            </Accordion>
          </Col >
        </Row >
        : null}

      <Row>
        <Col xl={12} className={style.step}>
          <div className={style.stepHeader}>
            <span className={style.number}>{oldRequiredChangesList?.length > 0 ? 2 : 1}</span>
            <p className={style.stepTitle}>
              {t("Modifications and Required Data")}
            </p>
          </div>
          <div className={style.stepContent}>
            {loading !== "pending" ? (
              <Row>
                <Col xs={12}>
                  <Form.Label className={style.label}>{t("notes")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    defaultValue={requiredChangesNotes}
                    disabled
                  />
                </Col>
                <Col xs={12}>
                  <Form.Label className={style.label}>
                    {t("attachments")}
                  </Form.Label>
                  <div className={style.fileContainer}>
                    <Form.Label className={[style.fileLabel, 'py-0'].join(' ')}>
                      يرجى تحميل المرفقات المعادة
                    </Form.Label>

                    {requiredChangesAttachments?.length > 0 && (
                      <div className={style.files}>
                        {requiredChangesAttachments.map((uploadedFile) => (
                          <div
                            key={uploadedFile.File_Code}
                            className="d-flex justify-content-between mb-1"
                          >
                            <div className="d-flex">
                              {getFileIcon(uploadedFile.File_Content)}
                              <span
                                onClick={handleToggleEllipsis}
                                className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                                  } mx-1`}
                              >
                                {uploadedFile.File_Name}
                              </span>
                              <span className={`${style.fileContent} mx-1`}>
                                {uploadedFile.File_Size}
                              </span>
                            </div>

                            <div className="d-flex">
                              <FontAwesomeIcon
                                className="mx-1"
                                size="1x"
                                color={"#1e90ff"}
                                icon={faDownload}
                                onClick={() => download(uploadedFile)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              <div>{t("Loading")}</div>
            )}
          </div>
        </Col>
      </Row>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xl={12} className={style.step}>
            <div className={style.stepHeader}>
              <span className={style.number}>{oldRequiredChangesList?.length > 0 ? 3 : 2}</span>
              <p className={style.stepTitle}>البيانات المضافة للطلب</p>
            </div>
            <div className={style.stepContent}>
              <div className={style.addRequestNote}>{t('add_request_note')}</div>
              {loading !== "pending" ? (
                <Row>
                  <Col xs={12}>
                    <Form.Label className={style.label}>
                      {t("notes")}
                    </Form.Label>
                    <Textarea defaultValue={currentTaskData?.Task_Notes} />
                  </Col>
                  <AddedFiles
                    defaultValue={currentTaskData?.Task_Attachments}
                  />
                </Row>
              ) : (
                <div>{t("Loading")}</div>
              )}
            </div>
          </Col>
          <Col xl={12} className={style.step}>
            <div className={style.terms}>
              <input
                className={style.checkBox}
                type="checkbox"
                defaultChecked={acceptedTerms}
                onChange={handleTerms}
              />
              <p ref={termsRef}>{t("terms and conditions")}</p>
            </div>
            <div
              className={style.terms}
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "ar"
                    ? serviceData?.Service_Rules_AR
                    : serviceData?.Service_Rules_EN,
              }}
            ></div>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="submit"
              className="mainBtn primarymainBtn smBtn"
              disabled={!acceptedTerms}
              onPointerEnter={() => setHighlightTerms(true)}
              onPointerLeave={() => setHighlightTerms(false)}
              style={{ cursor: acceptedTerms ? "pointer" : "not-allowed" }}
            >
              {t("submit")}
            </button>
          </Col>
        </Row>
      </Form>

      {isSubmiting && <LoadingOverlay />}
    </>
  );
};

export default RequestAdd;

const Textarea = ({ defaultValue }) => {
  const { clientProfiles } = useSelector((state) => state.user);
  const { requestId, currentTaskData } = useSelector(
    (state) => state.service.updateRequest
  );
  const dispatch = useDispatch();
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const { t, i18n } = useTranslation("common");
  const [validationError, setValidationError] = useState(false);

  const onKeyPress = (e) => {
    var ew = e.which;
    if (ew != 8) {
      if ((ew < 48 || ew > 57) && (ew < 0x0600 || ew > 0x06ff) && ew != 32) {
        setValidationError(true);
        e.preventDefault();
        return false;
      }
    }
    if (validationError) {
      setValidationError(false);
    }
    return true;
  };

  const handleChangeWithDebounce = debounce(async (e) => {
    if (e.target.value) {
      await saveAdditionalRequestInputNotes({
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        Request_Id: requestId,
        Task_Notes: e.target.value,
        Task_Id: currentTaskData?.Task_Id,
      }).catch((err) => {
        dispatch(setError(err));
      });
      setValue(e.target.value);
    }
  }, 500);

  return (
    <>
      <Form.Control
        name={"notes"}
        defaultValue={value}
        onKeyPress={onKeyPress}
        onChange={handleChangeWithDebounce}
        required={true}
        as="textarea"
        rows={3}
      />
      {validationError && (
        <Form.Text className={style.onError}>
          {t("Write Arabic only")}
        </Form.Text>
      )}
    </>
  );
};

const AddedFiles = ({ defaultValue }) => {
  const { t } = useTranslation("common");
  const { requestId, currentTaskData } = useSelector(
    (state) => state.service.updateRequest
  );
  const [uploadedFiles, setUploadedFiles] = useState(
    defaultValue ? defaultValue : []
  );
  const { clientProfiles } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [isEllipsis, setIsEllipsis] = useState(true);

  const onChange = async (e) => {
    var files = e.target.files[0];
    if (files) {
      const data = new FormData();
      data.append(
        "PARAMETERS",
        JSON.stringify({
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Id: requestId,
          Task_Id: currentTaskData?.Task_Id,
        })
      );
      setIsUploading(true);
      data.append("Attached_File", e.target.files[0]);
      const response = await saveAdditionalRequestAttachmentData(
        data,
        handleProgress
      )
        .catch((err) => {
          dispatch(setError(err));
        })
        .finally(() => {
          setIsUploading(false);
          setProgressValue(0);
        });
      if (response?.Status) {
        setUploadedFiles((prev) => [...prev, response?.Data]);
      }
      e.target.value = null;
    }
  };

  const removeFile = async (f) => {
    const response = await deleteAdditionalRequestAttachment({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Id: requestId,
      Task_Id: currentTaskData?.Task_Id,
      Service_Detail_Id: f.Service_Detail_Id,
      File_Code: f.File_Code,
    });
    if (response?.Status) {
      setUploadedFiles((prev) => prev.filter((x) => x !== f));
    }
  };

  const download = async (f) => {
    await downloadAdditionalRequestAttachment({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Id: requestId,
      Task_Id: currentTaskData?.Task_Id,
      Service_Detail_Id: f.Service_Detail_Id,
      File_Code: f.File_Code,
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const handleProgress = (progress) => {
    var progressValue = (progress.loaded / progress.total) * 100;
    setProgressValue(Math.trunc(progressValue));
  };

  const handleToggleEllipsis = () => {
    setIsEllipsis(!isEllipsis);
  };

  return (
    <>
      <Col xs={12}>
        <Form.Label className={style.label}>{t("attachments")}</Form.Label>
        {isUploading && <ProgressBar value={progressValue} color="#58743A" />}
        <div className={style.fileContainer}>
          <div className={style.fileLabel}>
            <FontAwesomeIcon color={"#d3d3d3"} icon={faUpload} />
          </div>
          <Form.Control
            className={style.file}
            type="file"
            onChange={onChange}
          // required={!uploadedFiles?.length}
          />
          {uploadedFiles?.length > 0 && (
            <div className={style.files}>
              {uploadedFiles.map((uploadedFile) => (
                <div
                  key={uploadedFile.File_Code}
                  className="d-flex justify-content-between mb-1"
                >
                  <div className="d-flex">
                    {getFileIcon(uploadedFile.File_Content)}
                    <span
                      onClick={handleToggleEllipsis}
                      className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                        } mx-1`}
                    >
                      {uploadedFile.File_Name}
                    </span>
                    <span className={`${style.fileContent} mx-1`}>
                      {uploadedFile.File_Size}
                    </span>
                  </div>

                  <div className="d-flex">
                    <FontAwesomeIcon
                      className="mx-1"
                      size="1x"
                      color={"#1e90ff"}
                      icon={faDownload}
                      onClick={() => download(uploadedFile)}
                    />
                    <FontAwesomeIcon
                      className="mx-1"
                      size="1x"
                      color={"#ff6347"}
                      icon={faTrashAlt}
                      onClick={() => removeFile(uploadedFile)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Col>

      {/* {isUploading && <LoadingOverlay />} */}
    </>
  );
};

const getFileIcon = (fileType) => {
  return (
    {
      "image/jpeg": <Jpg />,
      "image/png": <Png />,
      "video/mp4": <Mp4 />,
      "application/pdf": <Pdf />,
      "application/vnd.ms-powerpoint": <Doc />,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        <Doc />,
      "application/msword": <Doc />,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        <Doc />,
      "audio/mpeg": <Mp3 />,
      "application/zip": <Zip />,
      "text/csv": <Csv />,
      "text/plain": <Text />,
    }[fileType] ?? <Attachment />
  );
};
