import React, { useState, useEffect } from 'react'
import DuePaymentsGrid from './DuePaymentsGrid'
import PopUp from '../../../UIComponents/PopUp/PopUp'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import style from './../form.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { useSelector } from 'react-redux'
import axios from 'axios'

const DuePayments = props => {

    const { t, i18n } = useTranslation('common')
    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()
    const [paymentLink, setPaymentLink] = useState('')
    const [invoiceData, setInvoiceData] = useState()
    const [clickFix, setClickFix] = useState(false)
    const { user, clientProfiles } = useSelector((state) => state.user);

    const handleClose = () => setShow(false)
    const handleShow = data => {
        setModalData(data)
        setClickFix(!clickFix)
    }

    useEffect(() => {
        if (show && modalData) {
            const res = axios.post(
                `${process.env.REACT_APP_API_URL}PAYMENT/TAHSEEL/PREPARING_PAYMENT`,
                {
                    'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                    'Invoice_Id': modalData?.Invoice_Id,
                    'Lang': i18n.language === 'EN' ? 'EN' : 'AR'
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`
                    }
                }
            ).then(res => {
                setPaymentLink(res.data.Data)
            })
        }
    }, [show, modalData])

    useEffect(() => {
        if (modalData?.Invoice_Id) {
            const res = axios.post(
                `${process.env.REACT_APP_API_URL}/PORTAL/PAYMENTS/INVOICES/GET_ClIENTS_INVOICE_INFO`,
                {
                    'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                    'Invoice_id': modalData?.Invoice_Id
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`
                    }
                }
            ).then(res => {
                setInvoiceData(res.data.Data)
                setShow(true)
            })
        }
    }, [clickFix])

    const handleFileDownload = code => {
        const res = axios.post(
            `${process.env.REACT_APP_API_URL}/PORTAL/PAYMENTS/INVOICES/DOWNLOAD_ClIENTS_INVOICE_INFO`,
            {
                'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                'Invoice_id': modalData?.Invoice_Id,
                'File_Code': code
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`
                }
            }
        ).then(res => {
            let a = document.createElement("a"); // Create <a>
            a.href = "data:image/png;base64," + res.data.Data.File_Bytes; // Base64 
            a.download = res.data.Data.File_Name; // File name 
            a.click(); // Download file
        })
    }

    return (
        <>
            <DuePaymentsGrid
                handleShow={handleShow} dataIsReady={props.dataIsReady}
            />

            {modalData ?
                <PopUp
                    show={show}
                    handleClose={handleClose}
                    title={t('due_payment_requests')}
                >
                    <Container>
                        <Formik
                            initialValues={{
                                request_no: modalData?.Request_No,
                                invoice_date: moment(modalData?.Invoice_Date).lang("en").format('YYYY-MM-DD'),
                                department_name: i18n.language === 'en' ? modalData?.Department_Name_EN : modalData?.Department_Name_AR,
                                client_name: i18n.language === 'en' ? modalData?.Client_Name_EN : modalData?.Client_Name_AR,
                                invoice_id: modalData?.Invoice_Id,
                                service_name: i18n.language === 'en' ? modalData?.Service_Name_EN : modalData?.Service_Name_AR,
                                invoice_no: modalData?.Invoice_No,
                                invoice_amount: modalData?.Invoice_Value,
                            }}
                        >
                            <Form className={style.dashboardData}>
                                <Row
                                    style={{ 'textAlign': i18n.language === 'ar' ? 'start' : null }}
                                >
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_no')}</label>
                                        <Field className={style.dashboardField} name="request_no" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('invoice_date')}</label>
                                        <Field className={style.dashboardField} name="invoice_date" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('department_name')}</label>
                                        <Field className={style.dashboardField} name="department_name" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('client_name')}</label>
                                        <Field className={style.dashboardField} name="client_name" type="text" readOnly />
                                    </Col>

                                    <Col sm={12}>
                                        <Field name="service_name" type="text" as="textarea" className={['text-center title mainName', style.dashboardField].join(' ')} readOnly />
                                    </Col>

                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('invoice_serial_no')}</label>
                                        <Field className={style.dashboardField} name="invoice_id" type="text" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('invoice_no')}</label>
                                        <Field className={style.dashboardField} name="invoice_no" type="text" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('invoice_amount')}</label>
                                        <Field className={style.dashboardField} name="invoice_amount" type="text" readOnly />
                                    </Col>

                                    {invoiceData?.Invoice_File_Code ?
                                        <Col md={4} sm={6} className='mb-2'>
                                            <p className={style.name}>{t('attachments')}</p>
                                            <div className={style.file}>
                                                <FontAwesomeIcon className={style.fileSvg} icon={faFile} />
                                                <div>
                                                    <p>{invoiceData?.Invoice_File_Code_Info?.File_Name}</p>
                                                    <p>{invoiceData?.Invoice_File_Code_Info?.File_Size}</p>
                                                </div>
                                                <span
                                                    className={[
                                                        style.download,
                                                        i18n.language === 'en' ? style.downloadEN : style.downloadAR
                                                    ].join(' ')}
                                                    onClick={() => handleFileDownload(invoiceData?.Invoice_File_Code)}
                                                >
                                                    <FontAwesomeIcon size="lg" icon={faDownload} />
                                                </span>
                                            </div>
                                        </Col>
                                        : null}
                                </Row>

                                <br />
                                <br />

                                <Row className='mt-4'>
                                    <Col>
                                        <a
                                            className='mainBtn lgBtn primarymainBtn'
                                            href={paymentLink}
                                        >
                                            {t('payment_of_fees')}
                                        </a>
                                    </Col>
                                </Row>
                            </Form>
                        </Formik>
                    </Container>
                </PopUp>
                : null}
        </>

    )
}

export default React.memo(DuePayments)
