import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import style from "./DyanmicField.module.scss";
import { Col, Form } from "react-bootstrap";
import debounce from "lodash.debounce";
import { getServicesLookupsValues } from "api/dropdownsAPI";
import { SelectBox } from "devextreme-react/select-box";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styleNew from "./NewDyanmicField.module.scss";
import arrowDownDropDown from "./iconsFolder/arrowDownDropDown.svg";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const Dropdown = ({ item, defaultValue, onSave = async () => { } }) => {
  // const [value, setValue] = useState(defaultValue ? defaultValue : null);
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const { t, i18n } = useTranslation("common");
  // const [inputValue, setInputValue] = useState("");
  const selectBoxRef = useRef(null);

  const classes = useStyles();
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const handleExpandLabel = () => {
    setIsTextExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (defaultValue && typeof defaultValue() === "string") {
      setValue(parseInt(defaultValue()));
    }
  }, []);

  useEffect(() => {
    getServicesLookupsValues({
      Service_Lookup_Id: item?.Department_Service_Detail_Lookup_Id,
    })
      .then((response) => response.Status && setOptions(response?.Data))
      .catch((e) => console.error(e));
  }, []);

  const handleChangeWithDebounce = debounce(async (e) => {
    if (e) {
      let obj = { target: {} };
      obj.target.value = e.value;
      await onSave(obj, item);
      // setValue(value);
    }
  }, 500);

  return (
    <Col
      // className={style.dynamics}
      key={item.Department_Service_Detail_Id}
      md={12}
    >
      {item.Department_Service_Detail_Required ? (
        <Form.Label>
          {item?.Department_Service_Detail_Title_AR}{" "}
          <span className={style.required}>({t("required")})</span>
        </Form.Label>
      ) : (
        <Form.Label
          className={
            isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
          }
          onClick={() =>
            handleExpandLabel(
              setIsTextExpanded,
              item?.Department_Service_Detail_Title_AR
            )
          }
        >
          {item?.Department_Service_Detail_Title_AR}
        </Form.Label>
      )}

      <div className={style.textValidationContainer}>
        <Form.Control
          type="text"
          className={style.hiddenInput}
          value={value}
          required={item.Department_Service_Detail_Required ? true : false}
        />

        <SelectBox
          className={styleNew.dynamicDropDownBox}
          dataSource={options}
          placeholder="--اختر--"
          searchEnabled={true}
          name={item.Service_Detail_Id}
          value={value}
          displayExpr={
            i18n.language === "en" ? "Record_Name_EN" : "Record_Name_AR"
          }
          valueExpr={"Record_Id"}
          onValueChanged={(e) => {
            setValue(e.value);
            handleChangeWithDebounce(e);
          }}
          dropDownButtonRender={() => (
            <img
              style={{ width: "12px", height: "12px" }}
              alt="dropdown-icon"
              src={arrowDownDropDown}
            />
          )}
        />

        {/* <Autocomplete
        className={style.dynamicDropDown}
        name={item.Service_Detail_Id}
        options={options && options}
        getOptionLabel={(option) =>
          (i18n.language === "en"
            ? option.Record_Name_EN
            : option.Record_Name_AR) || ""
        }
        getOptionSelected={(option, value) =>
          option?.Record_Id === value?.Record_Id
        }
        value={value}
        onChange={(e, value) => {
          handleChangeWithDebounce(e, value);
        }}
        inputValue={inputValue}
        onInputChange={(e, value) => {
          setInputValue(value);
        }}
        classes={classes}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            name={item.Service_Detail_Id}
            fullWidth
            variant="outlined"
            required={item.Department_Service_Detail_Required ? true : false}
          />
        )}
      /> */}

        {/* <SelectBox
        dataSource={options}
        displayExpr="Record_Name_AR"
        searchEnabled={true}
        placeholder="--اختر--"
        name={item.Service_Detail_Id}
        defaultValue={parseInt(value)}
        className="w-100"
        valueExpr="Record_Id"
        onValueChanged={handleChangeWithDebounce}
        required={true}
      /> */}
        {/* <input list="brow"></input> */}
        {/* <Form.Control
        as="select"
        name={item.Service_Detail_Id}
        onChange={handleChangeWithDebounce}
        required={item.Department_Service_Detail_Required ? true : false}
      >
        <option value="" hidden disabled selected>
          --أختر--
        </option>
        {options?.length > 0 &&
          options.map((data) => (
            <option
              key={data?.Record_Id}
              value={data.Record_Id.toString()}
              selected={data.Record_Id.toString() === value}
            >
              {data?.Record_Name_AR}
            </option>
          ))}
      </Form.Control> */}
        {/* <Form.Text className={style.text}>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوىة</Form.Text> */}
      </div>
    </Col>
  );
};

export default Dropdown;
