import React from "react";
import ShMunAnimatedGif from "global/images/ShMunAnimatedGif-inside.gif";
import styles from "./Overlay.module.scss";

const LoadingOverlay = () => {
  return (
    <div className={styles.overlay}>
      <img src={ShMunAnimatedGif} />
    </div>
  );
};

export default LoadingOverlay;
