import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "app/App";
import store from "app/store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_ar from "./global/translations/ar/common.json";
import common_en from "./global/translations/en/common.json";
import auth_ar from "./global/translations/ar/auth.json";
import auth_en from "./global/translations/en/auth.json";
import { Provider } from "react-redux";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar",
  // lng: 'en',  default
  resources: {
    en: {
      auth: auth_en,
      common: common_en,
    },
    ar: {
      auth: auth_ar,
      common: common_ar,
    },
  },
});

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorker.unregister();
