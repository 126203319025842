import { post } from "./base";
import qs from "qs";

export async function getCredentials(param, cancelToken) {
  const url = `${"SYSTEM/LOGIN/CLIENTS_LOGIN"}`;
  const res = await post(url, qs.stringify(param), cancelToken);
  return res;
}
export async function getUserProfile(userToken) {
  const url = `${"SYSTEM/LOGIN/GET_CLIENT_PROFILES"}`;
  const token = userToken;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  const res = await post(url, null, options);
  return res;
}
export async function setForgetPassword(param, cancelToken) {
  const url = `${"SYSTEM/LOGIN/FORGET_PASSWORD"}`;
  const res = await post(url, qs.stringify(param), cancelToken);
  return res;
}
export async function setResetPassword(param, cancelToken) {
  const url = `${"SYSTEM/LOGIN/RESET_FORGET_PASSWORD"}`;
  const res = await post(url, qs.stringify(param), cancelToken);
  return res;
}
export async function setChangeDefaultPassword(param, userToken, cancelToken) {
  const url = `${"SYSTEM/LOGIN/CHANGE_DEFAULT_LOGIN_PASSWORD"}`;
  const token = userToken;
  const options = { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelToken };
  const res = await post(url, qs.stringify(param), options);
  return res;
}
