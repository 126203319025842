import React, { useState, useEffect } from 'react'
import Grid from 'components/UIComponents/Grid'
import { Column } from 'devextreme-react/data-grid'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { CheckBox } from 'devextreme-react/check-box'
import style from './../form.module.scss'
import style1 from "./../Dashboard.module.scss"
import { Row } from "react-bootstrap"

const ServicesSupportGrid = props => {

  const { t, i18n } = useTranslation('common')
  const [data, setData] = useState()
  const [allRequests, setAllRequests] = useState(false)
  const { user, clientProfiles } = useSelector((state) => state.user);

  useEffect(() => {
    const getServicesSupportList = async () => {
      const res = await axios.post(
        allRequests ?
          `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/SUPPORTREQUEST/GET_CLIENT_SUPPORT_REQUEST_ALL_LIST`
          : `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/SUPPORTREQUEST/GET_CLIENT_SUPPORT_REQUEST_LIST`,
        {
          'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
          'PageIndex': '1',
          'PageCount': '1000'
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`
          }
        }
      ).then(data => {
        setData(data.data.Data)
        props.dataIsReady(false)
      })
    }
    getServicesSupportList()
  }, [allRequests])

  let departmentName = "Department_Name_EN",
    serviceName = "Service_Name_EN",
    status = "Support_Request_Status_Name_EN",
    areaName = "Area_Name_EN";

  if (i18n.language === "ar") {
    departmentName = "Department_Name_AR";
    serviceName = "Service_Name_AR";
    status = "Support_Request_Status_Name_AR";
    areaName = "Area_Name_AR";
  }

  return (
    <>
      <Row className='justify-content-center'>
        <p className={style1.gridTitle}>{t("technical_support_requests")}</p>
      </Row>

      <Row className='p-2'>
        <div>
          <CheckBox
            defaultValue={false}
            onValueChanged={() => setAllRequests(!allRequests)}
          />
          <span className={['px-2', style.checkbox].join(' ')}>{t('all')}</span>
        </div>
      </Row>

      <Row>
        <Grid
          sourceData={data}
          addEnabled={false}
          editEnabled={false}
          deleteEnabled={false}
          viewEnabled={true}
          onView={({ data }) => { props.handleShow(data) }}
          onDelete={({ data }) => { }}
        >
          <Column caption={t('request_no')} alignment="center" dataField="Support_Request_No" dataType="string" width={150} />
          <Column caption={t('request_date')} alignment="center" dataField="Support_Request_Date" dataType="date" width={150} format="yyyy-MM-dd" />
          <Column caption={t('service_name')} alignment="center" dataField={serviceName} dataType="string" width={140} />
          <Column caption={t('department_name')} alignment="center" dataField={departmentName} dataType="string" width={150} />
          <Column caption={t('request_status')} alignment="center" dataField={status} dataType="string" width={150} />
        </Grid>
      </Row>
    </>
  )
}

export default ServicesSupportGrid
