import React, { useState } from 'react'
import Header from './../../UIComponents/Header/Header'
import Footer from './../../UIComponents/Footer/Footer'
import style from './UserServicesSearch.module.scss'
import ServicesGrid from './ServicesGrid'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PopUp from '../../UIComponents/PopUp/PopUp'
import { Formik, Form, Field } from "formik"
import moment from 'moment'

const UserServicesSearch = () => {

    const { t, i18n } = useTranslation('common')
    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()

    const handleClose = () => setShow(false)
    const handleShow = data => {
        setShow(true)
        setModalData(data)
    }

    return (
        <>
            <Header />

            <Container fluid='lg'>
                <Row>
                    <Col xl={12} className='text-center'>
                        <p className={style.title}>{t('services_search')}</p>
                    </Col>
                </Row>

                <Row className='mt-3 mb-5'>
                    <ServicesGrid handleShow={handleShow} />
                </Row>
            </Container>

            <Footer />

            {modalData ?
                <PopUp
                    show={show}
                    handleClose={handleClose}
                    title={t('services_search')}
                >
                    <Container>

                        <Formik
                            initialValues={{
                                request_no: modalData.Request_No,
                                request_date: moment(modalData?.Request_Date).lang("en").format('YYYY-MM-DD'),
                                service_name: i18n.language === 'en' ? modalData?.Service_Name_EN : modalData?.Service_Name_AR,
                                department_name: i18n.language === 'en' ? modalData?.Department_Name_EN : modalData?.Department_Name_AR,
                                request_status: i18n.language === 'en' ? modalData?.Request_Status_Name_EN : modalData?.Request_Status_Name_AR,
                                service_issuance: modalData.Department_Service_Version_Name
                            }}
                        >
                            <Form className={style.service}>
                                <Row style={{ 'textAlign': i18n.language === 'ar' ? 'start' : null }}>
                                    <Col sm={4}>
                                        <p className={style.name}>{t('request_no')}</p>
                                        <Field className={style.serviceField} name="request_no" type="text" readOnly />
                                    </Col>
                                    <Col sm={4}>
                                        <p className={style.name}>{t('request_date')}</p>
                                        <Field className={style.serviceField} name="request_date" type="text" readOnly />
                                    </Col>
                                    <Col sm={4}>
                                        <p className={style.name}>{t('department_name')}</p>
                                        <Field className={style.serviceField} name="department_name" type="text" readOnly />
                                    </Col>
                                    <Col sm={12}>
                                        <Field name="service_name" type="text" as="textarea" className={['text-center title mainName', style.serviceField].join(' ')} readOnly />
                                    </Col>
                                    <Col sm={6}>
                                        <p className={style.name}>{t('request_status')}</p>
                                        <Field className={style.serviceField} name="request_status" type="text" readOnly />
                                    </Col>
                                    <Col sm={6}>
                                        <p className={style.name}>{t('service_issuance')}</p>
                                        <Field className={style.serviceField} name="service_issuance" type="text" readOnly />
                                    </Col>
                                </Row>
                            </Form>
                        </Formik>

                    </Container>
                </PopUp>
                : null}
        </>
    )
}

export default UserServicesSearch
