import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { registerSuccess } from "./registerSlice";
import { forgetPasswordSuccess, resetPasswordSuccess } from "./otpSlice";

const slice = createSlice({
  name: "userMachine",
  initialState: {
    prevStates: [],
    currentState: "Login",
  },
  reducers: {
    prevState: (state) => {
      if (state.prevStates.length > 0) {
      }
    },
    toState: (state, { payload }) => {
      state.currentState = payload;
    },
    resetMachine: (state) => {
      state.currentState = "Login";
      state.prevStates = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgetPasswordSuccess, (state) => {
        state.currentState = "OTP";
      })
      .addMatcher(isAnyOf(registerSuccess, resetPasswordSuccess), (state) => {
        state.currentState = "Login";
      });
  },
});
export default slice.reducer;

export const { prevState, toState, resetMachine } = slice.actions;
