import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./applyService.module.scss";
import sendMail from "./iconsFolder/sendMail.svg";
import greyWave from "./iconsFolder/greyWave.svg";
import { useHistory } from "react-router-dom";

const SubmitModal = ({ isShow, handleClick }) => {
  const { t } = useTranslation("common");
  const history = useHistory();

  return (
    <Modal
      show={isShow}
      // onHide={handleClick}
      backdrop="static"
      keyboard={false}
      className={style.modal}
    >
      <div style={{ zIndex: "9" }}>
        <div className={style.imageContainer}>
          <img src={sendMail} alt="" />
        </div>
        <div className={style.thankYou}>{t("thankyou")}</div>
        <div className={`${style.orderDelivered} mb-3`}>
          {t("orderDeliveredSuccessfully")}
        </div>

        <div className={`${style.buttonsContainer} mb-5`}>
          <button
            onClick={() => history.push(`/`)}
            className={`mainBtn secondarymainBtn outlined p-2 ${style.previousButton}`}
          >
            {t("backToHomePage")}
          </button>
          <button
            onClick={() => history.push(`/dashboard`)}
            className={`mainBtn primarymainBtn p-2 ${style.nextButton}`}
          >
            {t("Dashboard")}
          </button>
        </div>
      </div>

      <img className={style.greyWave} src={greyWave} alt="" />
    </Modal>
  );
};

export default SubmitModal;
