import React from 'react'
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react'
import { useTranslation } from 'react-i18next'
import style from './MapContainer.module.scss'

const stylesArr = [
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{ "visibility": "simplified" }, { "color": "#fdfdfd" }]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "landscape.natural.landcover",
        "elementType": "geometry",
        "stylers": [{ "visibility": "simplified" }]
    },
    {
        "featureType": "landscape.natural.landcover",
        "elementType": "geometry.fill",
        "stylers": [{ "visibility": "simplified" }]
    },
    {
        "featureType": "landscape.natural.terrain",
        "elementType": "geometry",
        "stylers": [{ "visibility": "simplified" }]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{ "visibility": "simplified" }]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "poi.attraction",
        "elementType": "geometry",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "poi.business",
        "elementType": "geometry",
        "stylers": [{ "visibility": "simplified" }]
    },
    {
        "featureType": "poi.business",
        "elementType": "geometry.fill",
        "stylers": [{ "hue": "#fcff00" }, { "saturation": "0" }, { "lightness": "40" }]
    },
    {
        "featureType": "poi.government",
        "elementType": "geometry",
        "stylers": [{ "visibility": "on" }, { "color": "#28a745" }]
    },
    {
        "featureType": "poi.medical",
        "elementType": "geometry",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{ "visibility": "simplified" }]
    },
    {
        "featureType": "poi.place_of_worship",
        "elementType": "geometry",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "poi.school",
        "elementType": "geometry",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "geometry",
        "stylers": [{ "visibility": "simplified" }]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{ "color": "#000000" }]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [{ "color": "#ffffff" }]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [{ "gamma": 7.18 }]
    },
    {
        "featureType": "road",
        "elementType": "labels.text",
        "stylers": [{ "visibility": "on" }, { "saturation": "-35" }, { "lightness": "-95" }, { "gamma": "0.00" }, { "weight": "0.01" }]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [{ "color": "#baa167" }, { "lightness": "40" }]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [{ "color": "#ffffff" }]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [{ "visibility": "on" }, { "color": "#baa167" }, { "lightness": "80" }]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [{ "color": "#ffffff" }]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [{ "visibility": "simplified" }]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text",
        "stylers": [{ "visibility": "on" }]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [{ "gamma": 0.48 }, { "visibility": "off" }]
    },
    {
        "featureType": "transit.station",
        "elementType": "all",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "transit.station",
        "elementType": "labels.icon",
        "stylers": [{ "visibility": "off" }]
    },
    {
        "featureType": "transit.station.rail",
        "elementType": "all",
        "stylers": [{ "visibility": "off" }, { "color": "#ff0000" }]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [{ "color": "#4d4946" }]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{ "visibility": "on" }, { "color": "#151713" }, { "lightness": "70" }, { "saturation": "0" }]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#ffffff" }]
    }
]

const svgMarker = {
    path: "M30.5,11.4c-1.4-4.6-5.1-8.3-9.7-9.7C16,0.2,10.9,1.1,7,3.9c-3.9,2.9-6.2,7.5-6.2,12.3c0,3.3,1.1,6.5,3,9.1 L16,41l12.2-15.6C31.2,21.4,32,16.3,30.5,11.4z M16,24.4c-4.5,0-8.2-3.7-8.2-8.2S11.5,8,16,8s8.2,3.7,8.2,8.2S20.5,24.4,16,24.4z, M16,10.4c-3.2,0-5.9,2.6-5.9,5.8s2.6,5.9,5.9,5.9s5.9-2.6,5.9-5.9C21.9,13,19.2,10.4,16,10.4z",
    fillColor: "#28A745",
    fillOpacity: 1,
    strokeWeight: 2,
    strokeColor: "#ffffff",
    rotation: 0,
    scale: 1.1,
}

const MapContainer = props => {

    const { t, i18n } = useTranslation('common')

    return (
        <div className={[style.mapStyles].join(' ')}>
            <Map
                google={props.google}
                zoom={13}
                className={style.mapStyles}
                initialCenter={{ lat: 25.346254, lng: 55.420933 }}
                styles={stylesArr}
            >
                <Marker position={{ lat: 25.346254, lng: 55.420933 }} icon={svgMarker} />
            </Map>

            {/* <span className={style.sideSpan}>
        <p className={style.title}>{t('get_in_touch')}</p>
        <p>Al Falah SuburbAgricultural Falah - Sharjah - United Arab Emirates</p>
      </span> */}
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyCBx-BN5uLwmFezDw0NeQs9xp_UcplHIzs')
})(MapContainer)
