import React, { useState, useEffect } from 'react'
import PaymentsGrid from './PaymentsGrid'
import PopUp from '../../../UIComponents/PopUp/PopUp'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import style from './../form.module.scss'
import moment from 'moment'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Column } from 'devextreme-react/data-grid'
import Grid from './../../../UIComponents/Grid/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons'

const Payments = props => {

    const { t, i18n } = useTranslation('common')
    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()
    const [invoiceData, setInvoiceData] = useState()
    const [clickFix, setClickFix] = useState(false)
    const { user, clientProfiles } = useSelector((state) => state.user);

    const handleClose = () => setShow(false)
    const handleShow = data => {
        setModalData(data)
        setClickFix(!clickFix)
    }

    let invoiceDetailTypeName = 'Invoice_Detail_Type_Name_EN';
    if (i18n.language === 'ar') {
        invoiceDetailTypeName = 'Invoice_Detail_Type_Name_AR';
    }

    useEffect(() => {
        if (modalData?.Invoice_Id) {
            const res = axios.post(
                `${process.env.REACT_APP_API_URL}/PORTAL/PAYMENTS/INVOICES/GET_ClIENTS_INVOICE_INFO`,
                {
                    'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                    'Invoice_id': modalData?.Invoice_Id
                },
                {
                    headers: {
                        Authorization: `Bearer ${user?.access_token}`
                    }
                }
            ).then(res => {
                setInvoiceData(res.data.Data)
                setShow(true)
            })
        }
    }, [clickFix])

    const handleFileDownload = code => {
        const res = axios.post(
            `${process.env.REACT_APP_API_URL}/PORTAL/PAYMENTS/INVOICES/DOWNLOAD_ClIENTS_INVOICE_INFO`,
            {
                'Client_Profile_Id': clientProfiles?.Client_Profile_Id,
                'Invoice_id': modalData?.Invoice_Id,
                'File_Code': code
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`
                }
            }
        ).then(res => {
            let a = document.createElement("a"); // Create <a>
            a.href = "data:image/png;base64," + res.data.Data.File_Bytes; // Base64 
            a.download = res.data.Data.File_Name; // File name 
            a.click(); // Download file
        })
    }

    return (
        <>
            <PaymentsGrid
                handleShow={handleShow}
                dataIsReady={props.dataIsReady}
            />

            {modalData ?
                <PopUp
                    show={show}
                    handleClose={handleClose}
                    title={t('insurance_fees_fines')}
                >
                    <Container>
                        <Formik
                            initialValues={{
                                receipt_number: modalData?.Invoice_No,
                                request_no: modalData?.Request_No,
                                service_name: i18n.language === 'en' ? modalData?.Service_Name_EN : modalData?.Service_Name_AR,
                                department_name: i18n.language === 'en' ? modalData?.Department_Name_EN : modalData?.Department_Name_AR,
                                client_name: i18n.language === 'en' ? modalData?.Client_Name_EN : modalData?.Client_Name_AR,
                                invoice_date: moment(modalData?.Invoice_Date).lang("en").format('YYYY-MM-DD'),
                                invoice_id: modalData?.Invoice_Id,
                                invoice_no: modalData?.Invoice_No,
                                invoice_amount: modalData?.Invoice_Value,
                                payment_no: modalData?.Payment_No,
                                online_reference_no: modalData?.Online_Reference_No,
                                payment_date: moment(modalData?.Invoice_Paid_Date).lang("en").format('YYYY-MM-DD'),
                            }}
                        >
                            <Form className={style.dashboardData}>
                                <Row
                                    style={{ 'textAlign': i18n.language === 'ar' ? 'start' : null }}
                                >
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_no')}</label>
                                        <Field className={style.dashboardField} name="receipt_number" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('invoice_date')}</label>
                                        <Field className={style.dashboardField} name="invoice_date" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('department_name')}</label>
                                        <Field className={style.dashboardField} name="department_name" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('client_name')}</label>
                                        <Field className={style.dashboardField} name="client_name" type="text" readOnly />
                                    </Col>

                                    <Col sm={12}>
                                        <Field name="service_name" type="text" as="textarea" className={['text-center title mainName', style.dashboardField].join(' ')} readOnly />
                                    </Col>

                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('invoice_serial_no')}</label>
                                        <Field className={style.dashboardField} name="invoice_id" type="text" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('invoice_no')}</label>
                                        <Field className={style.dashboardField} name="invoice_no" type="text" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('invoice_amount')}</label>
                                        <Field className={style.dashboardField} name="invoice_amount" type="text" readOnly />
                                    </Col>

                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('payment_no')}</label>
                                        <Field className={style.dashboardField} name="payment_no" type="text" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('electronic_payment_voucher_no')}</label>
                                        <Field className={style.dashboardField} name="online_reference_no" type="text" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('payment_date')}</label>
                                        <Field className={style.dashboardField} name="payment_date" type="text" readOnly />
                                    </Col>

                                    {invoiceData?.Payment_File_Code || invoiceData?.Invoice_File_Code ?
                                        <Col md={4} sm={6} className='mb-2'>
                                            <p className={style.name}>{t('invoices')}</p>

                                            {invoiceData?.Payment_File_Code ?
                                                <div className={style.file}>
                                                    <FontAwesomeIcon className={style.fileSvg} icon={faFile} />
                                                    <div>
                                                        <p>{invoiceData?.Payment_File_Code_Info?.File_Name}</p>
                                                        <p className='px-2'>{invoiceData?.Payment_File_Code_Info?.File_Size}</p>
                                                    </div>
                                                    <span
                                                        className={[
                                                            style.download,
                                                            i18n.language === 'en' ? style.downloadEN : style.downloadAR
                                                        ].join(' ')}
                                                        onClick={() => handleFileDownload(invoiceData?.Payment_File_Code)}
                                                    >
                                                        <FontAwesomeIcon size="lg" icon={faDownload} />
                                                    </span>
                                                </div>
                                                : null}

                                            {invoiceData?.Invoice_File_Code ?
                                                <div className={style.file}>
                                                    <FontAwesomeIcon className={style.fileSvg} icon={faFile} />
                                                    <div>
                                                        <p>{invoiceData?.Invoice_File_Code_Info?.File_Name}</p>
                                                        <p className='px-2'>{invoiceData?.Invoice_File_Code_Info?.File_Size}</p>
                                                    </div>
                                                    <span
                                                        className={[
                                                            style.download,
                                                            i18n.language === 'en' ? style.downloadEN : style.downloadAR
                                                        ].join(' ')}
                                                        onClick={() => handleFileDownload(invoiceData?.Invoice_File_Code)}
                                                    >
                                                        <FontAwesomeIcon size="lg" icon={faDownload} />
                                                    </span>
                                                </div>
                                                : null}
                                        </Col>
                                        : null}

                                </Row>
                            </Form>
                        </Formik>

                        <br />

                        <Grid
                            sourceData={invoiceData?.Invoice_Details}
                            addEnabled={false}
                            editEnabled={false}
                            deleteEnabled={false}
                            viewEnabled={false}
                            onView={({ data }) => { }}
                            onDelete={({ data }) => { }}
                        >
                            <Column caption={t('invoice_detail_type_name')} alignment="center" dataField={invoiceDetailTypeName} dataType="string" width='auto' />
                            <Column caption={t('invoice_detail_total_value')} alignment="center" dataField="Invoice_Detail_Total_Value" dataType="string" width={120} />
                            <Column caption={t('invoice_detail_tax_value')} alignment="center" dataField="Invoice_Detail_Tax_Value" dataType="string" width={120} />
                            <Column caption={t('invoice_detail_grand_total_value')} alignment="center" dataField="Invoice_Detail_Grand_Total_Value" dataType="string" width='auto' />
                        </Grid>

                    </Container>
                </PopUp>
                : null}
        </>

    )
}

export default React.memo(Payments)
