import transitionDefault from "./transitionDefault.module.scss";

const TransitionControl = ({ additional, triggerLabel, postFix = false, callback, styles = transitionDefault }) => {
  if (postFix) {
    return (
      <p className={styles.paragraph}>
        <button type="button" className={`${styles.btn} ${styles.highlight}`} onClick={callback}>
          {triggerLabel}
        </button>
        {additional}
      </p>
    );
  } else {
    return (
      <p className={styles.paragraph}>
        {additional}
        <button type="button" className={`${styles.btn} ${styles.highlight}`} onClick={callback}>
          {triggerLabel}
        </button>
      </p>
    );
  }
};

export default TransitionControl;
