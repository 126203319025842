import React, { useState, useEffect } from "react";
import imageUpload from "./imageUpload.module.css";
import userImage from "global/images/user-image.png";

const ImageUpload = ({ field, setFieldValue, onSubmit, children, ...props }) => {
  const [uploadedFile, setUploadedFile] = useState(field.value);

  useEffect(() => {
    setUploadedFile(field.value);
  }, [field.value]);

  const onChange = async (e) => {
    e.preventDefault();

    let file = e.target.files[0];
    if (file) {
      const value = await onSubmit(file);
      if (value) {
        setFieldValue(props.id || props.name, value);
      }
      e.target.value = null;
    }
  };

  return (
    <label className={imageUpload.imageWrapper} htmlFor={props.id || props.name}>
      <>
        {uploadedFile ? (
          <div className="block">
            <img width="200px" height="200px" src={uploadedFile} alt="profile image" />
          </div>
        ) : (
          <div className={imageUpload.imageContainer}>
            <img width="200px" height="200px" src={userImage} alt="profile image" />
          </div>
        )}
      </>
      <input className={imageUpload.imageInput} id={props.id} accept="image/*" name={props.name} type={props.type} onChange={onChange} />
    </label>
  );
};

export default ImageUpload;
