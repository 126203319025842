import { configureStore, combineReducers } from "@reduxjs/toolkit";
import logger from "redux-logger";
import userReducer from "features/user/userSlice";
import otpSliceReducer from "features/user/otpSlice";
import registerReducer from "features/user/registerSlice";
import userMachineReducer from "features/user/userMachineSlice";
import serviceCardReducer from "features/service/serviceCardSlice";
import serviceRequestReducer from "features/service/serviceRequestSlice";
import serviceMachineReducer from "features/service/serviceMachineSlice";
import ServiceMunicipReducer from "features/service/serviceMunicipSlice";
import UpdateRequestReducer from "features/service/updateRequestSlice";
import ServiceViewReducer from "features/service/ServiceViewSlice";
import errorMessageReducer from "features/service/errorMessageSlice";

import { customInstance } from "api/base";

const appReducer = combineReducers({
  user: userReducer,
  login: combineReducers({
    register: registerReducer,
    userMachine: userMachineReducer,
    otp: otpSliceReducer,
  }),
  service: combineReducers({
    serviceCard: serviceCardReducer,
    serviceMunicip: ServiceMunicipReducer,
    serviceRequest: serviceRequestReducer,
    updateRequest: UpdateRequestReducer,
    serviceView: ServiceViewReducer,
    serviceMachine: serviceMachineReducer,
  }),
  error: errorMessageReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    // PlEASE NOTE, in a further enhancment the user related states should be destrcuted and reset it instead of the entire store
    state = undefined; //resets the store
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      process.env.NODE_ENV !== "production" ? logger : []
    ),
  devTools: process.env.NODE_ENV !== "production",
});

customInstance.defaults.store = store;

export default store;
