import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './LanguageBtn.module.scss'

const LanguageBtn = () => {

    const { t, i18n } = useTranslation('common')

    const handleLanguage = () => {
        if (i18n.language === 'en') {
            localStorage.setItem('lang', 'ar')
        } else {
            localStorage.setItem('lang', 'en')
        }
        window.location.reload()
    }
    
    return (
        <>
            <button onClick={handleLanguage} className={style.langBtn}>
                {i18n.language === 'en' ? 'Ar' : 'En'}
            </button>
        </>
    )
}

export default LanguageBtn
