import React from "react";

function Zip() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 350 350">
      <g fill="none" fillRule="nonzero" stroke="none" strokeDasharray="none" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1" opacity="1">
        <path
          fill="#B83535"
          d="M11.58 54.882v31.965A3.153 3.153 0 0014.733 90h60.534a3.153 3.153 0 003.153-3.153V54.882c-22.347-7.001-44.628-7.044-66.84 0z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#E9E9E0" d="M78.42 54.882V18.345C69.386 13.658 63.133 7.61 60.075 0H14.733a3.153 3.153 0 00-3.153 3.153v51.729h66.84z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#D9D7CA" d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path
          fill="#F9F9F9"
          d="M37.594 82.343h-8.783a1.5 1.5 0 01-1.327-2.201l7.621-14.433h-6.294a1.5 1.5 0 110-3h8.783a1.5 1.5 0 011.327 2.201L31.3 79.343h6.294a1.5 1.5 0 110 3zM58.351 62.719h-5.944a1.5 1.5 0 00-1.5 1.5V80.82a1.5 1.5 0 003 0v-5.247h4.444a4.343 4.343 0 004.338-4.339v-4.177a4.344 4.344 0 00-4.338-4.338zm1.337 8.514a1.34 1.34 0 01-1.338 1.339h-4.444v-6.854h4.444a1.34 1.34 0 011.338 1.338v4.177zM44.534 82.327a1.5 1.5 0 01-1.5-1.5V64.21a1.5 1.5 0 113 0v16.617a1.5 1.5 0 01-1.5 1.5z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
      </g>
    </svg>
  );
}

export default Zip;
