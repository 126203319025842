import React from 'react'
import Header from './../../UIComponents/Header/Header'
import Footer from './../../UIComponents/Footer/Footer'
import { Container, Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import style from './SliderContent.module.scss'
import { NavLink } from 'react-router-dom'

const SliderContent = () => {

    let location = useLocation()
    const { t, i18n } = useTranslation('common')

    return (
        <>
            <Header />

            <Container className={style.Content} fluid='lg'>
                <Row>
                    <Col xs={12}>
                        <p className={style.firstTitle}>{t('sharjah_government')}</p>
                        <p className={style.secondTitle}>{location.aboutProps.sliderTitle}</p>
                        <p className={style.para}>{location.aboutProps.sliderContent}</p>
                    </Col>
                </Row>

                <Row className='justify-content-center justify-content-sm-end mt-4'>
                    <Col md={3}>
                        <NavLink 
                            to='/'
                            className='mainBtn primarymainBtn py-3'
                        >
                            {t('return_to_home')}
                        </NavLink>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default SliderContent
