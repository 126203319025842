import React from "react";

function Mp3() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 350 350">
      <g fill="none" fillRule="nonzero" stroke="none" strokeDasharray="none" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1" opacity="1">
        <path
          fill="#B83535"
          d="M11.58 54.882v31.965A3.153 3.153 0 0014.733 90h60.534a3.153 3.153 0 003.153-3.153V54.882c-22.347-7.001-44.628-7.044-66.84 0z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#E9E9E0" d="M78.42 54.882V18.345C69.386 13.658 63.133 7.61 60.075 0H14.733a3.153 3.153 0 00-3.153 3.153v51.729h66.84z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path fill="#D9D7CA" d="M78.42 18.345H62.948a2.873 2.873 0 01-2.873-2.873V0L78.42 18.345z" transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
        <path
          fill="#F9F9F9"
          d="M37.01 62.769a1.5 1.5 0 00-1.67.492l-4.922 6.269-4.923-6.27a1.5 1.5 0 00-2.68.927v16.664a1.5 1.5 0 103 0V68.526l3.422 4.359.019.02c.03.037.066.07.1.104.038.039.075.079.117.112.006.005.011.012.018.017.027.021.058.035.086.055.048.032.094.065.145.092.042.022.085.038.128.055.046.019.092.039.139.053.048.014.096.022.145.032.046.009.092.019.138.023.048.005.095.003.143.003.049 0 .098.001.147-.004.045-.004.088-.014.132-.022.051-.01.102-.018.152-.033.044-.013.085-.032.128-.049.047-.019.095-.037.14-.061.046-.024.088-.054.131-.083.032-.021.066-.037.097-.062l.02-.019c.037-.03.069-.066.104-.1.039-.038.079-.076.113-.117l.017-.017 3.422-4.358V80.85a1.5 1.5 0 103 0V64.187a1.496 1.496 0 00-1.008-1.418zM50.06 62.719h-5.945a1.5 1.5 0 00-1.5 1.5V80.82a1.5 1.5 0 103 0v-5.247h4.445a4.343 4.343 0 004.338-4.339v-4.177a4.344 4.344 0 00-4.338-4.338zm1.337 8.514a1.34 1.34 0 01-1.338 1.339h-4.445v-6.854h4.445a1.34 1.34 0 011.338 1.338v4.177zM67.184 66.613c0-2.147-1.771-3.895-3.946-3.895h-5.122a1.5 1.5 0 000 3h5.122c.521 0 .946.401.946.895v3.525c0 .494-.425.896-.946.896h-3.091a1.5 1.5 0 000 3h3.091c.521 0 .946.401.946.896v3.525c0 .494-.425.896-.946.896h-5.122a1.5 1.5 0 000 3h5.122c2.176 0 3.946-1.747 3.946-3.896V74.93c0-.908-.329-1.733-.858-2.396a3.826 3.826 0 00.858-2.396v-3.525z"
          transform="matrix(3.89 0 0 3.89 -1.944 -1.944)"></path>
      </g>
    </svg>
  );
}

export default Mp3;
