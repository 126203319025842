import React from "react";
import style from "./Description.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

const Description = ({ serviceData, serviceDetailsData, serviceStepsData, onStart }) => {
  const { t, i18n } = useTranslation("common");

  return (
    <>
      <Row>
        <Col xl={12}>
          <p className={style.title}>{t("description")}</p>
        </Col>
        <Col xl={12}>{i18n.language === "en" ? <p className={style.content}>{serviceData.Service_Description_EN}</p> : <p className={style.content}>{serviceData.Service_Description_AR}</p>}</Col>
      </Row>

      <Row>
        <Col xl={12}>
          <p className={style.title}>{t("categorize")}</p>
        </Col>
        <Col xl={12}>{i18n.language === "en" ? <p className={style.content}>{serviceData.Service_Category_Name_EN}</p> : <p className={style.content}>{serviceData.Service_Category_Name_AR}</p>}</Col>
      </Row>

      <Row>
        <Col xl={12}>
          <p className={style.title}>{t("required_documents")}</p>
        </Col>
        <Col xl={12}>
          <ul className={style.stepsList}>
            {serviceDetailsData.length > 0 &&
              serviceDetailsData.map((detail, i) => (
                <li className={style.step} key={detail.Service_Detail_Id}>
                  <span className={style.number}>{i + 1}</span>
                  <div className={style.stepTitle}>
                    <p>{i18n.language === "en" ? detail.Service_Detail_Title_EN : detail.Service_Detail_Title_AR} {detail.Service_Detail_Required == 1 ? `(${t('required')})` : null}</p>
                    <span>{i18n.language === "en" ? detail.File_Content_Type_Name_EN : detail.File_Content_Type_Name_AR}</span>
                  </div>
                </li>
              ))}
          </ul>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <p className={style.title}>{t("steps")}</p>
        </Col>
        <Col xl={12}>
          <ul className={style.stepsList}>
            {serviceStepsData.length > 0 &&
              serviceStepsData.map((step) => (
                <li className={style.step} key={step.Service_Step_Id}>
                  <span className={style.number}>{step.Service_Step_No}</span>
                  <div className={style.stepTitle}>
                    <p>{i18n.language === "en" ? step.Service_Step_Name_EN : step.Service_Step_Name_AR}</p>
                    <span>{i18n.language === "en" ? step.Service_Step_Details_EN : step.Service_Step_Details_AR}</span>
                  </div>
                </li>
              ))}
          </ul>
        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <p className={style.title}>{t("terms_of_service")}</p>
        </Col>
        <Col xl={12}>{i18n.language === "en" ?
          <p dangerouslySetInnerHTML={{ __html: serviceData.Service_Rules_EN }} className={style.content} />
          : <p dangerouslySetInnerHTML={{ __html: serviceData.Service_Rules_AR }} className={style.content} />}

        </Col>
      </Row>

      <Row>
        <Col xl={12}>
          <p className={style.title}>{t("fees")}</p>
        </Col>
        <Col xl={12}>{i18n.language === "en" ? <p className={style.content}>{serviceData.Service_Fees_EN}</p> : <p className={style.content}>{serviceData.Service_Fees_AR}</p>}</Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end mb-3">
          <button type="button" className={["mainBtn primarymainBtn lgBtn", style.startBtn].join(" ")} onClick={onStart}>
            {t("start_service")}
          </button>
        </Col>
      </Row>
    </>
  );
};

export default Description;
