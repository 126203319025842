import React from 'react'
import Header from './../../UIComponents/Header/Header'
import Footer from './../../UIComponents/Footer/Footer'
import style from './SuggestionsComplains.module.scss'
import Inputs from './Inputs/Inputs'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const SuggestionsComplains = () => {

    const { t, i18n } = useTranslation('common')

    return (
        <>
            <Header />

            <Container fluid='lg'>
                <Row>
                    <Col xl={12} className='text-center'>
                        <p className={style.title}>{t('complaints_n_suggestions')}</p>
                        <Row className='justify-content-center'>
                            <Col xl={8} className='text-center'>
                                <p className={style.para}>{t('complaints_n_suggestions_para')}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Inputs />
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default SuggestionsComplains
