import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getServiceCard } from "api/serviceAPI";
import axios from "axios";

export const fetchServiceCard = createAsyncThunk("serviceCard/fetchServiceCard", async (param, { signal }) => {
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  const response = await getServiceCard(param, {
    cancelToken: source.token,
  });
  return response;
});
/// DepartmentId reset and reducers
const slice = createSlice({
  name: "serviceCard",
  initialState: {
    loading: "idle",
    serviceData: null,
    serviceDepartmentsList: [],
    serviceDetailsData: [],
    serviceStepsData: [],
    error: null,
  },
  reducers: {
    resetStates: (state) => {
      state.loading = "idle";
      state.serviceData = null;
      state.serviceDepartmentsList = [];
      state.serviceDetailsData = [];
      state.serviceStepsData = [];
      state.error = null;
    },
  },
  extraReducers: {
    [fetchServiceCard.pending]: (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    },
    [fetchServiceCard.fulfilled]: (state, { payload }) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        if (payload?.Status) {
          const { Service_Data, Service_Departments_List, Service_Details_Data, Service_Steps_Data } = payload?.Data;
          state.serviceData = Service_Data;
          state.serviceDepartmentsList = Service_Departments_List;
          state.serviceDetailsData = Service_Details_Data;
          state.serviceStepsData = Service_Steps_Data;
        }
      }
    },
    [fetchServiceCard.rejected]: (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = action.error;
      }
    },
  },
});
export default slice.reducer;

export const { resetStates } = slice.actions;
