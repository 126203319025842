import React, { useState, useRef, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserDropdown from "./../Icons/UserDropdown/UserDropdown";
import style from "./UserLogin.module.scss"
import useDetectOutsideClick from "hooks/useDetectOutsideClick";
import { toState } from "./../../../../../features/user/userMachineSlice";
import Sidebar from 'react-sidebar'
import Notifications from './../Notifications/Notifications'
import Badge from 'react-bootstrap/Badge'
import axios from "axios"

const UserLogin = () => {

    const { t, i18n } = useTranslation('common')
    const { user, clientProfiles, isAuthenticated } = useSelector((state) => state.user)
    const dropdownRef = useRef(null)
    const [dropOpen, setDropOpen] = useDetectOutsideClick(dropdownRef, false)
    const dispatch = useDispatch()
    const mql = window.matchMedia(`(min-width: 800px)`)
    const [notifState, setNotifState] = useState(false)
    const [notif, setNotif] = useState({
        new: false,
        unreaded: false,
        number: ''
    })

    useEffect(() => { 
        mql.addEventListener('change', () => mediaQueryChanged) 
        if (user?.access_token) {
            CheckWebNotifications()
        }
    }, [])

    useEffect(() => () => mql.removeEventListener('change', () => mediaQueryChanged), [])

    const onSetSidebarOpen = open => setNotifState(open)
    const mediaQueryChanged = () => setNotifState(false)
    const onCloseDropdown = () => setDropOpen(false)

    const CheckWebNotifications = async() => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/CHECK_WEB_NOTIFICATIONS`, {},
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`,
                },
            }
        )
        let newNotif = res.data.Data.New_Notifications
        let unreadedNotif = res.data.Data.Unread_Notifications

        if (newNotif > 0) {
            setNotif({
                new: true,
                unreaded: false,
                number: newNotif
            })
        } else {
            setNotif({
                new: false,
                unreaded: true,
                number: unreadedNotif
            })
        }
    }

    const handleNotificationsCheck = () => {
        CheckWebNotifications()
    }

    return (
        <>
            {!isAuthenticated ? (
                <NavLink exact to="/login">
                    <button 
                        className={[style.siginIn, i18n.language === "en" ? style.signEn : style.signAr].join(" ")}
                        onClick={() => dispatch(toState("Login"))}
                    >
                        {t("login_in")}
                    </button>
                </NavLink>
            ) : (
                <>
                    <div ref={dropdownRef} className={style.menuItem}>
                        <a className={dropOpen ? style.activeLink : null} onClick={() => setDropOpen(!dropOpen)}>
                            <div className={style.userWrapper}>
                                <span>
                                    {i18n.language === "en" ? 
                                        user?.User_Name_EN.split(' ').slice(0,2).join(' ') || t("user") 
                                    : 
                                        user?.User_Name_AR.split(' ').slice(0,2).join(' ') || t("user")
                                    }
                                </span>
                                <span className={style.profileType}>
                                    {i18n.language === "en" ? clientProfiles?.Dealing_Type_Name_EN : clientProfiles?.Dealing_Type_Name_AR}
                                </span>
                                <span 
                                    className={[
                                        style.statusBadge, 
                                        clientProfiles?.Profile_Active_Status_Id == -1 ? style.profileInactive
                                        : clientProfiles?.Profile_Active_Status_Id == 0 ? style.profilePending
                                        : clientProfiles?.Profile_Active_Status_Id == 1 ? style.profileActive
                                        : clientProfiles?.Profile_Active_Status_Id == 2 ? style.profileInactive
                                        : clientProfiles?.Profile_Active_Status_Id == 5 ? style.profilePending
                                        : clientProfiles?.Profile_Active_Status_Id == 6 ? style.profileInactive
                                        : clientProfiles?.Profile_Active_Status_Id == 7 ? style.profileInactive
                                        : false
                                    ].join(' ')}
                                >
                                    <span className={style.profileStatus}>
                                        {i18n.language === "en" ? clientProfiles?.Profile_Active_Status_Name_EN : clientProfiles?.Profile_Active_Status_Name_AR}
                                    </span>
                                </span>
                            </div>
                        </a>
                        <UserDropdown dropOpen={dropOpen} onCloseDropdown={onCloseDropdown} />
                    </div>
                    
                    <Sidebar
                        sidebar={
                            <Notifications notificationsCheck={handleNotificationsCheck} />
                        }
                        open={notifState}
                        onSetOpen={onSetSidebarOpen}
                        shadow={true}
                        transitions={true}
                        overlayClassName='notifOverlayStyle'
                        sidebarClassName='sideBarStyle'
                        sidebarClassName={[
                            'sideBarStyle',
                            i18n.language == 'en' ? 'sideBarEnStyle' : 'sideBarArStyle',
                            i18n.language == 'ar' && !notifState && 'sideBarArCloseStyle'
                        ].join(' ')}
                    >
                        <span 
                            onClick={() => onSetSidebarOpen(!notifState)}
                            className={[
                                style.siderbarBtn,
                                i18n.language == 'en' ? style.siderbarEnBtn : style.siderbarArBtn
                            ].join(' ')}
                        >
                            <Badge 
                                className={[
                                    notif.new ? style.newBadge : style.unreadedBadge,
                                    style.Badge
                                ].join(' ')}
                            >
                                {notif.number}
                            </Badge>
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                preserveAspectRatio="xMidYMid meet"
                            >
                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                    fill="#000000" stroke="none"
                                >
                                    <path d="M2390 4904 c-364 -46 -683 -194 -934 -434 -257 -247 -418 -547 -477
                                    -888 -18 -105 -19 -166 -19 -813 l0 -699 -189 -283 c-106 -160 -198 -309 -210
                                    -341 -90 -241 36 -501 280 -577 59 -18 120 -19 1719 -19 1596 0 1660 1 1719
                                    19 201 62 322 237 308 445 -2 45 -13 104 -24 131 -11 28 -105 179 -211 337
                                    l-191 286 -4 739 c-3 715 -4 741 -25 831 -50 215 -125 391 -243 569 -91 136
                                    -284 332 -414 420 -178 120 -395 212 -603 254 -95 19 -396 34 -482 23z"/>
                                    <path d="M1961 610 c36 -103 160 -246 269 -310 111 -66 186 -85 330 -85 104 0
                                    137 4 195 23 175 58 331 200 400 365 l15 37 -609 0 -610 0 10 -30z"/>
                                </g>
                            </svg>
                        </span>
                    </Sidebar>
                </>
            )}   
        </>
    )
}

export default UserLogin
