import React, { useState, useEffect } from 'react'
import style from './Media.module.scss'
import { Container, Carousel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import axios from 'axios'
import { NavLink } from 'react-router-dom'

const Media = () => {

    const { t, i18n } = useTranslation('common')
    const [media, setMedia] = useState([])
    const [isSlider, setIsSlider] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchMedia = async() => {
            setLoading(true)
            const res =  await axios.post(`${process.env.REACT_APP_API_URL}/PORTAL/Sliders/GET_TOP_Sliders`,
                {
                    "Sliders_Count": 5
                }
            )
            setMedia(res.data.Data)
            setLoading(false)
        }
        fetchMedia()
    }, [])

    useEffect(() => {
        if (media[0] && media[0].Portal_Slider_Type == 1) {
            setIsSlider( true )
        } else if (media[0] && media[0].Portal_Slider_Type == 2) {
            setIsSlider( false )
        }
    }, [media])

    return (
        <Container fluid className={[style.sliderContainer, 'p-0'].join(' ')}>
            {isSlider ?
                <Carousel className={style.carousel}>
                    {media.length > 0 && media.map(img => 
                        <Carousel.Item className={style.item} key={img.Portal_Slider_Id}>
                            <img
                                className="d-block w-100"
                                src={img.Portal_Slider_Media_Url}
                                alt="First slide"
                            />
                            <Carousel.Caption className={style.caption}>
                                <p className={style.firstTitle}>{t('sharjah_government')}</p>
                                <p className={style.secondTitle}>
                                    {i18n.language === 'en' ? img.Portal_Slider_Title_EN : img.Portal_Slider_Title_AR}  
                                </p>
                                <p className={style.para}>
                                    {i18n.language === 'en' ? img.Portal_Slider_Content_EN : img.Portal_Slider_Content_AR}  
                                </p>
                                <NavLink 
                                    className={[
                                        style.carouselBtn, 
                                        'mainBtn primarymainBtn',
                                        i18n.language === 'en' ? style.carouselBtnEn : style.carouselBtnAr
                                    ].join(' ')}
                                    to={{
                                        pathname:'/slider_content',
                                        aboutProps: {
                                            sliderTitle: i18n.language === 'en' ? img.Portal_Slider_Title_EN : img.Portal_Slider_Title_AR,
                                            sliderContent: i18n.language === 'en' ? img.Portal_Slider_Content_EN : img.Portal_Slider_Content_AR
                                        }
                                    }} 
                                    exact
                                >
                                    {t('see_more')}
                                    <span>
                                        <svg id="arrow-left-s-line" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                            <path id="Path" d="M0,0H36V36H0Z" fill="none"/>
                                            <path id="Path-2" data-name="Path" d="M4.242,9.546l7.425,7.425L9.546,19.092,0,9.546,9.546,0l2.121,2.121Z" transform="translate(12 8.454)" fill="$white"/>
                                        </svg>
                                    </span>
                                </NavLink>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )}
                </Carousel>
            :
                <ReactPlayer 
                    url={media[0] && media[0].Portal_Slider_Media_Url}
                    loop={true}
                    playing={true}
                    muted={true}
                    width='100%'
                    height='100%'
                    className={style.video}
                />
            }
        </Container>
    )
}

export default Media

