import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import style from "./Menu.module.scss";
import Items from "./Items/Items";
import Icons from "./Icons/Icons";
import UserLogin from './UserLogin/UserLogin'

const Menu = props => {
  const [offset, setOffset] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  let header = document.getElementById("header");

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  useEffect(() => {
    if (header) {
      if (offset > header.offsetTop && offset > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  }, [offset]);

  useEffect(
    () => () => {
      setOffset(0);
    },
    []
  );

  return (
    <Row className={[style.menu, isSticky ? style.sticky : null, isSticky ? "sticky" : null].join(" ")} id="header">
      <Col xl={9} md={8} xs={2} style={{zIndex: '99999999'}}>
        <Items municipal={props.municipal} />
      </Col>

      {/* <Col xl={3} lg={4} md={4} xs={7}> */}
      <Col xl={3} lg={4} md={4} xs={7} className={style.icons}>
        <Icons />
      </Col>

      {/* <Col className="d-md-none d-flex justify-content-center" xs={3}> */}
      <Col className={style.userLogin} xs={3}>
        <UserLogin />
      </Col>
    </Row>
  );
};

export default Menu;
