import React, { useState, useEffect } from "react";
import NewRequestsGrid from "./NewRequestsGrid";
import PopUp from "../../../UIComponents/PopUp/PopUp";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import style from "./../form.module.scss";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";

const NewRequests = props => {
  const { t, i18n } = useTranslation("common");
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState();
  const { user, clientProfiles } = useSelector((state) => state.user);

  const handleClose = () => setShow(false);

  const handleShow = (id) => {
    const res = axios
      .post(
        `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/REQUESTS/GET_CLIENTS_REQUEST_INFO_LIST`,
        {
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      )
      .then((res) => {
        setModalData(res.data.Data);
        setShow(true);
      });
  };

  return (
    <>
      <NewRequestsGrid handleShow={handleShow} dataIsReady={props.dataIsReady} />

      {modalData ? (
        <PopUp show={show} handleClose={handleClose} title={t("new_requests")}>
          <Container>
            <Formik
              initialValues={{
                request_no: modalData?.Request_No,
                request_year: modalData?.Request_Year,
                request_date: moment(modalData?.Request_Date)
                  .lang("en")
                  .format("YYYY-MM-DD"),
                department_name:
                  i18n.language === "en"
                    ? modalData?.Department_Name_EN
                    : modalData?.Department_Name_AR,
                service_name:
                  i18n.language === "en"
                    ? modalData?.Service_Name_EN
                    : modalData?.Service_Name_AR,
                service_code: modalData?.Service_No,
                Service_Version: modalData?.Department_Service_Version_Name,
                service_category:
                  i18n.language === "en"
                    ? modalData?.Service_Category_Name_EN
                    : modalData?.Service_Category_Name_AR,
                dealing_type:
                  i18n.language === "en"
                    ? modalData?.Dealing_Type_Name_EN
                    : modalData?.Dealing_Type_Name_AR,
                request_status:
                  i18n.language === "en"
                    ? modalData?.Request_Status_Name_EN
                    : modalData?.Request_Status_Name_AR,
              }}
            >
              <Form className={style.dashboardData}>
                <Row
                  style={{ textAlign: i18n.language === "ar" ? "start" : null }}
                >
                  <Col md={3} sm={6}>
                    <label className={style.name}>{t("request_no")}</label>
                    <Field
                      className={style.dashboardField}
                      name="request_no"
                      type="text"
                      readOnly
                    />
                  </Col>
                  <Col md={3} sm={6}>
                    <label className={style.name}>{t("request_Year")}</label>
                    <Field
                      className={style.dashboardField}
                      name="request_year"
                      type="text"
                      readOnly
                    />
                  </Col>
                  <Col md={3} sm={6}>
                    <label className={style.name}>{t("request_date")}</label>
                    <Field
                      className={style.dashboardField}
                      name="request_date"
                      type="text"
                      readOnly
                    />
                  </Col>
                  <Col md={3} sm={6}>
                    <label className={style.name}>{t("department_name")}</label>
                    <Field
                      className={style.dashboardField}
                      name="department_name"
                      type="text"
                      readOnly
                    />
                  </Col>
                  <Col sm={12}>
                    <Field
                      name="service_name"
                      type="text"
                      as="textarea"
                      className={[
                        "text-center title mainName",
                        style.dashboardField,
                      ].join(" ")}
                      readOnly
                    />
                  </Col>
                  <Col md={4} sm={6}>
                    <label className={style.name}>{t("service_code")}</label>
                    <Field
                      className={style.dashboardField}
                      name="service_code"
                      type="text"
                      readOnly
                    />
                  </Col>
                  <Col md={4} sm={6}>
                    <label className={style.name}>
                      {t("service_issuance")}
                    </label>
                    <Field
                      className={style.dashboardField}
                      name="Service_Version"
                      type="text"
                      readOnly
                    />
                  </Col>
                  <Col md={4} sm={6}>
                    <label className={style.name}>
                      {t("service_category")}
                    </label>
                    <Field
                      className={style.dashboardField}
                      name="service_category"
                      type="text"
                      readOnly
                    />
                  </Col>
                  <Col md={6} sm={6}>
                    <label className={style.name}>{t("dealing_type")}</label>
                    <Field
                      className={style.dashboardField}
                      name="dealing_type"
                      type="text"
                      as="textarea"
                      readOnly
                    />
                  </Col>
                  <Col md={6} sm={6}>
                    <label className={style.name}>{t("request_status")}</label>
                    <Field
                      className={style.dashboardField}
                      name="request_status"
                      type="text"
                      as="textarea"
                      readOnly
                    />
                  </Col>
                </Row>
              </Form>
            </Formik>
          </Container>
        </PopUp>
      ) : null}
    </>
  );
};

export default React.memo(NewRequests);
