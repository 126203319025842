import { post } from "./base";
import { loadState } from "utils";

export async function updateProfileImage(param, cancelToken) {
  const url = `${"SYSTEM/USERS/PROFILES/UPD_PROFILE_IMAGE"}`;
  const token = loadState("user")?.access_token;
  const options = { headers: { Authorization: `Bearer ${token}` }, cancelToken: cancelToken };
  const res = await post(url, param, options);
  console.log(res);
  return res;
}
