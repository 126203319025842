import React, { Fragment, useState } from "react";
import style from "./Items.module.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import MunicipalsDropdown from "./MunicipalsDropdown/MunicipalsDropdown";
import { loadSession } from "utils";

const MenuItems = (props) => {
  const { t, i18n } = useTranslation("common");
  const [dropOpen, setDropOpen] = useState(false);
  const [respMenuOpen, setRespMenuOpen] = useState(false);
  const seesionMunicipal = loadSession("Municipal");

  const onToggleRespMenu = () => setRespMenuOpen(!respMenuOpen);

  const onCloseDropdown = () => setDropOpen(false);

  const handleScroll = () => setDropOpen(false);

  window.addEventListener("scroll", handleScroll);

  const menuList = (
    <ul className={[style.menuList, respMenuOpen ? style.respOpen : null].join(" ")}>
      <li className={style.menuItem}>
        <NavLink to="/homePage" exact>
          {t("menu_home")}
        </NavLink>
      </li>
      <li className={style.menuItem}>
        <a className={dropOpen ? style.activeLink : null} onClick={() => setDropOpen(!dropOpen)}>
          {t("menu_municipalities_of_sharjah")}
          <span className={style.activeMunicipal}>
            {props.municipal
              ? `- ${props.municipal}`
              : seesionMunicipal
              ? `- ${i18n.language === "ar" ? seesionMunicipal?.Department_Name_AR ?? "" : seesionMunicipal?.Department_Name_EN ?? ""}`
              : null}
          </span>
        </a>
        <MunicipalsDropdown dropOpen={dropOpen} onCloseDropdown={onCloseDropdown} />
      </li>
      <li className={style.menuItem}>
        <NavLink to="/our_services" exact>
          {t("menu_our_services")}
        </NavLink>
      </li>
      {/* <li className={style.menuItem}>
        <NavLink to='/open_data' exact>{t('menu_open_data')}</NavLink>
        <a>{t("menu_open_data")}</a>
      </li> */}
      {/* <li className={style.menuItem}>
        <NavLink to='/circulars_and_decisions' exact>{t('menu_circulars_n_decisions')}</NavLink>
        <a>{t("menu_circulars_n_decisions")}</a>
      </li> */}
      <li className={style.menuItem}>
        <NavLink to="/suggestions_and_complains" exact>
          {t("menu_complaints_n_suggestions")}
        </NavLink>
      </li>
      <li className={style.menuItem}>
        <NavLink to="/decisions_group" exact>
          {t("Resolutions and circulars")}
        </NavLink>
      </li>
    </ul>
  );

  return (
    <>
      <div className={style.barContainer}>
        <span onClick={onToggleRespMenu}>
          {respMenuOpen ? (
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 409.806 409.806" xmlSpace="preserve">
              <g>
                <path
                  d="M228.929,205.01L404.596,29.343c6.78-6.548,6.968-17.352,0.42-24.132c-6.548-6.78-17.352-6.968-24.132-0.42
                                    c-0.142,0.137-0.282,0.277-0.42,0.42L204.796,180.878L29.129,5.21c-6.78-6.548-17.584-6.36-24.132,0.42
                                    c-6.388,6.614-6.388,17.099,0,23.713L180.664,205.01L4.997,380.677c-6.663,6.664-6.663,17.468,0,24.132
                                    c6.664,6.662,17.468,6.662,24.132,0l175.667-175.667l175.667,175.667c6.78,6.548,17.584,6.36,24.132-0.42
                                    c6.387-6.614,6.387-17.099,0-23.712L228.929,205.01z"
                />
              </g>
            </svg>
          ) : (
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
              <g>
                <path d="M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z" />
              </g>
              <g>
                <path d="M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z" />
              </g>
              <g>
                <path d="M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20C512,404.954,503.046,396,492,396z" />
              </g>
            </svg>
          )}
        </span>
      </div>

      <Fragment>{menuList}</Fragment>

      <div className={style.dropdownOverlay} onClick={onCloseDropdown} style={{ display: dropOpen ? "block" : "none" }}></div>
    </>
  );
};

export default MenuItems;
