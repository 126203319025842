import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import style from "./Request.module.scss";
import styleNew from "./NewRequest.module.scss";
import { useSelector } from "react-redux";
import Header from "components/UIComponents/Header/Header";
import InnerTitleCont from "components/UIComponents/InnerTitleCont/InnterTitleState";
import Footer from "components/UIComponents/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ServiceMunicipal from "components/UIComponents/Municipal/ServiceMunicipal";
import moment from "moment";
import { Accordion, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { deleteDraftAttachment, downloadAttachment } from "api/serviceAPI";
import * as icons from "./icons";
import {
  Attachment,
  Csv,
  Doc,
  Jpg,
  Mp3,
  Mp4,
  Pdf,
  Png,
  Text,
  Zip,
} from "components/UIComponents/FileIcons";
import Grid from "./../../components/UIComponents/Grid/index";
import { Column } from "devextreme-react/data-grid";
import { useHistory } from "react-router-dom";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
const NewClientRequestInfo = (props) => {
  const { t, i18n } = useTranslation("common");

  const [inputsDataList, setInputsDataList] = useState([]);
  const [inputsDataState, setInputsDataState] = useState(1);
  const [attachementsDataState, setAttachementsDataState] = useState(0);

  const [attachmentsDataList, setAttachmentsDataList] = useState([]);
  const [isTextExpanded, setIsTextExpanded] = useState({});

  useEffect(() => {
    let inputsArray = [];
    let attachementsArray = [];
    let inputsIsExpandedObject = {};
    let attachementsIsExpandedObject = {};

    props.inputsDataValue.map((ele) => {
      let inputData = props?.inputsData.filter(
        (ele1) =>
          ele1.Department_Service_Detail_Id === ele.Department_Service_Detail_Id
      );

      let title =
        i18n.language === "ar"
          ? inputData[0]?.Department_Service_Detail_Title_AR
          : inputData[0]?.Department_Service_Detail_Title_EN;

      let value =
        i18n.language === "ar"
          ? ele.Service_Detail_Value_AR
          : ele.Service_Detail_Value_EN;
      inputsIsExpandedObject[title] = false;
      return inputsArray.push({ title: title, value: value });
    });

    props.attachmentsDataValue.map((ele) => {
      let attachementData = props?.attachmentsData.filter(
        (ele1) =>
          ele1.Department_Service_Detail_Id === ele.Department_Service_Detail_Id
      );

      let title =
        i18n.language === "ar"
          ? attachementData[0]?.Department_Service_Detail_Title_AR
          : attachementData[0]?.Department_Service_Detail_Title_EN;

      attachementsIsExpandedObject[title] = false;

      let value = ele.Files_Data;
      return attachementsArray.push({ title: title, value: value });
    });

    setIsTextExpanded({
      ...inputsIsExpandedObject,
      ...attachementsIsExpandedObject,
    });
    setInputsDataList(inputsArray);
    setAttachmentsDataList(attachementsArray);
  }, [
    props.inputsDataValue,
    props.inputsData,
    props.attachmentsData,
    props.attachmentsDataValue,
  ]);

  const handleExpandLabel = (title) => {
    setIsTextExpanded((prev) => ({ ...prev, [title]: !prev[title] }));
  };

  const mapUploadedFiles = (files = [], title) => {
    return files.map((uploadedFile) => (
      <div
        key={uploadedFile.File_Code}
        style={{ background: "#F8EFD9" }}
        className="d-flex justify-content-between py-1"
      >
        <div className="d-flex flex-wrap  w-100">
          {getFileIcon(uploadedFile.File_Content)}
          <span
            onClick={() => handleExpandLabel(title)}
            style={{ color: "black" }}
            className={`${styleNew.fileContent}  ${!isTextExpanded[title] ? styleNew.clientRequestInfo : ""
              } mx-1`}
          >
            {uploadedFile.File_Name}
          </span>
        </div>
      </div>
    ));
  };

  return (
    <>
      <Container fluid="lg" className="pageContainer">
        <Row className="mb-5">
          <Col md={12}>
            <div className={`${styleNew.container}`}>
              {inputsDataList.length > 0 ? (
                <Row>
                  <Col>
                    <Accordion activeKey={inputsDataState}>
                      <Card className={style.accCard}>
                        <Card.Header className={style.accHeader}>
                          <Accordion.Toggle
                            eventKey={inputsDataState}
                            className={style.accBtn}
                            onClick={() =>
                              setInputsDataState(inputsDataState === 0 ? 1 : 0)
                            }
                          >
                            <div className={styleNew.titleContainer}>
                              <div className={styleNew.title}>
                                <div className={styleNew.completed}>
                                  {icons.stepperIcons[1]}
                                </div>
                                <div className={style.stepHeader}>
                                  <span className={style.stepTitle}>
                                    {t("serviceInput")}
                                  </span>
                                </div>
                              </div>
                              <span
                                className={[
                                  style.accArrow,
                                  inputsDataState === 1 ? style.upArrow : null,
                                ].join(" ")}
                              >
                                {icons.numberDown}
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey={inputsDataState}>
                          <Row>
                            <Card.Body className="pt-0 w-100">
                              <Col
                                xs={12}
                                className={styleNew.contentContainer}
                              >
                                <Row>
                                  <div className={styleNew.itemsContainer}>
                                    {inputsDataList
                                      .slice(
                                        0,
                                        inputsDataList.length % 2 !== 0
                                          ? inputsDataList.length / 2 + 1
                                          : inputsDataList.length / 2
                                      )
                                      .map((ele, index) => (
                                        <div className="mb-4">
                                          <span
                                            onClick={() =>
                                              handleExpandLabel(ele.title)
                                            }
                                            className={
                                              isTextExpanded[ele.title]
                                                ? styleNew.textLabel
                                                : styleNew.textExpandedLabel
                                            }
                                          >
                                            {ele.title}
                                          </span>
                                          <span
                                            onClick={() =>
                                              handleExpandLabel(ele.title)
                                            }
                                            className={
                                              isTextExpanded[ele.title]
                                                ? styleNew.textLabel
                                                : styleNew.textExpandedLabel
                                            }
                                          >
                                            {ele.value}
                                          </span>
                                        </div>
                                      ))}
                                  </div>
                                  {/* {inputsDataList.length > 1 && (
                                    <div style={{ height: "100px" }}>
                                      <div className={styleNew.lineWay}></div>
                                    </div>
                                  )} */}
                                  <div
                                    style={{
                                      borderRight: "1px dashed #4E6B31",
                                      height: "100%",
                                    }}
                                    className={styleNew.itemsContainer}
                                  >
                                    {inputsDataList
                                      .slice(
                                        inputsDataList.length % 2 !== 0
                                          ? inputsDataList.length / 2 + 1
                                          : inputsDataList.length / 2,
                                        inputsDataList.length
                                      )
                                      .map((ele, index) => (
                                        <div className="mb-4">
                                          <span
                                            onClick={() =>
                                              handleExpandLabel(ele.title)
                                            }
                                            className={
                                              isTextExpanded[ele.title]
                                                ? styleNew.textLabel
                                                : styleNew.textExpandedLabel
                                            }
                                          >
                                            {ele.title}
                                          </span>
                                          <span
                                            onClick={() =>
                                              handleExpandLabel(ele.title)
                                            }
                                            className={
                                              isTextExpanded[ele.title]
                                                ? styleNew.textLabel
                                                : styleNew.textExpandedLabel
                                            }
                                          >
                                            {ele.value}
                                          </span>
                                        </div>
                                      ))}
                                  </div>
                                </Row>
                              </Col>
                            </Card.Body>
                          </Row>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Col>
                </Row>
              ) : null}

              <div>{icons.horizontalLine}</div>
              {attachmentsDataList.length > 0 ? (
                <Row>
                  <Col>
                    <Accordion activeKey={attachementsDataState}>
                      <Card className={style.accCard}>
                        <Card.Header className={style.accHeader}>
                          <Accordion.Toggle
                            eventKey={attachementsDataState}
                            className={style.accBtn}
                            onClick={() =>
                              setAttachementsDataState(
                                attachementsDataState === 0 ? 1 : 0
                              )
                            }
                          >
                            <div className={styleNew.titleContainer}>
                              <div className={styleNew.title}>
                                <div className={styleNew.completed}>
                                  {icons.stepperIcons[2]}
                                </div>
                                <div className={style.stepHeader}>
                                  <span className={style.stepTitle}>
                                    {t("ServiceAttachments")}
                                  </span>
                                </div>
                              </div>
                              <span
                                className={[
                                  style.accArrow,
                                  inputsDataState === 1 ? style.upArrow : null,
                                ].join(" ")}
                              >
                                {icons.numberDown}
                              </span>
                            </div>
                          </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey={attachementsDataState}>
                          <Row>
                            <Card.Body className="pt-0 w-100">
                              <Col
                                xs={12}
                                className={styleNew.contentContainer}
                              >
                                <Row>
                                  <div className={styleNew.itemsContainer}>
                                    {attachmentsDataList
                                      .slice(
                                        0,
                                        attachmentsDataList.length % 2 !== 0
                                          ? attachmentsDataList.length / 2 + 1
                                          : attachmentsDataList.length / 2
                                      )
                                      .map((ele, index) => (
                                        <div className="mb-4">
                                          <span
                                            onClick={() =>
                                              handleExpandLabel(ele.title)
                                            }
                                            className={
                                              isTextExpanded[ele.title]
                                                ? styleNew.textLabel
                                                : styleNew.textExpandedLabel
                                            }
                                          >
                                            {ele.title}
                                          </span>
                                          <span className="flex-column">
                                            {mapUploadedFiles(
                                              ele.value,
                                              ele.title
                                            )}
                                          </span>
                                        </div>
                                      ))}
                                  </div>
                                  {/* {attachmentsDataList.length > 1 && (
                                    <div className={styleNew.lineWay}></div>
                                  )} */}
                                  <div
                                    style={{
                                      borderRight: "1px dashed #4E6B31",
                                      height: "100%",
                                    }}
                                    className={styleNew.itemsContainer}
                                  >
                                    {attachmentsDataList
                                      .slice(
                                        attachmentsDataList.length % 2 !== 0
                                          ? attachmentsDataList.length / 2 + 1
                                          : attachmentsDataList.length / 2,
                                        attachmentsDataList.length
                                      )
                                      .map((ele, index) => (
                                        <div className="mb-4">
                                          <span
                                            onClick={() =>
                                              handleExpandLabel(ele.title)
                                            }
                                            className={
                                              isTextExpanded[ele.title]
                                                ? styleNew.textLabel
                                                : styleNew.textExpandedLabel
                                            }
                                          >
                                            {ele.title}
                                          </span>
                                          <span className="flex-column">
                                            {mapUploadedFiles(
                                              ele.value,
                                              ele.title
                                            )}
                                          </span>
                                        </div>
                                      ))}
                                  </div>
                                </Row>
                              </Col>
                            </Card.Body>
                          </Row>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewClientRequestInfo;

const getFileIcon = (fileType) => {
  return (
    {
      "image/jpeg": <Jpg />,
      "image/png": <Png />,
      "video/mp4": <Mp4 />,
      "application/pdf": <Pdf />,
      "application/vnd.ms-powerpoint": <Doc />,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        <Doc />,
      "application/msword": <Doc />,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        <Doc />,
      "audio/mpeg": <Mp3 />,
      "application/zip": <Zip />,
      "text/csv": <Csv />,
      "text/plain": <Text />,
    }[fileType] ?? <Attachment />
  );
};
