import React, { useMemo } from "react";
import { Button } from "devextreme-react/button";
import { useTranslation } from "react-i18next";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  ColumnChooser,
  Scrolling
} from "devextreme-react/data-grid";
import "./Grid.scss";


const CustomGrid = ({ children, sourceData, addEnabled = false, editEnabled = true, deleteEnabled = false, viewEnabled = false, onAdd, onEdit, onView, onDelete }) => {

  const { t, i18n } = useTranslation("common")

  const handleEdit = ({ row }) => {
    onEdit(row);
  };
  const handleView = ({ row }) => {
    onView(row);
  };
  const handleAdd = () => {
    // onAdd();
  };
  const handleDelete = ({ row }) => {
    onDelete(row);
  };

  const ColumnButtons = useMemo(() => {
    let buttonsList = [];
    if (viewEnabled) {
      buttonsList = buttonsList.concat([
        {
          hint: t('Display'),
          icon: "detailslayout",
          visible: true,
          onClick: handleView,
        },
      ]);
    }
    if (editEnabled) {
      buttonsList = buttonsList.concat([
        {
          hint: t('edit'),
          icon: "edit",
          visible: true,
          onClick: handleEdit,
        },
      ]);
    }
    if (addEnabled) {
      buttonsList = buttonsList.concat([
        {
          hint: t('Add'),
          icon: "add",
          visible: true,
          onClick: handleAdd,
        },
      ]);
    }
    if (deleteEnabled) {
      buttonsList = buttonsList.concat([
        {
          hint: t('delete'),
          icon: "trash",
          visible: true,
          onClick: handleDelete,
        },
      ]);
    }
    // console.log(buttonsList);
    return buttonsList;
  }, [addEnabled, deleteEnabled, editEnabled, viewEnabled, handleEdit, handleDelete, handleAdd, handleView]);

  return (
    <>
      <div style={{ width: '100%' }}>
        {addEnabled && (
          <div className="flex justify-end items-center mx-4 text-sm">
            <Button icon="plus" className="dx-rounded-full" onClick={handleAdd} />
          </div>
        )}

        <DataGrid
          id="dataGrid"
          width={'100%'}
          dataSource={sourceData}
          showBorders={false}
          showScrollbar={false}
          hoverStateEnabled={true}
          allowColumnReordering={true}
          ShowHorizontalLines={false}
          rowAlternationEnabled={false}
          // columnMinWidth={100}
          // columnAutoWidth={true}
          showColumnLines={false}
          showRowLines={true}
          // columnHidingEnabled={true}
          allowColumnResizing={true}
          columnResizingMode={'widget'}
          // columnResizingMode='nextColumn'
          noDataText={t('There is no data')}
          rtlEnabled={i18n.language === 'ar' ? true : false}
        >
          <FilterRow visible={true} applyFilter={"auto"}  />

          <ColumnChooser enabled={true} allowSearch={true} />

          <SearchPanel
            visible={true}
            width={240}
            placeholder={t('search_for')}
          />

          <HeaderFilter visible={true} />

          {children}

          {ColumnButtons.length > 0 &&
            <Column
              cssClass="outlinedColumn"
              fixed={true}
              type="buttons"
              buttons={ColumnButtons}
              caption={t('Details')}
              alignment={"center"}
              fixedPosition={i18n.language === 'ar' ? 'left' : 'right'}
            />
          }

          {/* <Scrolling
            columnRenderingMode="virtual"
            useNative="false"
            scrollByContent="true"
          /> */}

          <Paging defaultPageSize={10} />
        </DataGrid>
      </div>
    </>
  );
};
export default CustomGrid;
