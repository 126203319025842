export const handleNonDecimalEvent = (event) => {
  if (event.which < 48 || event.which > 57) {
    event.preventDefault();
  }
};
export const loadState = (itemName) => {
  try {
    const serializedState = localStorage.getItem(itemName);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state, itemName) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(itemName, serializedState);
  } catch (error) {
    console.error(error);
  }
};

export const removeState = (itemName) => {
  try {
    localStorage.removeItem(itemName);
  } catch (error) {
    console.error(error);
  }
};

export const loadSession = (itemName) => {
  try {
    const serializedState = sessionStorage.getItem(itemName);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveSession = (state, itemName) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(itemName, serializedState);
  } catch (error) {
    console.error(error);
  }
};

export const removeSession = (itemName) => {
  try {
    sessionStorage.removeItem(itemName);
  } catch (error) {
    console.error(error);
  }
};

export const isTokenExpired = (expirationDate) => {
  const now = new Date();
  const expireDate = new Date(expirationDate);
  return now.getTime() > expireDate.getTime();
};

export const getTitme = (date) => {
  const time = new Date(date);
  return time;
};

export const removeProperty = (key, { [key]: _, ...rest }) => rest;

export function isArabicLetter(str) {
  if (str.length >= 1) {
    return str.replace(/[^[\u0600-\u06ff ][^0-9- ]+|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]]/g, "");
  }
  return "";
}
