import React, { useState, useEffect } from "react";
import DraftsGrid from "./DraftsGrid";
import PopUp from "../../../UIComponents/PopUp/PopUp";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import style from "./../form.module.scss";
import { deleteDraft } from "api/serviceAPI";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

const Drafts = React.memo(props => {
  const { t, i18n } = useTranslation("common");
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setdeleteMode] = useState(false);
  const [data, setData] = useState();
  const { user, clientProfiles } = useSelector((state) => state.user);

  const getDraftsList = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/DASHBOARD/GET_CLIENT_DASHBOARD_DRAFTS_REQUESTS_LIST`,
      {
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        PageIndex: "1",
        PageCount: "1000",
      },
      {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      }
    ).then(data => {
      setData(data.data.Data.Data)
      props.dataIsReady(false)
    })
  };

  useEffect(() => {
    getDraftsList();
  }, []);

  const handleClose = () => setShow(false);

  const handleDeleteClose = () => {
    deleteDraft({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Draft_Id: modalData?.Request_Draft_Id
    });
    setShow(false)
    getDraftsList();
  };

  const handleShow = (data, mode) => {
    setShow(true);
    setModalData(data);
    if (mode === "delete") {
      setdeleteMode(true);
      setEditMode(false);
    } else {
      setdeleteMode(false);
      setEditMode(true);
    }
  };

  return (
    <>
      <DraftsGrid data={data} handleShow={handleShow} dataIsReady={props.dataIsReady} />

      {modalData ? (
        <PopUp
          show={show} handleClose={handleClose}
          title={t('drafts')}
          smallModal={deleteMode ? true : false}
        >
          <Container>
            {editMode ? (
              <Formik
                initialValues={{
                  drafts_number: modalData?.Request_Draft_Id,
                  drafts_date: moment(modalData?.Request_Draft_Date).lang("en").format("YYYY-MM-DD"),
                  service_issuance: modalData?.Department_Service_Version_Name,
                  department_name: i18n.language === "en" ? modalData?.Department_Name_EN : modalData?.Department_Name_AR,
                  service_name: i18n.language === "en" ? modalData?.Service_Name_EN : modalData?.Service_Name_AR,
                }}>
                <Form className={style.dashboardData}>
                  <Row style={{ textAlign: i18n.language === "ar" ? "start" : null }}>
                    <Col md={3} sm={6}>
                      <label className={style.name}>{t("drafts_number")}</label>
                      <Field className={style.dashboardField} name="drafts_number" type="text" />
                    </Col>
                    <Col md={3} sm={6}>
                      <label className={style.name}>{t("drafts_date")}</label>
                      <Field className={style.dashboardField} name="drafts_date" type="date" />
                    </Col>
                    <Col md={3} sm={6}>
                      <label className={style.name}>{t("service_issuance")}</label>
                      <Field className={style.dashboardField} name="service_issuance" type="text" />
                    </Col>
                    <Col md={3} sm={6}>
                      <label className={style.name}>{t("department_name")}</label>
                      <Field className={style.dashboardField} name="department_name" type="text" />
                    </Col>
                    <Col sm={12}>
                      <Field name="service_name" as="textarea" className={['text-center title mainName', style.dashboardField].join(' ')} />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <button className="mainBtn lgBtn primarymainBtn">{t("save")}</button>
                    </Col>
                  </Row>
                </Form>
              </Formik>
            ) : (
              <>
                <Row>
                  <Col className='text-center'>
                    <p className={style.confirmDelete}>
                      {t("confirm_draft_delete")} {modalData?.Request_Draft_Id}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="d-flex justify-content-end">
                    <button type="button" className="mainBtn smBtn primarymainBtn" onClick={handleDeleteClose}>
                      {t("delete")}
                    </button>
                    <button type="button" className="mainBtn smBtn secondarymainBtn outlined mx-2" onClick={handleClose}>
                      {t("cancel")}
                    </button>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </PopUp>
      ) : null}
    </>
  );
});

export default Drafts;
