import React, { useState, useEffect } from 'react'
import style from './Housing.module.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import Card from './Card'

const Housing = () => {

    const { t, i18n } = useTranslation('common')

    return (
        <Container 
            className={[
                style.backWrap, 
                style.housing
            ].join(' ')}
            fluid
        >

            <Container>

                <Row>
                    <Col>
                        <p className={style.heading}>{t('Housing Models')}</p>
                    </Col>
                </Row>

                <Row className={style.headerRow}>
                    <Col md={9}>
                        <p 
                            className={[style.para, 'mb-md-0'].join(' ')}
                        >
                            {t('Housing Para')}
                        </p>
                    </Col>

                    <Col md={3}>
                        <NavLink
                            className={style.seemore}
                            exact 
                            to='/housing_models' 
                        >
                            <div>{t('see_more')}</div>
                            
                            <div 
                                className={i18n.language == 'en' ? style.engArrow : null}
                            >
                                <svg id="arrow-left-s-line" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                    <path id="Path" d="M0,0H36V36H0Z" fill="none"/>
                                    <path id="Path-2" data-name="Path" d="M4.242,9.546l7.425,7.425L9.546,19.092,0,9.546,9.546,0l2.121,2.121Z" transform="translate(12 8.454)" fill="$white"/>
                                </svg>
                            </div>
                        </NavLink>
                    </Col>
                </Row>

                <Card limited={true} />

            </Container>
            
        </Container>
    )
}

export default Housing
