import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import style from './Housing.module.scss'
import axios from 'axios'
import { NavLink } from 'react-router-dom'
import fallbackImg from './../../../global/images/housing_fall_back.jpg'

const Card = props => {

    const { t, i18n } = useTranslation('common')
    const [data, setData] = useState()
    const [cardsNo, setCardsNo] = useState(6);
    const { formVal, limited } = props

    useEffect(() => {
        handleData()
    }, [formVal])

    const handleData = async() => {
        const res =  await axios.post(
            `${process.env.REACT_APP_API_URL}/PORTAL/APPROVINGCHARTS/GET_APPROVING_CHART_LIST`,
            {
                'From_Area': formVal?.From_Area,
                'To_Area': formVal?.To_Area,
                'Floor_No': formVal?.Floor_No,
                'Room_No': formVal?.Room_No,
                'From_Cost': formVal?.From_Cost,
                'To_Cost': formVal?.To_Cost,
                'Area_Type_Id': formVal?.Area_Type_Id,
                'PageIndex': '1',
                'PageSize': limited ? '3' : '100'
            }
        )
        setData(res.data.Data.Data)
    }

    const handleSeeMore = () => {
        setCardsNo(cardsNo + 6)
    }

    const handleAscSorting = sortType => { 
        let newArr = []
        data && data.sort((a, b) => {
            if (sortType === 'Bedrooms') return a.Approving_Chart_Bedroom_No - b.Approving_Chart_Bedroom_No
            else if (sortType === 'Floors') return a.Approving_Chart_Floors_No - b.Approving_Chart_Floors_No
            else if (sortType === 'space') return a.Approving_Chart_Area - b.Approving_Chart_Area
        }).map(d => newArr.push(d))
        setData(newArr)
    }

    const handleDescSorting = sortType => {
        let newArr = []
        data && data.sort((a, b) => {
            if (sortType === 'Bedrooms') return b.Approving_Chart_Bedroom_No - a.Approving_Chart_Bedroom_No
            else if (sortType === 'Floors') return b.Approving_Chart_Floors_No - a.Approving_Chart_Floors_No
            else if (sortType === 'space') return b.Approving_Chart_Area - a.Approving_Chart_Area
        }).map(d => newArr.push(d))
        setData(newArr)
    }

    return (
        <>
            {
                !limited ?
                    <Row className={style.sorting}>
                        <Col lg={5} md={3}>
                            <p>{t('All designs')}</p>
                        </Col>

                        <Col lg={7} md={9} className='px-md-0'>
                            <Row>
                                <Col>
                                    <div className={style.sortingBtn}>
                                        <p className={style.label}>{t('Bedrooms')}</p>
                                        <div>
                                            <span 
                                                className={style.firteringIcon}
                                                onClick={() => handleAscSorting('Bedrooms')}
                                            >
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 492 492" xmlSpace="preserve"
                                                >
                                                    <g>
                                                        <g>
                                                            <path d="M442.627,185.388L265.083,7.844C260.019,2.78,253.263,0,245.915,0c-7.204,0-13.956,2.78-19.02,7.844L49.347,185.388
                                                                c-10.488,10.492-10.488,27.568,0,38.052l16.12,16.128c5.064,5.06,11.82,7.844,19.028,7.844c7.204,0,14.192-2.784,19.252-7.844
                                                                l103.808-103.584v329.084c0,14.832,11.616,26.932,26.448,26.932h22.8c14.832,0,27.624-12.1,27.624-26.932V134.816l104.396,104.752
                                                                c5.06,5.06,11.636,7.844,18.844,7.844s13.864-2.784,18.932-7.844l16.072-16.128C453.163,212.952,453.123,195.88,442.627,185.388z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>

                                            <span 
                                                className={style.firteringIcon}
                                                onClick={() => handleDescSorting('Bedrooms')}
                                            >
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 492 492" xmlSpace="preserve"
                                                >
                                                    <g>
                                                        <g>
                                                            <path d="M442.668,268.536l-16.116-16.12c-5.06-5.068-11.824-7.872-19.024-7.872c-7.208,0-14.584,2.804-19.644,7.872
                                                                L283.688,355.992V26.924C283.688,12.084,272.856,0,258.02,0h-22.804c-14.832,0-28.404,12.084-28.404,26.924v330.24
                                                                L102.824,252.416c-5.068-5.068-11.444-7.872-18.652-7.872c-7.2,0-13.776,2.804-18.84,7.872l-16.028,16.12
                                                                c-10.488,10.492-10.444,27.56,0.044,38.052l177.576,177.556c5.056,5.056,11.84,7.856,19.1,7.856h0.076
                                                                c7.204,0,13.972-2.8,19.028-7.856l177.54-177.552C453.164,296.104,453.164,279.028,442.668,268.536z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </Col>

                                <Col>
                                    <div className={style.sortingBtn}>
                                        <p className={style.label}>{t('number of floors')}</p>
                                        <div>
                                            <span 
                                                className={style.firteringIcon}
                                                onClick={() => handleAscSorting('Floors')}
                                            >
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 492 492" xmlSpace="preserve"
                                                >
                                                    <g>
                                                        <g>
                                                            <path d="M442.627,185.388L265.083,7.844C260.019,2.78,253.263,0,245.915,0c-7.204,0-13.956,2.78-19.02,7.844L49.347,185.388
                                                                c-10.488,10.492-10.488,27.568,0,38.052l16.12,16.128c5.064,5.06,11.82,7.844,19.028,7.844c7.204,0,14.192-2.784,19.252-7.844
                                                                l103.808-103.584v329.084c0,14.832,11.616,26.932,26.448,26.932h22.8c14.832,0,27.624-12.1,27.624-26.932V134.816l104.396,104.752
                                                                c5.06,5.06,11.636,7.844,18.844,7.844s13.864-2.784,18.932-7.844l16.072-16.128C453.163,212.952,453.123,195.88,442.627,185.388z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>

                                            <span 
                                                className={style.firteringIcon}
                                                onClick={() => handleDescSorting('Floors')}
                                            >
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 492 492" xmlSpace="preserve"
                                                >
                                                    <g>
                                                        <g>
                                                            <path d="M442.668,268.536l-16.116-16.12c-5.06-5.068-11.824-7.872-19.024-7.872c-7.208,0-14.584,2.804-19.644,7.872
                                                                L283.688,355.992V26.924C283.688,12.084,272.856,0,258.02,0h-22.804c-14.832,0-28.404,12.084-28.404,26.924v330.24
                                                                L102.824,252.416c-5.068-5.068-11.444-7.872-18.652-7.872c-7.2,0-13.776,2.804-18.84,7.872l-16.028,16.12
                                                                c-10.488,10.492-10.444,27.56,0.044,38.052l177.576,177.556c5.056,5.056,11.84,7.856,19.1,7.856h0.076
                                                                c7.204,0,13.972-2.8,19.028-7.856l177.54-177.552C453.164,296.104,453.164,279.028,442.668,268.536z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </Col>

                                <Col>
                                    <div className={style.sortingBtn}>
                                        <p className={style.label}>{t('space')}</p>
                                        <div>
                                            <span 
                                                className={style.firteringIcon}
                                                onClick={() => handleAscSorting('space')}
                                            >
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 492 492" xmlSpace="preserve"
                                                >
                                                    <g>
                                                        <g>
                                                            <path d="M442.627,185.388L265.083,7.844C260.019,2.78,253.263,0,245.915,0c-7.204,0-13.956,2.78-19.02,7.844L49.347,185.388
                                                                c-10.488,10.492-10.488,27.568,0,38.052l16.12,16.128c5.064,5.06,11.82,7.844,19.028,7.844c7.204,0,14.192-2.784,19.252-7.844
                                                                l103.808-103.584v329.084c0,14.832,11.616,26.932,26.448,26.932h22.8c14.832,0,27.624-12.1,27.624-26.932V134.816l104.396,104.752
                                                                c5.06,5.06,11.636,7.844,18.844,7.844s13.864-2.784,18.932-7.844l16.072-16.128C453.163,212.952,453.123,195.88,442.627,185.388z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>

                                            <span 
                                                className={style.firteringIcon}
                                                onClick={() => handleDescSorting('space')}
                                            >
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 492 492" xmlSpace="preserve"
                                                >
                                                    <g>
                                                        <g>
                                                            <path d="M442.668,268.536l-16.116-16.12c-5.06-5.068-11.824-7.872-19.024-7.872c-7.208,0-14.584,2.804-19.644,7.872
                                                                L283.688,355.992V26.924C283.688,12.084,272.856,0,258.02,0h-22.804c-14.832,0-28.404,12.084-28.404,26.924v330.24
                                                                L102.824,252.416c-5.068-5.068-11.444-7.872-18.652-7.872c-7.2,0-13.776,2.804-18.84,7.872l-16.028,16.12
                                                                c-10.488,10.492-10.444,27.56,0.044,38.052l177.576,177.556c5.056,5.056,11.84,7.856,19.1,7.856h0.076
                                                                c7.204,0,13.972-2.8,19.028-7.856l177.54-177.552C453.164,296.104,453.164,279.028,442.668,268.536z"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                : false
            }

            <Row
                className={limited ? 'justify-content-center' : null}
            >
                {
                    !limited ?
                        data && data.slice(0, cardsNo).map(card => <ModelCard key={card.Approving_Chart_Id} card={card} />)
                    :
                        data && data.map(card => <ModelCard key={card.Approving_Chart_Id} card={card} />)
                }

            </Row>

            {
                !limited && data?.length >= 6 && cardsNo <= data?.length?
                    <Row className='justify-content-center my-2'>
                        <Col md={3}>
                            <button
                                className='mainBtn secondarymainBtn outlined py-2 w-100'
                                onClick={() => handleSeeMore()}
                            >
                                {t('load more')}
                            </button>
                        </Col>
                    </Row>
                : false
            }
        </>
    )
}

export default Card

const ModelCard = props => {

    const { t, i18n } = useTranslation('common')
    const { card } = props

    return (
        <Col 
            lg={4} md={6}
        >
            <NavLink 
                className='cardLink'
                exact
                to={`../house_model/${card.Approving_Chart_Id}`}
            >
                <div className='houseCard'>
                    <img className='cardImg' src={card.Approving_Chart_Image_URL ? card.Approving_Chart_Image_URL : fallbackImg} />
                    <div className='cardInfo'>
                        <p className='cardTitle'>{card.Approving_Chart_Name}</p>
                        <p className='cardPara'>
                            {i18n.language == 'ar' ? card.Approving_Chart_Floors_Description_AR : card.Approving_Chart_Floors_Description_EN}    
                        </p>
                    </div>
                    <div 
                        className={[
                            'cardFooter', 
                            i18n.language == 'en' ? 'cardFooterEN' : 'cardFooterAR'
                        ].join(' ')}>
                        {
                            card.Approving_Chart_Bedroom_No ?
                                <div className='footerInfo'>
                                    <span className={style.footerInfoIcon}>
                                        <svg width="21px" height="13px" viewBox="0 0 21 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <title>bed</title>
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="2" transform="translate(-1514.000000, -1798.000000)" fill="#58743A" fillRule="nonzero">
                                                    <g id="Group-3" transform="translate(1197.000000, 1397.000000)">
                                                        <g id="bed" transform="translate(317.000000, 401.000000)">
                                                            <path d="M2.39076749,3.92334558 C2.39076749,2.85585269 3.28242953,1.98733938 4.37847156,1.98733938 L8.84039103,1.98733938 C9.53323918,1.98733938 10.1441179,2.33464305 10.5,2.8596222 C10.8559256,2.3346007 11.4667608,1.98733938 12.159609,1.98733938 L16.6215719,1.98733938 C17.7175705,1.98733938 18.6092325,2.85585269 18.6092325,3.92334558 L18.6092325,4.01250098 L19.1068325,4.01250098 L19.1068325,2.12460904 C19.1068325,0.953094456 18.1282873,0 16.9254893,0 L4.0744672,0 C2.87166925,0 1.89312397,0.953094456 1.89312397,2.12460904 L1.89312397,4.01250098 L2.39072401,4.01250098 L2.39072401,3.92334558 L2.39076749,3.92334558 Z" id="Path"></path>
                                                            <path d="M20.1645642,4.91654938 L0.835392318,4.91654938 C0.37475462,4.91654938 4.013321e-14,5.28155707 4.013321e-14,5.73021412 L4.013321e-14,10.4537135 C4.013321e-14,10.9023705 0.37475462,11.2673782 0.835392318,11.2673782 L1.18314269,11.2673782 L0.772251949,12.3968775 C0.68662978,12.6321969 0.813127946,12.890557 1.05468769,12.9739099 C1.10595663,12.9915715 1.15826921,13 1.20971209,13 C1.40074215,13 1.57972683,12.8842039 1.64717223,12.6987776 L2.16790634,11.2673359 L18.8320937,11.2673359 L19.3528278,12.6987776 C19.4202732,12.8842039 19.5992144,13 19.7902879,13 C19.8417308,13 19.8940434,12.9915715 19.9453123,12.9739099 C20.1869155,12.8905146 20.3133702,12.6321546 20.2277481,12.3968775 L19.8168573,11.2673782 L20.1646077,11.2673782 C20.6252454,11.2673782 21,10.9023705 21,10.4537135 L21,5.73021412 C21,5.28155707 20.6252454,4.91654938 20.1645642,4.91654938 Z" id="Path"></path>
                                                            <path d="M12.159609,2.89143014 C11.5754301,2.89143014 11.1001379,3.35431816 11.1001379,3.92330323 L11.1001379,4.01245862 L17.6809995,4.01245862 L17.6809995,3.92330323 C17.6809995,3.35431816 17.2057508,2.89143014 16.6215719,2.89143014 L12.159609,2.89143014 Z" id="Path"></path>
                                                            <path d="M3.31900051,3.92334558 L3.31900051,4.01245862 L9.89986209,4.01245862 L9.89986209,3.92330323 C9.89986209,3.35431816 9.4246134,2.89143014 8.84039103,2.89143014 L4.37847156,2.89143014 C3.7942492,2.89143014 3.31900051,3.35436052 3.31900051,3.92334558 Z" id="Path"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <span>{card.Approving_Chart_Bedroom_No} {t('rooms')}</span>
                                </div>
                            : false
                        }

                        {
                            card.Approving_Chart_Floors_No ?
                                <div className='footerInfo'>
                                    <span className={style.footerInfoIcon}>
                                        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <title>layers</title>
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="2" transform="translate(-1392.000000, -1795.000000)" fill="#58743A" fillRule="nonzero">
                                                    <g id="Group-3" transform="translate(1197.000000, 1397.000000)">
                                                        <g id="layers" transform="translate(195.000000, 398.000000)">
                                                            <path d="M17.7180562,8.46790988 C17.6999005,8.45574286 17.6811798,8.44451757 17.6618565,8.43427167 L16.3845188,7.74640237 L9.30087109,11.4969682 C9.11268498,11.5966397 8.88735885,11.5966397 8.69917274,11.4969682 L1.61556273,7.74640237 L0.338187351,8.43427167 C0.0277330009,8.59907251 -0.0903918282,8.98434863 0.0744028552,9.29481458 C0.0846483761,9.31410099 0.0958732483,9.33286003 0.108039804,9.35101638 L9.00002192,14.0588208 L17.892004,9.35101638 C18.0878366,9.05912112 18.0099782,8.66374978 17.7180562,8.46790988 Z" id="Path"></path>
                                                            <path d="M17.9222509,12.5519196 C17.8626988,12.4415878 17.7721842,12.3510697 17.6618941,12.2915531 L16.3845564,11.6036838 L9.30090876,15.3542496 C9.11272265,15.4539212 8.88739652,15.4539212 8.69921041,15.3542496 L1.61556273,11.6036838 L0.338187351,12.2915531 C0.025736631,12.4601961 -0.0908061691,12.8501809 0.0778305846,13.1626433 C0.137382675,13.2729751 0.227897332,13.3634931 0.338187351,13.4230098 L8.69533067,17.923184 C8.88555082,18.0256053 9.11453068,18.0256053 9.30475083,17.923184 L17.6618941,13.4230098 C17.9743072,13.2543667 18.0908877,12.864382 17.9222509,12.5519196 Z" id="Path"></path>
                                                            <path d="M17.7180562,4.61062845 C17.6999005,4.59846144 17.6811798,4.58723615 17.6618565,4.57699024 L9.30475083,0.0768160268 C9.11453068,-0.0256053423 8.88555082,-0.0256053423 8.69533067,0.0768160268 L0.338187351,4.57699024 C0.0277330009,4.74179109 -0.0903918282,5.1270672 0.0744028552,5.43753316 C0.0846483761,5.45681956 0.0958732483,5.47557861 0.108039804,5.49373495 L9.00002192,10.2015394 L17.892004,5.49373495 C18.0878366,5.2018397 18.0099782,4.80646835 17.7180562,4.61062845 Z" id="Path"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <span>{card.Approving_Chart_Floors_No} {t('Floors')}</span>  
                                </div>
                            : false
                        }
                        
                        {
                            card.Approving_Chart_Area ?
                                <div className='footerInfo'>
                                    <span className={style.footerInfoIcon}>
                                        <svg width="20px" height="18px" viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <title>rectangle</title>
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="2" transform="translate(-1257.000000, -1794.000000)" fill="#58743A" fillRule="nonzero">
                                                    <g id="Group-3" transform="translate(1197.000000, 1397.000000)">
                                                        <g id="rectangle" transform="translate(60.000000, 397.000000)">
                                                            <path d="M20,4.01546679 L20,1.15445978e-15 L16.1383984,1.15445978e-15 L16.1383984,1.39842895 L3.86160156,1.39842895 L3.86160156,1.15445978e-15 L0,1.15445978e-15 L0,4.01546679 L1.34488281,4.01546679 L1.34488281,13.9845332 L0,13.9845332 L0,18 L3.86160156,18 L3.86160156,16.6015711 L16.1383984,16.6015711 L16.1383984,18 L20,18 L20,13.9845332 L18.6551172,13.9845332 L18.6551172,4.01546679 L20,4.01546679 Z M17.4832422,13.9844926 L16.1383984,13.9844926 L16.1383984,15.3829622 L3.86160156,15.3829622 L3.86160156,13.9844926 L2.51675781,13.9844926 L2.51675781,4.01546679 L3.86160156,4.01546679 L3.86160156,2.61699722 L16.1383984,2.61699722 L16.1383984,4.01546679 L17.4832422,4.01546679 L17.4832422,13.9844926 Z" id="Shape"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <span>{card.Approving_Chart_Area} {t('Area')}</span>
                                </div>
                            : false
                        }
                    </div>
                </div>
            </NavLink>
        </Col>
    )
}
