import React, { useRef, useLayoutEffect } from "react";
import { ProgressBar as Progress } from "react-bootstrap";
import style from "./ProgressBar.module.scss";

const ProgressBar = ({ value, color, ...rest }) => {
  const progressBarRef = useRef(null);

  useLayoutEffect(() => {
    progressBarRef.current.childNodes[0].style.background = color.toString();
  }, [color]);

  return (
    <Progress
      ref={progressBarRef}
      className={style.progressBarStyle}
      now={parseInt(value)}
      label={`${parseInt(value)}%`}
      {...rest}
    />
  );
};

export default ProgressBar;
