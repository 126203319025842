import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import style from "./Request.module.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  saveUpdatRequestInputsData,
  submitUpdateRequest,
  saveUpdateRequestAttachmentData,
  deleteUpdateRequestAttachment,
  downloadUpdateRequestAttachment,
  downloadAttachment,
} from "api/serviceAPI";
import moment from "moment";
import { setError } from "./updateRequestSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faDownload,
  faUpload,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  Attachment,
  Csv,
  Doc,
  Excel,
  File,
  Jpg,
  Mp3,
  Mp4,
  Pdf,
  Png,
  Text,
  Zip,
} from "components/UIComponents/FileIcons";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import DynamicField from "components/UIComponents/Dynamics/DynamicField";
import ProgressBar from "../../components/UIComponents/ProgressBar/ProgressBar";

const RequestEdit = () => {
  const { t, i18n } = useTranslation("common");
  const {
    serviceData,
    requestData,
    requestDate,
    inputsDataValue,
    attachmentsDataValue,
    requestId,
    inputsData,
    attachmentsData,
    requiredChangesAttachments,
    requiredChangesNotes,
    currentTaskData,
    loading,
  } = useSelector((state) => state.service.updateRequest);
  const { clientProfiles } = useSelector((state) => state.user);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [highlightTerms, setHighlightTerms] = useState(false);
  const [isEllipsis, setIsEllipsis] = useState(true);

  const termsRef = useRef();

  useEffect(() => {
    if (termsRef && highlightTerms && !acceptedTerms) {
      termsRef.current.style.transform = "scale(1.01)";
      termsRef.current.style.textDecoration = "underline";
    } else {
      termsRef.current.style.transform = "";
      termsRef.current.style.textDecoration = "";
    }
  }, [termsRef, highlightTerms, acceptedTerms]);

  const handleTerms = () => {
    setAcceptedTerms(Number(!acceptedTerms));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsSubmiting(true);
    submitUpdateRequest(
      {
        Department_Id: serviceData.Department_Id,
        Dealing_Type_Id: serviceData.Dealing_Type_Id,
        Service_Id: serviceData.Service_Id,
        Task_Id: currentTaskData?.Task_Id,
        Client_Profile_Id: clientProfiles.Client_Profile_Id,
        Request_Id: requestId,
      },
      source
    )
      .then((response) => {
        if (response.Status) {
          history.push(`/dashboard`);
        }
      })
      .catch((err) => {
        dispatch(setError(err));
      })
      .finally(() => {
        setIsSubmiting(false);
      });
    return () => {
      source.cancel();
    };
  };

  const download = async (f) => {
    await downloadAttachment({
      File_Code: f.File_Code,
    })
      .then(function (response) {
        const blob = new Blob([response]);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        // document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const handleDefaultValue = (item) => {
    if (inputsDataValue?.length) {
      const dataValue = inputsDataValue.filter(
        (dataValue) =>
          dataValue?.Department_Service_Detail_Id ===
          item.Department_Service_Detail_Id
      );
      if (dataValue) return dataValue[0]?.Service_Detail_Value;
      else {
        return null;
      }
    }
    return null;
  };
  const handleFileValue = (item) => {
    if (attachmentsDataValue?.length) {
      const fileValue = attachmentsDataValue.filter(
        (fileValue) =>
          fileValue?.Department_Service_Detail_Id ===
          item.Department_Service_Detail_Id
      );
      if (fileValue) return fileValue[0]?.Files_Data;
      else {
        return null;
      }
    }
    return null;
  };

  const handleSave = () => {
    history.push(`/dashboard`);
  };

  const onInputSave = async (e, item) => {
    await saveUpdatRequestInputsData({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Id: requestId,
      Task_Id: currentTaskData?.Task_Id,
      Draft_Data: [
        {
          Service_Detail_Id: item.Service_Detail_Id,
          Service_Detail_Value: e.target.value,
        },
      ],
    }).catch((err) => {
      dispatch(setError(err));
    });
  };

  const handleToggleEllipsis = () => {
    setIsEllipsis(!isEllipsis);
  };

  return (
    <>
      <Row className="align-items-center position-relative">
        <Col xs={6}>
          <p className={style.title}>{t("Service Information")}</p>
        </Col>
        <Col xs={6} className="d-flex justify-content-end mb-2">
          <button className="mainBtn primarymainBtn smBtn" onClick={handleSave}>
            {t("save")}
          </button>
        </Col>
      </Row>
      <Row className="align-items-center position-relative">
        <Col xs={12} className={style.detailsContent}>
          <Row>
            <p className={style.details}>{t("Request Number")}</p>
            <p className={style.details}>{requestData?.Request_No}</p>
          </Row>
          <Row>
            <p className={style.details}>{t("Request Date")}</p>
            <p className={style.details}>
              {requestData?.Request_Date &&
                moment(requestDate.Request_Date)
                  .lang("en")
                  .format("YYYY-MM-DD")}
            </p>
          </Row>
          <Row className="justify-content-between">
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("service_name")}:</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Service_Name_AR
                    : serviceData?.Service_Name_EN}
                </p>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("service_code")}:</p>
                <p className={style.details}>{serviceData?.Service_Code}</p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Service Version")}</p>
                <p className={style.details}>
                  {serviceData?.Department_Service_Version_Name}
                </p>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Service Category")}</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Service_Category_Name_AR
                    : serviceData?.Service_Category_Name_EN}
                </p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("department_name")}:</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Department_Name_AR
                    : serviceData?.Department_Name_EN}
                </p>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <p className={style.details}>{t("Section Name")}</p>
                <p className={style.details}>
                  {i18n.language === "ar"
                    ? serviceData?.Department_Section_Name_AR
                    : serviceData?.Department_Section_Name_EN}
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xl={12}>
            <p className={style.title}>{t("steps")}</p>
          </Col>

          <Col xl={12} className={style.step}>
            <div className={style.stepHeader}>
              <span className={style.number}>1</span>
              <p className={style.stepTitle}>{t("Information")}</p>
            </div>
            <div className={style.stepContent}>
              {loading !== "pending" ? (
                <Row>
                  {inputsData?.length > 0 &&
                    [...inputsData]
                      .sort(
                        (a, b) =>
                          Number(a.Department_Service_Detail_No) -
                          Number(b.Department_Service_Detail_No)
                      )
                      .map((item) => (
                        <DynamicField
                          key={item.Department_Service_Detail_Id}
                          defaultValue={() => handleDefaultValue(item)}
                          onSave={onInputSave}
                          item={item}
                          autoSave={
                            item.Department_Service_Detail_Type_Id === 10
                          }
                        />
                      ))}
                </Row>
              ) : (
                <div>{t("Loading")}</div>
              )}
            </div>
          </Col>

          <Col xl={12} className={style.step}>
            <div className={style.stepHeader}>
              <span className={style.number}>2</span>
              <p className={style.stepTitle}>
                {t("Modifications and Required Data")}
              </p>
            </div>
            <div className={style.stepContent}>
              {loading !== "pending" ? (
                <Row>
                  <Col xs={12}>
                    <Form.Label className={style.label}>
                      {t("notes")}
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      defaultValue={requiredChangesNotes}
                      disabled
                    />
                  </Col>
                  <Col xs={12}>
                    <Form.Label className={style.label}>
                      {t("attachments")}
                    </Form.Label>
                    <div className={style.fileContainer}>
                      <div className={style.fileLabel}>
                        <FontAwesomeIcon color={"#d3d3d3"} icon={faDownload} />
                      </div>

                      {requiredChangesAttachments?.length > 0 && (
                        <div className={`${style.files} mt-2`}>
                          {requiredChangesAttachments.map((uploadedFile) => (
                            <div
                              key={uploadedFile.File_Code}
                              className="d-flex justify-content-between mb-1 p-1"
                            >
                              <div className="d-flex">
                                {getFileIcon(uploadedFile.File_Content)}
                                <span
                                  onClick={handleToggleEllipsis}
                                  className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                                    } mx-1`}
                                >
                                  {uploadedFile.File_Name}
                                </span>
                                <span className={`${style.fileContent} mx-1`}>
                                  {uploadedFile.File_Size}
                                </span>
                              </div>
                              <div className="d-flex">
                                <FontAwesomeIcon
                                  className="mx-1"
                                  size="1x"
                                  color={"#1e90ff"}
                                  icon={faDownload}
                                  onClick={() => download(uploadedFile)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              ) : (
                <div>{t("Loading")}</div>
              )}
            </div>
          </Col>

          <Col xl={12} className={style.step}>
            <div className={style.stepHeader}>
              <span className={style.number}>3</span>
              <p className={style.stepTitle}>{t("Donwload Required")}</p>
            </div>

            <div className={style.stepContent}>
              <Row>
                {attachmentsData?.length > 0 &&
                  [...attachmentsData]
                    .sort(
                      (a, b) =>
                        Number(a.Department_Service_Detail_No) -
                        Number(b.Department_Service_Detail_No)
                    )
                    .map((item) => {
                      return (
                        <FileUpload
                          key={item.Department_Service_Detail_Id}
                          defaultValue={() => handleFileValue(item)}
                          item={item}
                        />
                      );
                    })}
              </Row>
            </div>
          </Col>
          <Col xl={12} className={style.step}>
            <div className={style.terms}>
              <input
                className={style.checkBox}
                type="checkbox"
                defaultChecked={acceptedTerms}
                onChange={handleTerms}
              />
              <p ref={termsRef}>{t("terms and conditions")}</p>
            </div>
            <div
              className={style.terms}
              dangerouslySetInnerHTML={{
                __html:
                  i18n.language === "ar"
                    ? serviceData?.Service_Rules_AR
                    : serviceData?.Service_Rules_EN,
              }}
            ></div>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="submit"
              className="mainBtn primarymainBtn smBtn"
              disabled={!acceptedTerms}
              onPointerEnter={() => setHighlightTerms(true)}
              onPointerLeave={() => setHighlightTerms(false)}
              style={{ cursor: acceptedTerms ? "pointer" : "not-allowed" }}
            >
              {t("submit")}
            </button>
          </Col>
        </Row>
      </Form>
      {isSubmiting && <LoadingOverlay />}
    </>
  );
};

export default RequestEdit;

const FileUpload = ({ item, defaultValue }) => {
  const { t, i18n } = useTranslation("common");
  const [uploadedFiles, setUploadedFiles] = useState(
    defaultValue ? defaultValue : []
  );
  const { clientProfiles } = useSelector((state) => state.user);
  const { requestId } = useSelector((state) => state.service.updateRequest);
  const { currentTaskData } = useSelector(
    (state) => state.service.updateRequest
  );
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [isEllipsis, setIsEllipsis] = useState(true);
  const [progressValue, setProgressValue] = useState(0);

  const onChange = async (e) => {
    var files = e.target.files[0];
    if (files) {
      const data = new FormData();
      data.append(
        "PARAMETERS",
        JSON.stringify({
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Id: requestId,
          Service_Detail_Id: item.Service_Detail_Id,
          Task_Id: currentTaskData?.Task_Id,
        })
      );
      setIsUploading(true);
      data.append("Attached_File", e.target.files[0]);
      const response = await saveUpdateRequestAttachmentData(
        data,
        handleProgress
      )
        .catch((err) => {
          dispatch(setError(err));
        })
        .finally(() => {
          setIsUploading(false);
          setProgressValue(0);
        });
      if (response?.Status) {
        setUploadedFiles(response?.Data?.Files_Data);
      }
      e.target.value = null;
    }
  };

  const removeFile = async (f) => {
    const response = await deleteUpdateRequestAttachment({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Id: requestId,
      Service_Detail_Id: item.Service_Detail_Id,
      Task_Id: currentTaskData?.Task_Id,
      File_Code: f.File_Code,
    });
    if (response?.Status) {
      setUploadedFiles((prev) => prev.filter((x) => x !== f));
    }
  };
  const download = async (f) => {
    await downloadUpdateRequestAttachment({
      Client_Profile_Id: clientProfiles?.Client_Profile_Id,
      Request_Id: requestId,
      Task_Id: currentTaskData?.Task_Id,
      Service_Detail_Id: item.Service_Detail_Id,
      File_Code: f.File_Code,
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", f.File_Name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((ex) => {
        console.error(ex);
      });
  };

  const handleToggleEllipsis = () => {
    setIsEllipsis(!isEllipsis);
  };

  const handleProgress = (progress) => {
    var progressValue = (progress.loaded / progress.total) * 100;
    setProgressValue(Math.trunc(progressValue));
  };

  return (
    <>
      <Col key={item.Department_Service_Detail_Id} xs={12}>
        <Form.Label className={style.label}>
          {i18n.language === "ar"
            ? item?.Department_Service_Detail_Title_AR
            : item?.Department_Service_Detail_Title_EN}
        </Form.Label>
        {isUploading && <ProgressBar value={progressValue} color="#58743A" />}
        <div className={style.fileContainer}>
          <div className={style.fileLabel}>
            <FontAwesomeIcon color={"#d3d3d3"} icon={faUpload} />
          </div>
          <Form.Control
            className={style.file}
            type="file"
            onChange={onChange}
          // required={
          //   item.Department_Service_Detail_Required && !uploadedFiles?.length
          // }
          />
          <Form.Text className={style.text}>
            {t("Required File Format")}{" "}
            {i18n.language === "ar"
              ? item.File_Content_Type_Name_AR
              : item.File_Content_Type_Name_EN}
          </Form.Text>
          {uploadedFiles?.length > 0 && (
            <div className={style.files}>
              {uploadedFiles.map((uploadedFile) => (
                <div
                  key={uploadedFile.File_Code}
                  className="d-flex justify-content-between mb-1"
                >
                  <div className="d-flex">
                    {getFileIcon(uploadedFile.File_Content)}
                    <span
                      onClick={handleToggleEllipsis}
                      className={`${style.fileContent} ${isEllipsis ? style.clientRequestInfo : ""
                        } mx-1`}
                    >
                      {uploadedFile.File_Name}
                    </span>
                    <span className={`${style.fileContent} mx-1`}>
                      {uploadedFile.File_Size}
                    </span>
                  </div>

                  <div className="d-flex">
                    <FontAwesomeIcon
                      className="mx-1"
                      size="1x"
                      color={"#1e90ff"}
                      icon={faDownload}
                      onClick={() => download(uploadedFile)}
                    />
                    <FontAwesomeIcon
                      className="mx-1"
                      size="1x"
                      color={"#ff6347"}
                      icon={faTrashAlt}
                      onClick={() => removeFile(uploadedFile)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Col>

      {/* {isUploading && <LoadingOverlay />} */}
    </>
  );
};

const getFileIcon = (fileType) => {
  return (
    {
      "image/jpeg": <Jpg />,
      "image/png": <Png />,
      "video/mp4": <Mp4 />,
      "application/pdf": <Pdf />,
      "application/vnd.ms-powerpoint": <Doc />,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        <Doc />,
      "application/msword": <Doc />,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        <Doc />,
      "audio/mpeg": <Mp3 />,
      "application/zip": <Zip />,
      "text/csv": <Csv />,
      "text/plain": <Text />,
    }[fileType] ?? <Attachment />
  );
};
