import axios from "axios";
import { logout } from "features/user/userSlice";
import { messageAdded } from "features/service/errorMessageSlice";
import { Redirect, Route } from "react-router-dom";

export const customInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

customInstance.interceptors.request.use(
	(config) => {
		if (config?.headers["Content-Type"] !== "application/json") {
			config.headers["Content-Type"] =
				"application/x-www-form-urlencoded";
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

customInstance.interceptors.response.use(
	(response) => {
		if (response.data?.Status === false) {
			const { baseURL, data, headers, method, url } = response.config;
			let errorList = response?.data?.Data;
			errorList =
				typeof errorList === "string"
					? [
							{
								Parameter_EN: "Error",
								Parameter_AR: "الخطأ",
								Errors_EN: [errorList],
								Errors_AR: [errorList],
							},
					  ]
					: errorList;

			customInstance.defaults.store.dispatch(
				messageAdded({
					errorEnglish: response?.data?.Error_EN,
					errorArabic: response?.data?.Error_AR,
					errorList: errorList,
				})
			);

			if (response.data) {
				return Promise.reject(
					JSON.stringify({
						baseURL: baseURL,
						data: data,
						headers: headers,
						method: method,
						url: url,
						error: response.data,
					})
				);
			} else {
				return Promise.reject(
					JSON.stringify({
						baseURL: baseURL,
						data: data,
						headers: headers,
						method: method,
						url: url,
					})
				);
			}
		}
		return response?.data;
	},
	async (error) => {
		const { baseURL, data, headers, method, url } = error.config;
		let errorData = error?.response?.data?.Data;
		errorData =
			typeof errorData === "string"
				? [
						{
							Parameter_EN: "Error",
							Parameter_AR: "الخطأ",
							Errors_EN: [errorData],
							Errors_AR: [errorData],
						},
				  ]
				: errorData;
		if (
			error.response?.status === 401 ||
			error.response?.statusText === "Unauthorized"
		) {
			customInstance.defaults.store.dispatch(logout());
			//// -- Global error handler -- ////
			customInstance.defaults.store.dispatch(
				messageAdded({
					errorEnglish: "Error occurred in credentials",
					errorArabic: "حدث خطأ في المصادقة",
					errorList: errorData,
					errorCode: 401,
				})
			);
			////
			return Promise.reject(error);
		} else if (error.response?.status === 500) {
			console.log(errorData);
			customInstance.defaults.store.dispatch(
				messageAdded({
					errorEnglish: "Error occurred while submiting your order",
					errorArabic: "حدث خطأ أثناء تنفيذ طلبك",
					errorList: errorData,
				})
			);
		}

		return Promise.reject(
			JSON.stringify({
				baseURL: baseURL,
				data: data,
				headers: headers,
				method: method,
				url: url,
				errorCode: error.response?.status,
			})
		);
	}
);

const { get, post, put } = customInstance;
export { get, post, put };
