import React, { useState, useEffect } from 'react'
import RejectedRequestsGrid from './RejectedRequestsGrid'
import PopUp from '../../../UIComponents/PopUp/PopUp'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import style from './../form.module.scss'
import moment from 'moment'

const RejectedRequests = props => {

    const { t, i18n } = useTranslation('common')
    const [show, setShow] = useState(false)
    const [modalData, setModalData] = useState()
    const [clickFix, setClickFix] = useState(false)

    const handleClose = () => setShow(false)

    const getRequestId = data => {
        setModalData(data)
        setClickFix(!clickFix)
    }

    useEffect(() => {
        if (modalData?.Request_Id) {
            setShow(true)
        }
    }, [clickFix])

    return (
        <>
            <RejectedRequestsGrid
                getRequestId={getRequestId}
                dataIsReady={props.dataIsReady}
            />

            {modalData ?
                <PopUp
                    show={show}
                    handleClose={handleClose}
                    title={t('rejected_requests')}
                >
                    <Container>
                        <Formik
                            initialValues={{
                                request_no: modalData?.Request_No,
                                request_date: moment(modalData?.Request_Date).lang("en").format('YYYY-MM-DD'),
                                service_issuance: modalData?.Department_Service_Version_Name,
                                department_name: i18n.language === 'en' ? modalData?.Department_Name_EN : modalData?.Department_Name_AR,
                                service_name: i18n.language === 'en' ? modalData?.Service_Name_EN : modalData?.Service_Name_AR,
                                request_year: modalData?.Request_Year,
                                service_code: modalData?.Service_No,
                                service_category: i18n.language === 'en' ? modalData?.Service_Category_Name_EN : modalData?.Service_Category_Name_AR,
                                dealing_type: i18n.language === 'en' ? modalData?.Dealing_Type_Name_EN : modalData?.Dealing_Type_Name_AR,
                                request_status: i18n.language === 'en' ? modalData?.Request_Status_Name_EN : modalData?.Request_Status_Name_AR,
                                request_reject_reasons: modalData?.Request_Reject_Reasons
                            }}
                        >
                            <Form className={style.dashboardData}>
                                <Row
                                    style={{ 'textAlign': i18n.language === 'ar' ? 'start' : null }}
                                >
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_no')}</label>
                                        <Field className={style.dashboardField} name="request_no" type="text" readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_Year')}</label>
                                        <Field className={style.dashboardField} name="request_year" type='text' readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('request_date')}</label>
                                        <Field className={style.dashboardField} name="request_date" type='text' readOnly />
                                    </Col>
                                    <Col md={3} sm={6}>
                                        <label className={style.name}>{t('department_name')}</label>
                                        <Field className={style.dashboardField} name="department_name" type="text" readOnly />
                                    </Col>

                                    <Col sm={12}>
                                        <Field name="service_name" type="text" as="textarea" className={['text-center title mainName', style.dashboardField].join(' ')} readOnly />
                                    </Col>

                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('service_code')}</label>
                                        <Field className={style.dashboardField} name="service_code" type="text" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('service_issuance')}</label>
                                        <Field className={style.dashboardField} name="service_issuance" type="text" readOnly />
                                    </Col>
                                    <Col md={4} sm={6}>
                                        <label className={style.name}>{t('service_category')}</label>
                                        <Field className={style.dashboardField} name="service_category" type="text" readOnly />
                                    </Col>
                                    <Col md={6} sm={6}>
                                        <label className={style.name}>{t('dealing_type')}</label>
                                        <Field className={style.dashboardField} name="dealing_type" type="text" readOnly />
                                    </Col>
                                    <Col md={6} sm={6}>
                                        <label className={style.name}>{t('request_status')}</label>
                                        <Field className={style.dashboardField} name="request_status" type="text" readOnly />
                                    </Col>
                                    <Col sm={12}>
                                        <label className={style.name}>{t('reject_reasons')}</label>
                                        <Field className={style.dashboardField} name="request_reject_reasons" type="text" as="textarea" readOnly />
                                    </Col>
                                </Row>
                            </Form>
                        </Formik>
                    </Container>
                </PopUp>
                : null}
        </>

    )
}

export default React.memo(RejectedRequests)
