import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./ServicesList.module.scss";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { departmentSelected } from "features/service/serviceMunicipSlice";

const ServicesList = props => {
  const { i18n } = useTranslation("common");
  const dispatch = useDispatch();

  return (
    <>
      {props.serviceList?.map((service) => (
        <Col xs={12} className={style.service} key={service.Service_Id}>
          <NavLink
            to={{
              pathname: `../../service-card/${service.Service_Id}`,
            }}
            onClick={() => {
              dispatch(departmentSelected(props.municipalId));
            }}
            exact>
            <span>
              {service.Service_Logo_URL ? (
                <img className={style.serviceImg} src={service.Service_Logo_URL} />
              ) : (
                <svg id="checkbox-circle-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path id="Path" d="M0,0H24V24H0Z" fill="none" />
                  <g id="file-4-fill" transform="translate(2 1)">
                    <path id="Path-2" data-name="Path" d="M0,0H21V21H0Z" fill="none" />
                    <path
                      id="Shape"
                      d="M.873,17.5A.877.877,0,0,1,0,16.632V.868A.869.869,0,0,1,.868,0H14.882a.876.876,0,0,1,.868.881V11.375H9.625V17.5Zm10.5-4.375H15.75L11.375,17.5Z"
                      transform="translate(2.625 1.75)"
                      fill="#58743a"
                    />
                  </g>
                </svg>
              )}
            </span>
            <p>{i18n.language === "en" ? service.Service_Name_EN : service.Service_Name_AR}</p>
          </NavLink>
        </Col>
      ))}
    </>
  );
};

export default ServicesList;
