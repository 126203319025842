import { createSlice, isAnyOf, createAsyncThunk } from "@reduxjs/toolkit";
import { getClientCompeletedRequestInfo, getClientUnderProcessRequestInfo, getClientRejectedRequestInfo, getClientReturnRequestInfo } from "api/serviceAPI";
import axios from "axios";

export const fetchCompletedRequestInfo = createAsyncThunk("serviceView/CompletedRequestInfo", async (param, { signal }) => {
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  const response = await getClientCompeletedRequestInfo(param, {
    cancelToken: source.token,
  });
  return response;
});

export const fetchUnderProcessRequestInfo = createAsyncThunk("serviceView/UnderProcessRequestInfo", async (param, { signal }) => {
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  const response = await getClientUnderProcessRequestInfo(param, {
    cancelToken: source.token,
  });
  return response;
});

export const fetchRejectedRequestInfo = createAsyncThunk("serviceView/RejectedRequestInfo", async (param, { signal }) => {
  const source = axios.CancelToken.source();
  signal.addEventListener("abort", () => {
    source.cancel();
  });
  const response = await getClientRejectedRequestInfo(param, {
    cancelToken: source.token,
  });
  return response;
});

const slice = createSlice({
  name: "serviceView",
  initialState: {
    loading: "idle",
    serviceData: null,
    requestId: null,
    error: null,
  },
  reducers: {
    resetRequest: (state) => {
      state.loading = "idle";
      state.serviceData = null;
      state.requestId = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(fetchCompletedRequestInfo.pending, fetchUnderProcessRequestInfo.pending, fetchRejectedRequestInfo.pending), (state) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      })
      .addMatcher(isAnyOf(fetchCompletedRequestInfo.fulfilled, fetchUnderProcessRequestInfo.fulfilled, fetchRejectedRequestInfo.fulfilled), (state, { payload }) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          if (payload?.Status) {
            state.serviceData = payload?.Data;
          }
        }
      })
      .addMatcher(isAnyOf(fetchCompletedRequestInfo.rejected, fetchUnderProcessRequestInfo.rejected, fetchRejectedRequestInfo.rejected), (state, { payload }) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          if (payload?.Status) {
            state.serviceData = payload?.Data;
          }
        }
      });
  },
});
export default slice.reducer;

export const { resetRequest } = slice.actions;
