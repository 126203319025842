import React, { useState, useEffect } from "react";
import style from "./Notifications.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Accordion, Card } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import Pagination from "./Pagination";
import PopUp from "./../../../PopUp/PopUp";

import { post } from "../../../../../api/base";

const Notifications = (props) => {
	const { t, i18n } = useTranslation("common");
	const [open, setOpen] = useState(null);
	const { user } = useSelector((state) => state.user);
	const [notifications, setNotifications] = useState();
	const [notificationBody, setNotificationBody] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [notificationsPerPage, setNotificationsPerPage] = useState(10);
	const [show, setShow] = useState(false);
	const [deleteStae, setDeleteState] = useState({
		byId: null,
		readed: false,
		all: false,
	});

	useEffect(() => GetNotifications(), []);

	const GetNotifications = async () => {
		const res = await post(
			`${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/GET_WEB_NOTIFICATIONS`,
			{
				Page_Index: 1,
				Page_Count: 1000,
			},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		);

		setNotifications(res.Data);
	};

	const GetNotificationsDetails = async (id) => {
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/GET_WEB_NOTIFICATION_DETAILS`,
			{
				Notification_Web_Id: id,
			},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		);
		setOpen(open == id ? null : id);
		setNotificationBody(res.data.Data);
		GetNotifications();
		props.notificationsCheck();
	};

	const deleteNotification = async (id) => {
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/DEL_WEB_NOTIFICATION`,
			{
				Notification_Web_Id: id,
			},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		);
		GetNotifications();
		props.notificationsCheck();
	};

	const deleteReaded = async () => {
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/DEL_READED_WEB_NOTIFICATION`,
			{},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		);
		GetNotifications();
		props.notificationsCheck();
	};

	const deleteAll = async () => {
		const res = await axios.post(
			`${process.env.REACT_APP_API_URL}/SYSTEM/USERS/NOTIFICATIONS/DEL_ALL_WEB_NOTIFICATION`,
			{},
			{
				headers: {
					Authorization: `Bearer ${user?.access_token}`,
				},
			}
		);
		GetNotifications();
		props.notificationsCheck();
	};

	const confirmDelete = () => {
		if (deleteStae.byId !== null) {
			deleteNotification(deleteStae.byId);
		} else if (deleteStae.readed) {
			deleteReaded();
		} else if (deleteStae.all) {
			deleteAll();
		}
		setShow(false);
	};

	const onPrevPage = () => {
		if (currentPage > 1) {
			setTimeout(() => setCurrentPage(currentPage - 1), 1000);
		}
	};

	const onNextPage = () => {
		if (currentPage < notifications.length / notificationsPerPage) {
			setTimeout(() => setCurrentPage(currentPage + 1), 1000);
		}
	};

	const handleClose = () => setShow(false);

	const indexOfLastPost = currentPage * notificationsPerPage;
	const indexOfFirstPost = indexOfLastPost - notificationsPerPage;
	const currentNotifications = notifications?.slice(
		indexOfFirstPost,
		indexOfLastPost
	);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<Container className="my-4">
			<Row>
				{currentNotifications &&
					currentNotifications.map((n) => (
						<Col
							sm={12}
							className="mb-3"
							key={n.Notification_Web_Id}
						>
							<Accordion activeKey={open}>
								<Card className="mx-3">
									<Card.Header className={style.accHeader}>
										<Accordion.Toggle
											eventKey={n.Notification_Web_Id}
											className={[
												style.accBtn,
												n.Is_Read == 0
													? style.accBtnNew
													: style.accBtnReaded,
											].join(" ")}
											onClick={() =>
												GetNotificationsDetails(
													n.Notification_Web_Id
												)
											}
										>
											<span>
												{i18n.language == "en"
													? n.Notification_Title_EN
													: n.Notification_Title_AR}
												<p className={style.time}>
													{n.Notification_Time}
												</p>
											</span>

											<span
												className={[
													style.accArrow,
													open ==
													n.Notification_Web_Id
														? style.upArrow
														: null,
												].join(" ")}
											>
												<svg
													version="1.1"
													id="Capa_1"
													xmlns="http://www.w3.org/2000/svg"
													xmlnsXlink="http://www.w3.org/1999/xlink"
													x="0px"
													y="0px"
													width="451.847px"
													height="451.847px"
													viewBox="0 0 451.847 451.847"
													xmlSpace="preserve"
												>
													<g>
														<path
															d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
                                                            c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
                                                            c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
														/>
													</g>
												</svg>
											</span>
										</Accordion.Toggle>
									</Card.Header>

									<Accordion.Collapse
										eventKey={n.Notification_Web_Id}
									>
										<Card.Body className={style.accBody}>
											<Row>
												<Col>
													{i18n.language == "en"
														? notificationBody?.Notification_Body_EN
														: notificationBody?.Notification_Body_AR}
												</Col>
											</Row>

											<Row>
												<Col className="d-flex justify-content-end">
													<span
														className={
															style.deleteNotifBtn
														}
														onClick={() => {
															setShow(true);
															setDeleteState({
																byId: n.Notification_Web_Id,
																readed: false,
																all: false,
															});
														}}
													>
														<svg
															version="1.0"
															xmlns="http://www.w3.org/2000/svg"
															width="512.000000pt"
															height="512.000000pt"
															viewBox="0 0 512.000000 512.000000"
															preserveAspectRatio="xMidYMid meet"
														>
															<g
																transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
																fill="#000000"
																stroke="none"
															>
																<path
																	d="M805 4810 c-225 -26 -411 -187 -482 -416 -17 -56 -18 -140 -18 -1834
                                                                0 -1694 1 -1778 18 -1834 62 -199 204 -341 403 -403 56 -17 140 -18 1834 -18
                                                                1694 0 1778 1 1834 18 199 62 341 204 403 403 17 56 18 140 18 1834 0 1694 -1
                                                                1778 -18 1834 -61 197 -198 335 -397 402 -53 18 -129 19 -1795 20 -957 1
                                                                -1767 -2 -1800 -6z m1867 -680 c147 -28 233 -73 328 -169 110 -111 169 -249
                                                                170 -392 l0 -47 93 -16 c50 -9 104 -19 120 -22 34 -7 83 -47 102 -85 43 -81
                                                                -15 -191 -107 -205 -27 -4 -933 152 -1631 281 -164 30 -200 53 -214 138 -8 49
                                                                2 81 38 122 48 54 78 58 237 29 75 -13 137 -24 138 -24 1 0 21 39 44 88 73
                                                                150 209 263 373 307 74 20 190 18 309 -5z m705 -996 c15 -11 38 -36 51 -54
                                                                l22 -33 0 -821 c0 -769 -1 -826 -19 -888 -46 -169 -188 -309 -356 -353 -87
                                                                -22 -963 -22 -1050 0 -143 38 -258 134 -328 274 l-42 84 -3 855 c-2 845 -2
                                                                854 18 887 11 18 40 43 63 54 42 21 47 21 829 19 780 -3 787 -3 815 -24z"
																/>
																<path
																	d="M2385 3829 c-47 -23 -101 -73 -122 -112 -8 -16 -13 -30 -11 -31 12
                                                                -8 610 -108 615 -102 12 12 -16 96 -48 142 -16 24 -49 55 -73 69 -91 54 -285
                                                                72 -361 34z"
																/>
																<path
																	d="M1940 2158 c0 -477 4 -716 11 -743 15 -54 52 -101 99 -125 37 -19 60
                                                                -20 503 -20 l463 0 46 29 c30 19 52 44 67 73 21 44 21 49 21 766 l0 722 -605
                                                                0 -605 0 0 -702z m406 513 c24 -11 50 -33 62 -53 22 -35 22 -40 22 -554 0
                                                                -500 -1 -520 -20 -552 -58 -95 -192 -95 -250 0 -19 32 -20 52 -20 548 0 585
                                                                -3 563 80 607 52 28 72 28 126 4z m574 -11 c60 -43 60 -39 60 -603 l0 -514
                                                                -23 -34 c-59 -89 -185 -89 -244 0 l-23 34 0 522 0 522 23 34 c46 70 139 87
                                                                207 39z"
																/>
															</g>
														</svg>
													</span>
												</Col>
											</Row>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</Col>
					))}
			</Row>

			<Row
				className={style.paginationCont}
				className="align-items-center my-4"
			>
				<Pagination
					notificationsPerPage={notificationsPerPage}
					totalNotifications={notifications?.length}
					paginate={paginate}
					currentPage={currentPage}
					prevPage={onPrevPage}
					nextPage={onNextPage}
				/>
			</Row>

			<Row>
				<Col>
					<span
						className={style.deleteBtns}
						onClick={() => {
							setShow(true);
							setDeleteState({
								byId: null,
								readed: true,
								all: false,
							});
						}}
					>
						<span>{t("delete_readed")}</span>
						<svg
							version="1.0"
							xmlns="http://www.w3.org/2000/svg"
							width="512.000000pt"
							height="512.000000pt"
							viewBox="0 0 512.000000 512.000000"
							preserveAspectRatio="xMidYMid meet"
						>
							<g
								transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
								fill="#000000"
								stroke="none"
							>
								<path
									d="M805 4810 c-225 -26 -411 -187 -482 -416 -17 -56 -18 -140 -18 -1834
                                0 -1694 1 -1778 18 -1834 62 -199 204 -341 403 -403 56 -17 140 -18 1834 -18
                                1694 0 1778 1 1834 18 199 62 341 204 403 403 17 56 18 140 18 1834 0 1694 -1
                                1778 -18 1834 -61 197 -198 335 -397 402 -53 18 -129 19 -1795 20 -957 1
                                -1767 -2 -1800 -6z m1867 -680 c147 -28 233 -73 328 -169 110 -111 169 -249
                                170 -392 l0 -47 93 -16 c50 -9 104 -19 120 -22 34 -7 83 -47 102 -85 43 -81
                                -15 -191 -107 -205 -27 -4 -933 152 -1631 281 -164 30 -200 53 -214 138 -8 49
                                2 81 38 122 48 54 78 58 237 29 75 -13 137 -24 138 -24 1 0 21 39 44 88 73
                                150 209 263 373 307 74 20 190 18 309 -5z m705 -996 c15 -11 38 -36 51 -54
                                l22 -33 0 -821 c0 -769 -1 -826 -19 -888 -46 -169 -188 -309 -356 -353 -87
                                -22 -963 -22 -1050 0 -143 38 -258 134 -328 274 l-42 84 -3 855 c-2 845 -2
                                854 18 887 11 18 40 43 63 54 42 21 47 21 829 19 780 -3 787 -3 815 -24z"
								/>
								<path
									d="M2385 3829 c-47 -23 -101 -73 -122 -112 -8 -16 -13 -30 -11 -31 12
                                -8 610 -108 615 -102 12 12 -16 96 -48 142 -16 24 -49 55 -73 69 -91 54 -285
                                72 -361 34z"
								/>
								<path
									d="M1940 2158 c0 -477 4 -716 11 -743 15 -54 52 -101 99 -125 37 -19 60
                                -20 503 -20 l463 0 46 29 c30 19 52 44 67 73 21 44 21 49 21 766 l0 722 -605
                                0 -605 0 0 -702z m406 513 c24 -11 50 -33 62 -53 22 -35 22 -40 22 -554 0
                                -500 -1 -520 -20 -552 -58 -95 -192 -95 -250 0 -19 32 -20 52 -20 548 0 585
                                -3 563 80 607 52 28 72 28 126 4z m574 -11 c60 -43 60 -39 60 -603 l0 -514
                                -23 -34 c-59 -89 -185 -89 -244 0 l-23 34 0 522 0 522 23 34 c46 70 139 87
                                207 39z"
								/>
							</g>
						</svg>
					</span>
				</Col>

				<Col>
					<span
						className={style.deleteBtns}
						onClick={() => {
							setShow(true);
							setDeleteState({
								byId: null,
								readed: false,
								all: true,
							});
						}}
					>
						<span>{t("delete_all")}</span>
						<svg
							version="1.0"
							xmlns="http://www.w3.org/2000/svg"
							width="512.000000pt"
							height="512.000000pt"
							viewBox="0 0 512.000000 512.000000"
							preserveAspectRatio="xMidYMid meet"
						>
							<g
								transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
								fill="#000000"
								stroke="none"
							>
								<path
									d="M805 4810 c-225 -26 -411 -187 -482 -416 -17 -56 -18 -140 -18 -1834
                                0 -1694 1 -1778 18 -1834 62 -199 204 -341 403 -403 56 -17 140 -18 1834 -18
                                1694 0 1778 1 1834 18 199 62 341 204 403 403 17 56 18 140 18 1834 0 1694 -1
                                1778 -18 1834 -61 197 -198 335 -397 402 -53 18 -129 19 -1795 20 -957 1
                                -1767 -2 -1800 -6z m1867 -680 c147 -28 233 -73 328 -169 110 -111 169 -249
                                170 -392 l0 -47 93 -16 c50 -9 104 -19 120 -22 34 -7 83 -47 102 -85 43 -81
                                -15 -191 -107 -205 -27 -4 -933 152 -1631 281 -164 30 -200 53 -214 138 -8 49
                                2 81 38 122 48 54 78 58 237 29 75 -13 137 -24 138 -24 1 0 21 39 44 88 73
                                150 209 263 373 307 74 20 190 18 309 -5z m705 -996 c15 -11 38 -36 51 -54
                                l22 -33 0 -821 c0 -769 -1 -826 -19 -888 -46 -169 -188 -309 -356 -353 -87
                                -22 -963 -22 -1050 0 -143 38 -258 134 -328 274 l-42 84 -3 855 c-2 845 -2
                                854 18 887 11 18 40 43 63 54 42 21 47 21 829 19 780 -3 787 -3 815 -24z"
								/>
								<path
									d="M2385 3829 c-47 -23 -101 -73 -122 -112 -8 -16 -13 -30 -11 -31 12
                                -8 610 -108 615 -102 12 12 -16 96 -48 142 -16 24 -49 55 -73 69 -91 54 -285
                                72 -361 34z"
								/>
								<path
									d="M1940 2158 c0 -477 4 -716 11 -743 15 -54 52 -101 99 -125 37 -19 60
                                -20 503 -20 l463 0 46 29 c30 19 52 44 67 73 21 44 21 49 21 766 l0 722 -605
                                0 -605 0 0 -702z m406 513 c24 -11 50 -33 62 -53 22 -35 22 -40 22 -554 0
                                -500 -1 -520 -20 -552 -58 -95 -192 -95 -250 0 -19 32 -20 52 -20 548 0 585
                                -3 563 80 607 52 28 72 28 126 4z m574 -11 c60 -43 60 -39 60 -603 l0 -514
                                -23 -34 c-59 -89 -185 -89 -244 0 l-23 34 0 522 0 522 23 34 c46 70 139 87
                                207 39z"
								/>
							</g>
						</svg>
					</span>
				</Col>
			</Row>

			<>
				{notifications ? (
					<PopUp
						show={show}
						handleClose={handleClose}
						title={t("confirm_delete")}
						smallModal={true}
					>
						<Container>
							<Row className="mt-4">
								<Col className="d-flex justify-content-end">
									<button
										type="button"
										className="mainBtn smBtn primarymainBtn"
										onClick={confirmDelete}
									>
										{t("delete")}
									</button>
									<button
										type="button"
										className="mainBtn smBtn secondarymainBtn outlined mx-2"
										onClick={handleClose}
									>
										{t("cancel")}
									</button>
								</Col>
							</Row>
						</Container>
					</PopUp>
				) : null}
			</>
		</Container>
	);
};

export default Notifications;
